import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { toggleTab } from '../../../redux/reducers/relocationTabReducer';

interface TabsProps {
    tabs: string[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
    const dispatch = useDispatch()
    const selectedTab = useSelector((state: any) => state?.relocationTabReducer?.selectedTab);

    return (
        <div className="header-section">
            {tabs.map((tab) => (
                <div
                    key={tab}
                    className={`single-section ${selectedTab === tab ? 'active' : ''}`}
                    onClick={() => dispatch(toggleTab(tab))}
                >
                    {tab}
                </div>
            ))}
        </div>
    );
};

export default Tabs;
