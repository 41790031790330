import React from "react";
import BookingFormSection from "./BookingFormSection";

const TextAreaField = ({ label, id, placeholder, rows, required, onChange, value }: any) => (
  <BookingFormSection>
    <label className="form-label">
      {label} {required && <span className="text-danger"> *</span>}
    </label>
    <textarea id={id} className="form-control" value={value} placeholder={placeholder} rows={rows} onChange={onChange}/>
  </BookingFormSection>
);

export default TextAreaField;
