import React from "react";
import { useSelector } from "react-redux";
import { all_routes } from "../../../router/all_routes";
import DashboardMenuItem from "../../../common/ui_components/DashboardMenuItem";

type UserType = 'flexiDriver' | 'carOwner' | 'professionalDriver' | 'enterprise' | 'individual';

// After login - Menu list according user type
const DashboardMenu = () => {
  const routes = all_routes;
  const userType = useSelector((state: any) => state?.authReducer?.login_data?.role) as UserType;
  const subUserType = useSelector((state: any) => state?.authReducer?.login_data?.subrole) as UserType;

  const menuItems: Record<UserType, { route: string; icon: string; label: string; }[]> = {
    flexiDriver: [
      { route: routes.driverBookings, icon: "booking-icon.svg", label: "My Bookings" },
      { route: routes.userSettings, icon: "settings-icon.svg", label: "Settings" }
    ],
    carOwner: [],
    professionalDriver: [
      // { route: routes.driverBookings, icon: "booking-icon.svg", label: "My Bookings" },   //for future purpose
      { route: routes.userSettings, icon: "settings-icon.svg", label: "Settings" }
    ],
    enterprise: [
      { route: routes.userQuote, icon: "booking-icon.svg", label: "My Quotes" },
      { route: routes.userSettings, icon: "settings-icon.svg", label: "Settings" }
    ],
    individual: [
      { route: routes.userDashboard, icon: "dashboard-icon.svg", label: "Dashboard" },
      { route: routes.userRelocation, icon: "booking-icon.svg", label: "My Relocations" },
      { route: routes.userPayment, icon: "payment-icon.svg", label: "Payments" },
      { route: routes.userSettings, icon: "settings-icon.svg", label: "Settings" }
    ]
  };

  let userMenuItems: any[] = [];
  
  if (userType === 'carOwner') {
    userMenuItems = subUserType === 'enterprise' ? menuItems.enterprise : menuItems.individual;
  } else if (userType && menuItems[userType]) {
    userMenuItems = menuItems[userType];
  }
  
  return (
    <div className="dashboard-section">
      <div className="dashboard_container">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-menu">
              <ul>
                {userMenuItems.map((item, index) => (
                  <DashboardMenuItem
                    key={index}
                    route={item.route}
                    iconSrc={`assets/img/icons/${item.icon}`}
                    label={item.label}
                    isActive={(route) => location.pathname === route}
                    additionalRoutes={item.label === "Settings" ? [
                      routes.userSecurity,
                      routes.preference,
                      routes.notification,
                      routes.integration
                    ] : []}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardMenu;
