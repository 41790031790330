import React from "react";
import { Link, useLocation } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import ListingLocation from "./ListingLocation";

const Breadcrumbs = (props: any) => {
  const routes = all_routes;
  const location = useLocation();
  let addButton = null;
  if (
    location.pathname === routes.listingGrid ||
    // location.pathname === routes.CarList ||
    location.pathname === routes.listingMap ||
    location.pathname === routes.booking ||
    location.pathname === routes.invoice ||
    location.pathname === routes.ourTeam ||
    location.pathname === routes.testimonial ||
    location.pathname === routes.termsConditions ||
    location.pathname === routes.privacyPolicy ||
    location.pathname === routes.aboutUs ||
    location.pathname === routes.pricing ||
    location.pathname === routes.faq ||
    location.pathname === routes.gallery ||
    location.pathname === routes.blogList ||
    location.pathname === routes.blogGrid ||
    location.pathname === routes.bookingAddon ||
    location.pathname === routes.bookingCheckout ||
    location.pathname === routes.bookingDetail ||
    location.pathname === routes.bookingPayment ||
    location.pathname === routes.invoiceDetails ||
    location.pathname === routes.BookingConformation
  ) {
    addButton = (
      <div className="breadcrumb-bar">
        <div className="card_container">
          <div className="row align-items-center">
            <div className="col-md-12 col-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <h2 className="breadcrumb-title">{props.title}</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={routes.MainLanding}>Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">{props.subtitle}</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {props.title}
                    </li>
                  </ol>
                </nav>
              </div>
              {props.loactiondata !== "false" && <ListingLocation color='#fff' searchdata={props?.searchdata} />}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (
    location.pathname === routes.userDashboard ||
    location.pathname === routes.userRelocation ||
    location.pathname === routes.driverBookings ||
    location.pathname === routes.userPayment ||
    location.pathname === routes.userQuote ||
    location.pathname === routes.userSettings ||
    location.pathname === routes.contactUs ||

    location.pathname === routes.relocateCar ||
    location.pathname === routes.invoice ||
    location.pathname === routes.userMessages ||
    location.pathname === routes.userReviews ||
    location.pathname === routes.RentBookingSuccess ||
    location.pathname === routes.CarList ||
    location.pathname === routes.CarDetails ||
    // location.pathname === routes.userWallet ||
    location.pathname === routes.integration
  ) {
    addButton = (
      <div className="breadcrumb-bar">
        <div className="dashboard_container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">{props.title}</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.MainLanding}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {props.subtitle}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    addButton = (
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin-dashboard">{props.title}</Link>
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
          </ul>
        </div>
        <div className="col-auto float-end ms-auto"></div>
      </div>
    );
  }
  return <>{addButton}</>;
};

export default Breadcrumbs;
