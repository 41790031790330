import React, { useState } from "react";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import ListActionMenu from "../../../common/ui_components/ListActionMenu";
import BookingAvatar from "../../../common/ui_components/BookingAvatar";
import RelocationStatus from "../../../common/ui_components/UserBookingStatus";
import ListingTable from "../../../common/ui_components/ListingTable";
import { removeCookie } from "../../../../utils/cookieUtils";
import UserBookingTableHeader from "../UserBooking/UserBookingTableHeader";
// import ListTable from "../../../common/ui_components/listingModule/ListTable";
// import { fetchBookings } from "../../../../redux/reducers/bookingReducer";
// import { useDispatch } from "react-redux";



const BookingList = ({ tableData, title }: any) => {
    // const dispatch = useDispatch();
    const navigate = useNavigate();

    const routes = all_routes;

    const [searchInput, setSearchInput] = useState("");

    const filteredData = tableData?.filter((rowData: any) => {
        const values = rowData && Object.values(rowData).map((value: any) => value?.toString().toLowerCase());
        return values?.some((value: any) => value?.includes(searchInput.toLowerCase()));
    });

    const navigatePath = () => {
        removeCookie('CarId');
        removeCookie('stepperstep');
        removeCookie('rentAmount');
        navigate(routes.CarList);
    }

    const actionButtons = [
        { icon: "feather icon-eye", label: "View", target: "#upcoming_booking" },
    ];

    const locationStyle = {
        whiteSpace: 'nowrap',      /* Prevents the text from wrapping to a new line */
        overflow: 'hidden',         /* Hides the overflowed content */
        textOverflow: 'ellipsis',  /* Adds the ellipsis (...) at the end */
        maxWidth: '200px',         /* Set the max width as per your requirement */
    }

    const columnConfig = [
        // {
        //   field: "",
        //   header: (
        //     <label className="custom_check w-100">
        //       <input type="checkbox" name="username" />
        //       <span className="checkmark" />
        //     </label>
        //   ),
        //   body: () => (
        //     <label className="custom_check w-100">
        //       <input type="checkbox" name="username" />
        //       <span className="checkmark" />
        //     </label>
        //   ),
        // },
        {
            field: "bookingId",
            header: "Booking ID",
            body: (rowData: any) => (
                <Link to="#" data-bs-toggle="modal" data-bs-target="#upcoming_booking" className="bookbyid">
                    {rowData?.bookingId}
                </Link>
            ),
        },
        {
            field: "carName",
            header: "Car Name",
            body: (rowData: any) => (
                <BookingAvatar
                    img={rowData?.imgSrc}
                    carName={rowData?.carName}
                    deliveryStatus={rowData?.carType}
                />
            ),
        },
        {
            field: "pickupLocation",
            header: "Pickup Location",
            body: (rowData: any) => (
                <p style={locationStyle}> {rowData?.pickupLocation}
                    <span className="d-block">{rowData?.pickupTime}</span>
                </p>
            ),
        },
        {
            field: "location",
            header: "Dropoff Location",
            body: (rowData: any) => (
                <p style={locationStyle}> {rowData?.dropoffLocation}
                    <span className="d-block">{rowData?.dropoffTime}</span>
                </p>
            ),
        },
        {
            field: "bookedOn",
            header: "Booked On",
            body: (rowData: any) => <RelocationStatus status={rowData?.bookedOn} />,
        },
        {
            field: "bookingStatus",
            header: "Booking Status",
            body: (rowData: any) => <RelocationStatus status={rowData?.bookingStatus} />,
        },
        {
            field: "action",
            header: "Action",
            body: () => <ListActionMenu actions={actionButtons} />,
        },
    ];






    // const [data, setData] = useState([]);
    // const [totalRecords, setTotalRecords] = useState(0);
    // const [loading, setLoading] = useState(false);
    // const [pageNo, setPageNo] = useState(0);
    // const [rows, setRows] = useState(5);

    // const onPageChange = (event: any) => {
    //     setPageNo(event.first);
    //     setRows(event.rows);
    // };

    // Function to fetch data from the server - this will be 'onPageChange func'
    // const fetchData = async (page: number, rows: number) => {
    //     dispatch(fetchBookings({ endpoint: `bookings/driver?dataPerPage=${rows}&page=${page + 1}` }));

    //     return {
    //         data: filteredData,       // Array of data items
    //         totalRecords: filteredData?.length // Total number of records
    //     };
    // };

    // Load data on page or rows change
    // useEffect(() => {
    //     // loadData(pageNo / rows, rows);
    //     dispatch(fetchBookings({ endpoint: `bookings/driver?dataPerPage=${rows}&page=${pageNo + 1}` }));
    // }, [pageNo, rows]);

    return (
        <div className="col-lg-12 d-flex">
            <div className="card book-card flex-fill mb-0">
                <UserBookingTableHeader
                    title={title}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    navigatePath={navigatePath}
                    itemCount={filteredData?.length}
                    show_btn={true}
                    addBtnLabel='Book Car'
                />
                <ListingTable filteredData={filteredData} columnConfig={columnConfig} />

                {/* This will be new common listing table */}
                {/* <ListTable fetchData={fetchData} columnConfig={columnConfig} /> */}
            </div>
        </div>
    );
};
export default BookingList;
