import React, { useEffect } from "react";
import { LuCopyPlus } from "react-icons/lu";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "@mui/material";
import DropdownOptions from "./OptionDropdown";
import { useSelector } from "react-redux";
import FileUploader from "../../common/ui_components/FileUploader";
import { all_routes } from "../../router/all_routes";
import useBillingForm from "../../../hooks/useBillingForm";



const label = { inputProps: { "aria-label": "Checkbox demo" } };
const { countryOptions } = DropdownOptions();

const BillingForm = ({ setStepperState }: any) => {
  const routes = all_routes;
  const {formik, fetchUserData} = useBillingForm(setStepperState)

  const UserDetails = useSelector((state: any) => state?.authReducer?.login_data);
  const login_data = useSelector((state: any) => state?.authReducer?.login_data);

  useEffect(() => {
    fetchUserData();
  }, []);

  console.log("fomik===>",formik.values, formik.errors)

  return (
    <form onSubmit={formik.handleSubmit} className="form-container">
      <div className="main-form-container">
        <div className="form-details shadow-lg p-4">
          <div className="d-flex align-items-center gap-3 border-bottom pb-3">
            <LuCopyPlus />
            <p className={`fw-bold fs-4`}>Billing Info</p>
          </div>
          <div className="row row-cols-1 row-cols-md-2 py-2">
            <div className="input-block">
              <label className="form-label">First Name <span className="text-danger"> *</span></label>
              <input
                type="text"
                name="firstName"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className="text-danger font-short error">
                  {formik.errors.firstName}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="form-label">Last Name <span className="text-danger"> *</span></label>
              <input
                type="text"
                name="lastName"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className="text-danger font-short error">
                  {formik.errors.lastName}
                </div>
              ) : null}
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-3 mb-2">
            <div className="input-block">
              <label className="form-label">Country <span className="text-danger"> *</span></label>
              <Dropdown
                value={countryOptions?.find((country) => country?.name === formik.values.country)}
                onChange={(e) => formik.setFieldValue("country", e.value.name)}
                options={countryOptions}
                optionLabel="name"
                className="w-100"
              />
              {formik.touched.country && formik.errors.country ? (
                <div className="text-danger error font-short mt-3">
                  {formik.errors.country}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="form-label">City <span className="text-danger"> *</span></label>
              <input
                type="text"
                name="city"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
              />
              {formik.touched.city && formik.errors.city ? (
                <div className="text-danger error font-short mt-3">
                  {formik.errors.city}
                </div>
              ) : null}
            </div>
            <div className="input-block">
              <label className="form-label">Pincode <span className="text-danger"> *</span></label>
              <input
                type="text"
                name="pincode"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pincode}
              />
              {formik.touched.pincode && formik.errors.pincode ? (
                <div className="text-danger error font-short mt-3">
                  {formik.errors.pincode}
                </div>
              ) : null}
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 py-2">
            <div className="input-block">
              <label className="form-label">Email Address <span className="text-danger"> *</span></label>
              <div className="d-flex flex-column">
                <div className="d-flex gap-2 h-fit-cont">
                  <input
                    type="text"
                    name="email"
                    className={`form-control ${UserDetails?.emailVerified? "profile-inputfield-disable": ""}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    disabled={UserDetails?.emailVerified}
                  />
                  {UserDetails?.emailVerified === false &&
                    <button type="button" className="py-2 px-3 text-white d-flex justify-content-center align-items-center rounded-2 h-fit-cont" style={{ backgroundColor: '#0076D7', border: 'none', outline: 'none' }}>
                      Verify
                    </button>}
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-danger error font-short">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="input-block">
              <label className="form-label">Phone Number <span className="text-danger"> *</span></label>
              <div className="d-flex flex-column">
                <div className="d-flex gap-2 h-fit-cont">
                  <input
                    type="text"
                    name="phone"
                    className={`form-control ${UserDetails?.phoneVerified ? "profile-inputfield-disable" : ""}`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    disabled={UserDetails?.phoneVerified}
                  />
                  {UserDetails?.phoneVerified === false &&
                    <button type={`button`} className="py-2 px-3 text-white d-flex justify-content-center align-items-center rounded-2 h-fit-cont" style={{ backgroundColor: '#0076D7', border: 'none', outline: 'none' }}>
                      Verify
                    </button>}
                </div>
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="text-danger font-short error">
                    {formik.errors.phone}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="input-block">
            <label className="form-label">Additional Description</label>
            <textarea
              name="additionalDescription"
              className="form-control"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.additionalDescription}
            />
          </div>

          {(login_data?.role === 'flexiDriver' || login_data?.role === 'professionalDriver') &&
            <div className="input-block">
              <label className="form-label">Driving License Number <span className="text-danger"> *</span></label>
              <input
                type="text"
                name="licenseField"
                className="form-control h-160"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.licenseField}
              />
              {formik.touched.licenseField &&
                formik.errors.licenseField ? (
                <div className="text-danger error font-short">
                  {formik.errors.licenseField}
                </div>
              ) : null}
            </div>
          }

          <div className="col-md-12">
            <FileUploader
              label="Upload Document (license)"
              file={formik.values.license}
              // file={(file:any) => formik.setFieldValue('document', file)}
              onChange={(e: any) => formik.setFieldValue('license', e?.target?.files)}
              required
            />
            {formik.touched.license && formik.errors.license && (
              <div className="error text-danger font-short" style={{ margin: '0 0 5px 5px' }}>
                {formik.errors.license}
              </div>
            )}
          </div>
          <div className="d-flex gap-1 align-items-center">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center">
                <Checkbox
                  {...label}
                  name="acceptTerms"
                  checked={formik.values.acceptTerms}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className={`d-flex align-items-center`}>
                  <p>I have Read and Accept <a href={routes.termsConditions} target="_blank" rel="noopener noreferrer">Terms & Conditions</a></p>
                  <span className="text-danger">*</span>
                </div>
              </div>
            </div>
            {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
              <div className="text-danger error font-short">
                {formik.errors.acceptTerms}
              </div>
            ) : null}
          </div>
        </div>
        <div className="d-flex justify-content-center justify-content-md-end align-items-center w-100 gap-3">
          <button
            onClick={() => setStepperState((prev: any) => prev - 1)}
            type={`button`}
            className="bg-black text-white py-2 px-5 d-flex justify-content-center align-items-center rounded-2"
          >
            Back
          </button>
          <button
            type={`submit`}
            className="bg-black text-white py-2 px-3 d-flex justify-content-center align-items-center rounded-2"
          >
            Confirm and Pay Now
          </button>
        </div>
      </div>
    </form>
  );
};

export default BillingForm;
