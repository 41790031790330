import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { doPostApiCall } from "../utils/apiconfig";
import { all_routes } from "../feature-module/router/all_routes";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { set_signup_data } from "../redux/reducers/authReducer";



export const useSignup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const routes = all_routes;

  const [loading, setLoading] = useState(false);

  // const handleSignUp = async (values: any, resetForm: any) => {
  const handleSignUp = async (values: any) => {
    setLoading(true);
    const signupData = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/auth/signup`,
      bodyData: {
        user_name: values.username,
        email: values.email,
        password: values.password,
        type: values.activeState,
        subtype: values.subtype,
      },
    };

    try {
      // dispatch(signUp(signupData));
      const response: any = await doPostApiCall(signupData);
      if (response?.error) {
        response?.code == 'USER_ALREADY_EXISTS' && toast.warn("Sign up failed, User already exist.") 
        return;
      }
      toast.success("Sign up successful !!")
      dispatch(set_signup_data(response.result));
      navigate(routes.OtpVerification);

    } catch (err) {
      console.error("An error occurred during signup.");
    } finally {
      setLoading(false);
    }
  };

  // const verifyUser = async (email: string) => {
  //   const verifyData = {
  //     url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/auth/users/verify`,
  //     bodyData: { email },
  //   };

  //   console.log("verifyUser===>",verifyUser);
  //   try {
  //     await doPatchApiCall(verifyData);
  //     alert("User verified successfully.");
  //   } catch (err) {
  //     alert("User verification failed.");
  //   }
  // };

  return {
    handleSignUp,
    loading,
  };
};
