import React, { useEffect, useState } from "react";
import RateItem from "../../common/ui_components/BookingRateItem";
import BookingLocationCard from "../../common/ui_components/cards/BookingLocationCard";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { doGetApiCall } from "../../../utils/apiconfig";
import { getCookie } from "../../../utils/cookieUtils";

const BookingSidebar = () => {
  const [car, setCar] = useState<any>({});
  const CarId = getCookie('CarId');
  const Stepperstep = getCookie("stepperstep")

  const rentalCharges: any = [
    // { label: "Rental Charges Rate (1 day)", amount: "+ $300" },
    // { label: "Door delivery & Pickup", amount: "+ $60" },
    // { label: "Trip Protection Fees", amount: "+ $25" },
    // { label: "Convenience Fees", amount: "+ $2" },
    // { label: "Tax", amount: "+ $2" },
    // { label: "Refundable Deposit", amount: "+ $1200" },
    // { label: "Subtotal", amount: "+ $1604", isTotal: true },
  ];


  useEffect(() => {
    if (CarId) {
      getConfirmationData();
    }
  }, [CarId, Stepperstep])

  const getConfirmationData = async () => {
    const data = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars/${CarId}`,
    };
    await doGetApiCall(data)
      .then((res: any) => {
        if (!res.error) {
          setCar(res.result)
        }
      })
      .catch((err) => {
        console.error(err);
      })
  };

  return (
    <div className="col-lg-4 theiaStickySidebar">
      <div className="stickybar">
        <div className="booking-sidebar">
          <div className="booking-sidebar-card">
            <div className="booking-sidebar-head">
              <h5>Car Details</h5>
            </div>
            <div className="booking-sidebar-body">
              <div className="booking-car-detail">
                <span className="car-img">
                  <ImageWithBasePath
                    srcType='new'
                    src={car?.images?.length > 0 ?
                      `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${car?.images?.[0]?.key}&width=${100}`
                      : '/assets/img/blank_car.png'
                    }
                    className="img-fluid" alt="Car"
                  />
                </span>
                <div className="care-more-info">
                  <h5>{car?.modelName || "Car"}</h5>
                  <p>{car?.registration ? `Registration Number: ${car?.registration}` : "Registration Number"}</p>
                  <p>{car?.carCategory?.name ? `Car Type: ${car?.carCategory?.name}` : 'Car Type'}</p>
                  {/* {detailsLink && <Link to={detailsLink}>View Car Details</Link>} */}
                </div>
              </div>
              <div className="booking-vehicle-rates">
                <ul>
                  {rentalCharges.length > 0 && rentalCharges.map((item: any, index: any) => (
                    <RateItem
                      key={index}
                      label={item.label}
                      amount={item.amount}
                      isTotal={item.isTotal}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {car?.pickupDate && <BookingLocationCard car={car} />}
          {
            car?.rentAmount && <div className="total-rate-card">
              <div className="vehicle-total-price">
                <h5>Estimated Total</h5>
                <span>{`€ ${car?.rentAmount}`}</span>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default BookingSidebar;
