import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedSection: "Profile",
};

const userSettingsTabSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
    },
  },
});

export const { setSelectedSection } = userSettingsTabSlice.actions;
export default userSettingsTabSlice.reducer;
