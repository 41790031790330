import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { doUploadMediaApiCall } from '../utils/apiconfig';
import { getUserDetails } from '../redux/reducers/userReducer';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { all_routes } from '../feature-module/router/all_routes';
import { toast } from 'react-toastify';
import { setSelectedSection } from '../redux/reducers/userSettingsTabSlice';

export const useUserSettingsHook = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const routes = all_routes;

    const [verificationModal, setVerificationMoadal] = useState(false);
    const [successMessage, setsuccessMessage] = useState(false);
    const [phoneData, setPhoneData] = useState({ code: '', number: '' });
    const [imagePreviews, setImagePreviews] = useState<string[]>([]);
    const [deletedProfileImageKey, setDeletedProfileImageKey] = useState(null)
    const login_data = useSelector((state: any) => state?.authReducer?.login_data);

    const handleSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
        if (!login_data?.phoneVerified) {
            toast.info("Please verify phone number !!")
            return;
        }

        // console.log("formik====>", formik)

        const formData = new FormData();
        formData.append('profilePicture', values?.profilePicture !== null ? (values?.profilePicture?.key ? JSON.stringify(values?.profilePicture) : values?.profilePicture) : '{}');
        formData.append('first_name', values?.firstName);
        formData.append('last_name', values?.lastName);
        formData.append('user_name', values?.userName);
        formData.append('email', values?.email);
        formData.append('phone[code]', phoneData?.code !== '' ? phoneData?.code : values?.phonecode);
        formData.append('phone[number]', phoneData?.number !== '' ? phoneData?.number : values?.phoneNumber);
        formData.append('license', values?.license?.key ? JSON.stringify(values.license) : values.license);
        if (login_data?.role === 'flexiDriver' || login_data?.role === 'professionalDriver') {
            formData.append('licenseField', values?.licenseField);
            // formData.append('document', values?.document);
        }
        if (deletedProfileImageKey !== null) { formData.append('deleteProfilePicture', deletedProfileImageKey) }
        // if (login_data?.role === 'carOwner') formData.append('document', values.document[0]?.key ? JSON.stringify(values.document) : values.document);
        formData.append('address[street]', values?.address);
        formData.append('address[city]', values?.city);
        formData.append('address[state]', values?.state);
        formData.append('address[country]', values?.country);
        formData.append('address[pincode]', values?.pincode);
        // formData.append('phoneNo', values.phoneNumberWithCode);

        const data = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/users/update`,
            bodyData: formData,
            // method: "PUT",
        };

        // await doUploadMediaApiCall(data)
        await doUploadMediaApiCall(data)
            .then(async (res: any) => {
                if (!res.error) {
                    toast.success("Save successfully !!")
                    setDeletedProfileImageKey(null)
                    dispatch(getUserDetails());
                    if (login_data?.role !== 'professionalDriver') {
                        if (!login_data?.profileUpdated) setVerificationMoadal(true);
                    }
                    else {
                        if (!login_data?.profileUpdated) dispatch(setSelectedSection('Documents'))
                    }
                    resetForm();
                } else {
                    console.error(res.error || "Save failed");
                }
            })
            .catch((err) => {
                toast.error("Something went wrong")
                console.error('Error while updating user data ==>', err);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required("First Name is required")
            .max(25, "First Name must not exceed 25 characters"),
        lastName: Yup.string()
            .required("Last Name is required")
            .max(25, "Last Name must not exceed 25 characters"),
        userName: Yup.string().required("User Name is required"),
        email: Yup.string().email("Invalid email address").required("Email is required"),
        phoneNumberWithCode: Yup.string()
            .required("Phone Number is required")
            .test("is-greater", "Please verify phone number", function () {
                return login_data?.phoneVerified;
            }),//Please provide Phone Number & verify it first
        ...((login_data?.role === 'flexiDriver' || login_data?.role === 'professionalDriver') && { ['licenseField']: Yup.string().required("Driving License Number is required") }),
        address: Yup.string().required("Address is required"),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("State is required"),
        country: Yup.string().required("Country is required"),
        pincode: Yup.string().required("Pincode is required")
            .max(10, "Pincode must not exceed 10 digits"),
        license: Yup.mixed()
            .required("Document (license) is required")
            .test("fileSize", "File size is too large (Max 4MB)", (file: any) => {
                if (!file) return true; // Allow empty value until required check
                return file.size ? file.size <= 4 * 1024 * 1024 : true; // Handle both formats
            })
            .test("fileFormat", "Unsupported File Format (only images & PDF allowed)", (file: any) => {
                if (!file) return true;
                const allowedFormats = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
                return allowedFormats.includes(file.type || file.mimetype); // Support both cases
            }),

        // document: Yup.mixed()
        //     .required("Document is required")
        // .test("fileSize", "File size is too large (Max 4MB)", (value: any) => (value && value.size > 4 * 1024 * 1024))
        // .test("fileType", "Unsupported File Format", (value: any) => !value || (value && ["image/jpeg", "image/png", "application/pdf"].includes(value.type))),
    });

    const formik = useFormik({
        initialValues: {
            profilePicture: null,
            firstName: "",
            lastName: "",
            userName: "",
            email: "",
            phonecode: '',
            phoneNumber: '',
            phoneNumberWithCode: "",
            // licenseField: "",
            ...((login_data?.role === 'flexiDriver' || login_data?.role === 'professionalDriver') && { ['licenseField']: '' }),
            license: "" as string | null,
            // document: "" as string | null, // Use string | null
            address: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleVerifyClick = async () => {
        setVerificationMoadal(false);
        setsuccessMessage(true);
        const formData = new FormData();
        formData.append('profileUpdated', "1");
        const data = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/users/update`,
            bodyData: formData,
            // method: "PUT",
        };
        // await doUploadMediaApiCall(data)
        await doUploadMediaApiCall(data)
            .then(async (res: any) => {
                if (!res.error) dispatch(getUserDetails());
                else console.error(res.error || "Save failed");
            })
            .catch((err) => console.error(err, '<<-- err'));
    };

    const handleCancel = () => {
        setVerificationMoadal(false);
    };

    const handleOkClick = () => {
        setsuccessMessage(false);
        dispatch(setSelectedSection('Profile'))
        navigate(routes.MainLanding);
    };

    const handleFreelanceDocumentSubmit = async (values: any, resetForm?: () => void) => {
        const formData = new FormData();
        if (values?.images && values?.images?.length > 0) {
            values.images.forEach((file: any) => {
                formData.append("document", file);
            });
        }
        const data = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/users/update`,
            bodyData: formData,
            // method: 'PUT'
        };
        await doUploadMediaApiCall(data)
            .then(async (res: any) => {
                if (!res.error) {
                    toast.success("Save successfully !!")
                    dispatch(getUserDetails());
                    if (!login_data?.profileUpdated) setVerificationMoadal(true);
                    resetForm?.();
                } else {
                    console.error(res.error || "Save failed");
                }
            })
            .catch((err) => {
                toast.error("Something went wrong")
                console.error('Error while updating user data ==>', err);
            })
    }

    const freelanceformik = useFormik({
        initialValues: {
            images: null
        },
        validationSchema: Yup.object({
            images: Yup.array()
                .required('File is required')
                .min(1, 'At least one image is required')
                .max(5, 'You can upload up to 5 images')
                .of(
                    Yup.mixed()
                        .test('fileSize', 'File size is too large (Max 4MB)', (file: any) => { return file && file.size <= 4 * 1024 * 1024; })
                        .test('fileFormat', 'Unsupported File Format (only images allowed)', (file: any) => { return file && ['image/jpg', 'image/jpeg', 'image/png'].includes(file.type || file.mimetype); })  // Check file format
                )
        }),
        onSubmit: (values, { resetForm }) => {
            handleFreelanceDocumentSubmit(values, resetForm)
        },
    });

    return {
        formik,
        handleSubmit,
        handleVerifyClick,
        handleCancel,
        phoneData,
        setPhoneData,
        handleOkClick,
        verificationModal,
        successMessage,
        handleFreelanceDocumentSubmit,
        imagePreviews,
        setImagePreviews,
        freelanceformik,
        setDeletedProfileImageKey
    };
};

