import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    notificationList: [],
    dataCount: 0,
    unreadCount:0
}

const NotificationReducer = createSlice({
    name:'notification',
    initialState,
    reducers:{
        getNotificationList:(state, action)=>{
            state.notificationList =  action.payload.result;
            state.dataCount =  action.payload.dataCount;
            state.unreadCount = action.payload.specialCount;
        },
    }
});

export const {getNotificationList} = NotificationReducer.actions;
export default NotificationReducer.reducer;