import React, { useEffect, useState } from "react";
import SortAndFilterBar from "./SortAndFilterBar";
import CommonBookingList from "./CommonBookingList";
// import { userBookings } from "../../../../core/data/json/common_static_data";
import UserBookingHeader from "./UserBookingHeader";
// import UserBookingCalender from "../../../common/ui_components/UserBookingCalender";
import DashboardLayout from "../DashboardLayout";
import CompletedBooking from "../../CompletedBooking";
import SuccessModal from "../UserSectionModals/SuccessModal";
import { all_routes } from "../../../router/all_routes";
import ViewBooking from "../UserSectionModals/ViewBooking";
import CancelBooking from "../UserSectionModals/CancelBooking";
import EditBooking from "../UserSectionModals/EditBooking";
import InprogressBooking from "../../InprogressBooking";
import CanceledBooking from "../../CanceledBooking";
import CancelReason from "../UserSectionModals/CancelReason";
import RelocationStatus from "../UserSectionModals/BookingStatus";
import DeleteBooking from "../UserSectionModals/DeleteModal";
import CustomDateModal from "../UserSectionModals/CustomDateModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchCars } from "../../../../redux/reducers/carReducer";
import { format } from 'date-fns';
import useHelperFuncs from "../../../../utils/hooks/useHelperFuncs";
// import { fetchMediaData } from "../../../../services/auth/MediaData";



const UserBookings = ({ title, subtitle }: any) => {
  const dispatch = useDispatch();
  const { toTitleCase } = useHelperFuncs();

  const carList = useSelector((state: any) => state?.carReducer?.carList);

  const [activeStatus, setActiveStatus] = useState("All Relocations");
  const [listData, setListData] = useState<any[]>([]);
  const [timeFilter, setTimeFilter] = useState<string>('');
  const [sortOpt, setSortOpt] = useState<string>('');
  const [viewModalData, setViewModalData] = useState<any>(null)

  const getFilteredList = () => {
    switch (activeStatus) {
      case "Pending":
        return listData.filter((rowData) => rowData?.paymentStatus === "Pending");
      case "Success":
        return listData.filter((rowData) => rowData?.paymentStatus === "Success");
      case "Failed":
        return listData.filter((rowData) => rowData?.paymentStatus === "Failed");
      case "Upcoming":
        return listData.filter((rowData) => rowData?.bookingStatus === "Upcoming");
      // case "Ongoing":
      //   return listData.filter((rowData) => rowData?.bookingStatus === "Ongoing");
      // case "Completed":
      //   return listData.filter((rowData) => rowData?.bookingStatus === "Completed");
      // case "Cancelled":
      //   return listData.filter((rowData) => rowData?.bookingStatus === "Cancelled");
      default:
        return listData;
    }
  };
  const filteredListData = getFilteredList();

  // Initial list data fetch
  useEffect(() => {
    dispatch(fetchCars({ endpoint: `cars/lists?dataPerPage=100` }));
  }, [])

  // Filtered list data fetch
  useEffect(() => {
    if (timeFilter || sortOpt) {
      let query = '';
      if (timeFilter) query = `${timeFilter}&`;
      if (sortOpt) query += `${sortOpt}`;
      dispatch(fetchCars({ endpoint: `cars/lists?${query}&dataPerPage=100` }));
    }
  }, [timeFilter, sortOpt])

  // Only for formating list data
  useEffect(() => {
    if (carList?.length > 0) {
      const formatedCarList = carList.map((car: any) => {
        return {
          // route: all_routes.userDashboard,
          // imgSrc: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${car?.images?.[0]?.key}&width=${100}`,
          imgSrc: car?.images?.[0]?.key ? `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${car?.images?.[0]?.key}&width=${100}` : '/assets/img/cars/car-01.jpg',
          carName: car?.modelName || 'Model Name',
          carType: car?.carCategory?.name || 'Car Type',
          pickupLocation: car?.pickupLocation?.street,
          pickupTime: `${format(new Date(car?.pickupDate), "dd MMM yyyy, hh:mm a")}`,
          dropoffLocation: car?.dropoffLocation?.street,
          dropoffTime: `${format(new Date(car?.dropoffDate), "dd MMM yyyy, hh:mm a")}`,
          total: `€${car?.relocationAmount?.price}`,
          paymentStatus: car?.paymentStatus ? toTitleCase(car?.paymentStatus) : 'NA',
          bookingStatus: car?.bookingInfo?.status ? toTitleCase(car?.bookingInfo?.status) : 'Not Booked Yet',
          createdAt: car?.createdAt && `${format(new Date(car?.createdAt), "dd MMM yyyy, hh:mm a")}`,

          // Extra data
          carId: car?._id,
          stepCompleted: car?.stepCompleted,
          rentAmount: car?.relocationAmount?.price,
        };
      });
      setListData(formatedCarList);
    }
  }, [carList])

  return (
    <>
      <DashboardLayout title={title} subtitle={subtitle}>
        <UserBookingHeader title={title} />
        <SortAndFilterBar onStatusChange={setActiveStatus} onTimeFilterChange={setTimeFilter} onSortChange={setSortOpt} />
        <div className="row">
          <CommonBookingList title={activeStatus} tableData={filteredListData} setViewModalData={ setViewModalData} /> 
          {/* : <UserBookingCalender /> */}
        </div>
      </DashboardLayout>

      <CompletedBooking />
      <SuccessModal
        title="Successful"
        message="Your Ride has been successfully started."
        orderId="#50641"
        link={all_routes.userDashboard}
        linkText="Go to Dashboard"
        modalId="start_rides"
      />
      <ViewBooking viewModalData={ viewModalData} />
      <EditBooking />
      <CancelBooking />
      <InprogressBooking />
      <CanceledBooking />
      <RelocationStatus />
      <CancelReason />
      <DeleteBooking id="delete_modal"
        title="Delete Booking"
        message="Are you sure you want to delete this booking?" />
      <CustomDateModal />
    </>

  );
};

export default UserBookings;
