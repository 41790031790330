import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { useLocation } from "react-router-dom";
import SuccessMessage from "../../common/ui_components/SuccessMessage";
import BookingCard from "../../common/ui_components/cards/BookingCard";
// import PricingList from "../../common/ui_components/PricingList";
// import InfoList from "../../common/ui_components/BookingInfoList";
// import { bookingData } from "../../../core/data/json/common_static_data";
// import { all_routes } from "../../router/all_routes";
// import Breadcrumbs from "../../common/ui_components/breadcrumbs";
import { doGetApiCall } from "../../../utils/apiconfig";
import CircularIndeterminate from "../../common/ui_components/CircularProgress";
import { useSelector } from "react-redux";
import { removeCookie } from "../../../utils/cookieUtils";
// import useHelperFuncs from "../../../utils/hooks/useHelperFuncs";



const BookingSuccess = () => {
  // const routes = all_routes;
  // const navigate = useNavigate();

  // const { printSection } = useHelperFuncs()

  const [loading, setLoading] = useState(true);
  const [carData, setCarData] = useState<any>({});
  const [Payment_id, setPaymentId] = useState<any>({});
  const UserDetails = useSelector((state: any) => state?.authReducer?.login_data);

  // const handlePrint = () => {
  //   window.print();
  // };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const intentId = params.get('intent_id')

  useEffect(() => {
    if (intentId !== null) {
      UpdatePayment();
    }
  }, [])


  const UpdatePayment = async () => {
    const data = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/stripe/${intentId}`,
    };
    await doGetApiCall(data)
      .then(async (res: any) => {
        if (!res.error) {
          setPaymentId(res.result?.tempId)
          await getConfirmationData(res.result?.carId)
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      })
  };

  const getConfirmationData = async (id: any) => {
    const data = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars/${id}`,
    };
    await doGetApiCall(data)
      .then((res: any) => {
        if (!res.error) {
          setCarData(res.result)
          removeCookie("stepperstep")
          removeCookie("CarId")
          removeCookie("rentAmount")
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {/* <Breadcrumbs title={`Relocate your car`} subtitle={`payment`} /> */}
      {loading ? <div className='spinner_container' style={{ height: '40rem' }}><CircularIndeterminate /></div> :
        <div className="container">
          <div className="booking-card" id="printable-section">
            <SuccessMessage status={carData?.paymentStatus} orderNumber={Payment_id} />

            <div className="booking-header">
              <div className="booking-img-wrap">
                <div className="book-img">
                  <ImageWithBasePath src="assets/img/blank_car.png" alt="img" />
                </div>
                <div className="book-info">
                  <h6>{carData?.modelName}</h6>
                  <p>
                    <i className="feather icon-map-pin" /> Location:{" "}
                    {carData?.pickupLocation?.street}
                  </p>
                </div>
              </div>
              <div className="book-amount">
                <p>Total Amount</p>
                <h6>{`€ ${carData?.relocationAmount?.price}`}</h6>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 d-flex">
                <BookingCard title="Location & Time">
                  <div style={{ marginTop: '10px' }}>
                    <h6>PickupLocation</h6>
                    <p className="billing-lists" style={{ marginTop: '10px' }}>{carData?.pickupLocation?.street}</p>
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <h6>DropoffLocation</h6>
                    <p className="billing-lists" style={{ marginTop: '10px' }}>{carData?.dropoffLocation?.street}</p>
                  </div>
                  {/* <InfoList items={carData} /> */}
                </BookingCard>
              </div>

              <div className="col-lg-6 col-md-6 d-flex">
                <BookingCard title="Billing Information">
                  <ul className="billing-lists">
                    <div>{`${UserDetails?.first_name} ${UserDetails?.last_name}`}</div>
                    <div>{`+${UserDetails?.phone?.code} ${UserDetails?.phone?.number}`}</div>
                    <div>{UserDetails?.email}</div>
                    <div>{UserDetails?.address?.street}</div>
                  </ul>
                </BookingCard>
              </div>
              {/* <div><span style={{ fontWeight: '700', color: "#000" }}>Additional Information :</span>  {UserDetails?.additionalInfo}</div> */}
            </div>
          </div>

          <div className="print-btn text-center" style={{ margin: '20px 0' }} >
            {/* <Link to={`${routes.invoice}?car_id=${carData._id}`} className="btn btn-secondary" >
              Print Order
            </Link> */}

            {/* <button className="btn btn-secondary" onClick={() => printSection('printable-section')}>Print Order</button> */}
          </div>
        </div>
      }
    </>
  );
};

export default BookingSuccess;