import React, { useState } from "react";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import UserBookingTableHeader from "./UserBookingTableHeader"
import ListActionMenu from "../../../common/ui_components/ListActionMenu";
import BookingAvatar from "../../../common/ui_components/BookingAvatar";
import RelocationStatus from "../../../common/ui_components/UserBookingStatus";
import ListingTable from "../../../common/ui_components/ListingTable";
import { removeCookie, setCookie } from "../../../../utils/cookieUtils";

const CommonBookingList = ({ tableData, title, setViewModalData }: any) => {
  const navigate = useNavigate();

  const routes = all_routes;

  const [searchInput, setSearchInput] = useState("");

  const filteredData = tableData?.filter((rowData: any) => {
    const values = rowData && Object.values(rowData).map((value: any) => value?.toString().toLowerCase());
    return values?.some((value: any) => value?.includes(searchInput.toLowerCase()));
  });

  const navigatePath = () => {
    removeCookie('CarId');
    removeCookie('stepperstep');
    removeCookie('rentAmount');
    navigate(routes.relocateCar);
  }

  // const actionButtons = [
  //   { icon: "feather icon-eye", label: "View", target: "#upcoming_booking", onClick: (rowData:any) => console.log("row data on view booking", rowData) }, 
  //   // { icon: "feather icon-edit-2", label: "Edit", target: "#edit_booking" },
  //   // { icon: "feather icon-trash-2", label: "Delete", target: "#delete_modal" },
  // ];

  const locationStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  }

  const columnConfig = [
    {
      field: "carName",
      header: "Car Name",
      body: (rowData: any) => (
        <BookingAvatar
          img={rowData?.imgSrc}
          carName={rowData?.carName}
          deliveryStatus={rowData?.carType}
        />
      ),
    },
    {
      field: "deliveryStatus",
      header: "Pickup / Delivery Location",
      body: (rowData: any) => (
        <p style={locationStyle}> {rowData?.pickupLocation}
          <span className="d-block">{rowData?.pickupTime}</span>
        </p>
      ),
    },
    {
      field: "location",
      header: "Dropoff Location",
      body: (rowData: any) => (
        <p style={locationStyle}> {rowData?.dropoffLocation}
          <span className="d-block">{rowData?.dropoffTime}</span>
        </p>
      ),
    },
    {
      field: "total",
      header: "Total",
    },
    {
      field: "paymentStatus",
      header: "Payment Status",
      body: (rowData: any) => <RelocationStatus status={rowData?.paymentStatus} />,
    },
    {
      field: "bookingStatus",
      header: "Booking Status",
      body: (rowData: any) => <RelocationStatus status={rowData?.bookingStatus} />,
    },
    {
      field: "createdAt",
      header: "Created At",
      body: (rowData: any) => <RelocationStatus status={rowData?.createdAt} />,
    },
    {
      field: "action",
      header: "Action",
      body: (rowData: any) => {
        let actions = [];
        const handleClick = () => {
          setCookie("stepperstep", rowData.stepCompleted++, 7);
          setCookie("CarId", rowData.carId, 7);
          setCookie("rentAmount", rowData.rentAmount, 7);
          navigate(routes?.relocateCar);
        }
        if (rowData?.paymentStatus === 'Pending') {
          actions = [{ icon: "feather icon-edit-2", label: "Edit", onClick: () => handleClick() }, { icon: "feather icon-eye", label: "View", target: "#upcoming_booking", onClick: () => setViewModalData(rowData) }]
        } else { actions = [{ icon: "feather icon-eye", label: "View", target: "#upcoming_booking", onClick: () => setViewModalData( rowData) }] }
        return <ListActionMenu actions={actions} />
      },
    },
  ];


  return (
    <div className="col-lg-12 d-flex">
      <div className="card book-card flex-fill mb-0">
        <UserBookingTableHeader
          title={title}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          navigatePath={navigatePath}
          itemCount={filteredData?.length}
          show_btn={true}
        />
        <ListingTable filteredData={filteredData} columnConfig={columnConfig} />
      </div>
    </div>
  );
};
export default CommonBookingList;
