import React, { useEffect, useState } from "react";
import Select, { SingleValue, StylesConfig } from "react-select";

// ---------------------------------------------------------
// *,
// *::before,
// *::after {
//   box-sizing: border-box;
// }

// select:: -ms - expand {
//     display: none;
// }
// ---------------------------------------------------------

// Custom styles for react-select
const customStyles: StylesConfig<any, false> = {
    control: (base) => ({
        ...base,
        // A reset of styles, including removing the default dropdown arrow
        appearance: "none",
        // Additional resets for further consistency
        backgroundColor: "transparent",
        border: "none",
        padding: "0 1em 0 0",
        margin: 0,
        width: "100%",
        fontFamily: "inherit",
        fontSize: "inherit",
        cursor: "pointer",
        lineHeight: "inherit",
        outline: "none",
        boxShadow: "none",
    }),
    option: (base) => ({
        ...base,
        cursor: "pointer",
    }),
    singleValue: (base) => ({
        ...base,
        color: "#333", // Change text color as needed
        fontWeight: 600,
        fontSize: "20px",
        margin: 0,
        "@media (max-width: 1023px)": {
        fontSize: "16px",
        },
    }),
    placeholder: (base) => ({
        ...base,
        color: "#333", // Adjust color to match the design
        fontWeight: 600,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: "24px",
        margin: 0,
        "@media (max-width: 1428px)": {
        fontSize: "20px",
        },
        "@media (max-width: 1225px)": {
        fontSize: "16px",
        },
        "@media (max-width: 1028px)": {
        fontSize: "14px",
        },
        "@media (max-width: 926px)": {
        fontSize: "12px",
        },
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    dropdownIndicator: (base) => ({
        ...base,
        display: "none"
    }),
};

const AutoCompletePicker: React.FC<any> = ({ placeholder, options, selected, setSelected }) => {
    // Handle change of options in autocomplete
    const handleChange = (option: SingleValue<any>) => {
        setSelected(option);
    };

    const [value, setValue] = useState();

    useEffect(() => {
        setValue(selected);
    }, [selected])

    return (
        <>
            <Select<any>
                options={options}
                onChange={handleChange}
                placeholder={placeholder}
                styles={customStyles}
                value={value}
            />
        </>
    )
};

export default AutoCompletePicker;

