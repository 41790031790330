import { FormikHelpers, FormikValues, useFormik } from "formik";
import * as Yup from "yup";
import { doGetApiCall, doUploadMediaApiCall } from "../utils/apiconfig";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getCookie, setCookie } from "../utils/cookieUtils";
import { set_login_data } from "../redux/reducers/authReducer";

const useBillingForm = (setStepperState: any) => {
    // const login_data = useSelector((state: any) => state?.authReducer?.login_data);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            country: null,
            city: "",
            pincode: "",
            email: "",
            phone: "",
            additionalDescription: "",
            licenseField: "",
            license: "" as string | null,
            // document: [],
            acceptTerms: false,
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string()
                .required("First Name is required")
                .max(25, "First Name must not exceed 25 characters"),
            lastName: Yup.string()
                .required("Last Name is required")
                .max(25, "Last Name must not exceed 25 characters"),
            country: Yup.string().required("Country is required"),
            city: Yup.string().required("City is required"),
            pincode: Yup.string().required("Pincode is required")
                .max(10, "Pincode must not exceed 10 digits"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            phone: Yup.string().required("Phone Number is required"),
            additionalDescription: Yup.string(),
            // licenseField: Yup.string().required("Driving License Number is required"),
            license: Yup.mixed()
                .required("Document (license) is required")
                .test("fileSize", "File size is too large (Max 4MB)", (file: any) => {
                    if (!file) return true; // Allow empty value until required check
                    return file.size ? file.size <= 4 * 1024 * 1024 : true; // Handle both formats
                })
                .test("fileFormat", "Unsupported File Format (only images & PDF allowed)", (file: any) => {
                    if (!file) return true;
                    const allowedFormats = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
                    return allowedFormats.includes(file.type || file.mimetype); // Support both cases
                }),
            acceptTerms: Yup.bool().oneOf([true], "You must accept the terms and conditions"),
        }),
        onSubmit: async (values, action) => { handleSubmit(values, action) }
    });

    const handleSubmit = async (values: FormikValues, formikHelpers: FormikHelpers<any>) => {

        try {
            const { setSubmitting } = formikHelpers;

            const formData = new FormData();
            formData.append('first_name', values.firstName);
            formData.append('last_name', values.lastName);
            formData.append('phoneNo', values.phone);
            formData.append('address[city]', values.city);
            formData.append('address[country]', values.country);
            formData.append('address[pincode]', values.pincode);
            formData.append('license', values?.license?.key ? JSON.stringify(values.license) : values.license);
            // if (login_data?.role === 'flexiDriver' || login_data?.role === 'professionalDriver') {
            //     if (values?.licenseField) formData.append('licenseField', values.licenseField);
            //     else setFieldError('licenseField', 'Driving License Number is required');
            // }
            formData.append('termsandcondition', values.acceptTerms)
            formData.append('additionalInfo', values.additionalDescription)

            await doUploadMediaApiCall({ url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/users/update`, bodyData: formData }).then(async (res: any) => {
                if (!res.error) UpdateCarData();
                else console.error(res.error || "Save failed");
            }).catch((err) => {
                toast.error("something went wrong");
                console.error(err, '<<-- err');
            }).finally(() => setSubmitting(false));
        } catch (error) {
            console.error("Error during submission ---->", error);
        }
    };

    const UpdateCarData = async () => {
        const carId = getCookie('CarId');
        const formData = new FormData();
        formData.append("carId", carId);
        formData.append("stepCompleted", "3");
        const data = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars/${carId}`,
            bodyData: formData,
        };
        await doUploadMediaApiCall(data)
            .then(async (res: any) => {
                if (!res.error) {
                    toast.success("Billing Information saved successfully !!")
                    setStepperState((prev: any) => prev + 1);
                    setCookie("stepperstep", 3)
                } else {
                    toast.error(res.message)
                    console.error(res.error || "Save failed");
                }
            })
            .catch((err) => {
                console.error(err, '<<-- err');
            })
    };

    const fetchUserData = async () => {
        const userDataRequest = { url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/users/details` };

        try {
            const response: any = await doGetApiCall(userDataRequest);
            console.log("response==>", response)
            if (!response.error) {
                dispatch(set_login_data(response.result));
                setCookie("userdata", response.result, 7);
                formik.setValues({
                    firstName: response?.result?.first_name || "",
                    lastName: response?.result?.last_name || "",
                    phone: response?.result?.phoneNo || "",
                    email: response?.result?.email || "",
                    country: response?.result?.address?.country || "",
                    city: response?.result?.address?.city || "",
                    pincode: response?.result?.address?.pincode || "",
                    additionalDescription: response?.result?.additionalInfo || "",
                    licenseField: response?.result?.licenseField || "",
                    license: response?.result?.license,
                    acceptTerms: response?.result?.termsandcondition,
                });
            }
        } catch (err) {
            console.error("Error fetching user data:", err);
        }
    };
    return { formik, fetchUserData }
}

export default useBillingForm