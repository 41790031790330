import React, { useEffect, useRef, RefObject, useState } from "react";
import Breadcrumbs from "../../common/ui_components/breadcrumbs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from "aos";
import CarSpecification from "./CarSpecification";
import DetailsHeader from "./DetailsHeader";
import CarImagesCarousel from "./CarImagesCarousel";
import CarExtraServices from "./CarExtraServices";
import CarDescription from "./CarDescription";
import CarFeatures from "./CarFeatures";
import CarGallary from "./CarGallary";
import Policies from "./Policies";
// import PricingDetails from "./PricingDetails";
import AvailabilityCheck from "./LocationDateInfo";
import ViewCarLocation from "./ViewCarLocation";
// import SuggestedVehicle from "./SuggestedVehicle";
import AvailabilityDetails from "./AvailabilityDetails";
// import { carImages } from "../../../core/data/json/common_static_data";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { fetchCarById } from "../../../redux/reducers/carReducer";
import { useDispatch, useSelector } from "react-redux";
import { all_routes } from "../../router/all_routes";
import { getCookie } from "../../../utils/cookieUtils";
import AvailabilityCalender from "./AvailabilityCalender";
// import { useFormik } from "formik";
// import * as Yup from "yup";



const listingDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { searchdata } = location.state || {}; // Destructure the passed state
  // console.log("searchdata in details", searchdata)
  const [searchParams] = useSearchParams();
  const token = getCookie('token');
  const routes = all_routes;

  const singleCarDetails = useSelector((state: any) => state?.carReducer?.singleCarDetails);

  const [currCarID, setCurrCarID] = useState<string | null>('');
  const [headerLocationData, setHeaderLocationData] = useState<any>();
  const [availabilityCheckData, setAvailabilityCheckData] = useState<any>();

  const bigImgSliderRef: RefObject<Slider> = useRef<Slider>(null);
  const thumbnailSliderRef: RefObject<Slider> = useRef<Slider>(null);

  const handleBookClick = (formValues: any) => {
    // console.log("Form values ---->", formValues);

    if (token) {
      // console.log("dateRange", new Date(dateRange?.selection?.startDate).toLocaleDateString());
      navigate(`${routes.BookingConformation}?carId=${singleCarDetails?._id}`, {
        state: {
          bookingFormValues: formValues,
          headerLocationData: headerLocationData
        }
      });
    } else navigate(routes.login)
  }

  useEffect(() => {
    if (bigImgSliderRef.current && thumbnailSliderRef.current) {
      bigImgSliderRef.current.slickGoTo(0);
      thumbnailSliderRef.current.slickGoTo(0);
    }
  }, []);
  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);

  // const pricingData = [
  //   { label: "Free day", days: 10, perDayAmount: 0, amount: 0 },
  //   { label: "Paid day", days: 2, perDayAmount: 100, amount: 200 },
  //   { label: "Total amount", amount: 200 },
  // ];

  useEffect(() => {
    // console.log('searchParams', searchParams);
    if (searchParams?.size > 0) {
      const carId = searchParams.get("carId");
      setCurrCarID(carId);
    }
  }, [searchParams])

  useEffect(() => {
    if (currCarID) {
      dispatch(fetchCarById(currCarID));
    }
  }, [currCarID])

  useEffect(() => {
    if (Object.keys(singleCarDetails).length > 0) {
      setHeaderLocationData({
        pickupLocation: singleCarDetails?.pickupLocation?.street,
        pickupDate: singleCarDetails?.pickupDate,
        dropoffLocation: singleCarDetails?.dropoffLocation?.street,
        dropoffDate: singleCarDetails?.dropoffDate
      });

      setAvailabilityCheckData({
        pickupLocation: singleCarDetails?.pickupLocation?.street,
        dropoffLocation: singleCarDetails?.dropoffLocation?.street,
        pickupDate: singleCarDetails?.pickupDate,
        pickupTime: singleCarDetails?.pickupTime,
        dropoffDate: singleCarDetails?.dropoffDate,
        dropoffTime: singleCarDetails?.dropoffTime,
      })
    }
  }, [singleCarDetails])

  return (
    <div className="main-wrapper">
      <Breadcrumbs title={singleCarDetails?.modelName} subtitle="Listings" maintitle={""} searchdata={searchdata} />
      <DetailsHeader />
      <section className="section product-details">
        <div className="details-container">
          <div className="row">
            <div className="col-lg-8">
              <CarImagesCarousel />
              <CarExtraServices />
              <CarDescription />
              <CarSpecification />
              <CarFeatures />
              <CarGallary />
              <Policies />
            </div>
            <div className="col-lg-4 theiaStickySidebar">
              <div className="stickybar">
                <AvailabilityCheck dateTimeData={availabilityCheckData} />
                <AvailabilityCalender
                  // formik={formik}
                  carAvailabilityData={availabilityCheckData}
                  handleBookClick={handleBookClick}
                  seatingCapacity={singleCarDetails?.seatingCapacity}
                // noOfPersons={noOfPersons}
                // setNoOfPersons={setNoOfPersons}
                />
                <ViewCarLocation />
                {/* <PricingDetails pricingItems={pricingData} /> */}
              </div>
            </div>
          </div>
          {/* <SuggestedVehicle /> */}
        </div>
      </section>
      <AvailabilityDetails />
    </div>
  );
};

export default listingDetails;
