// import { isExpired } from "react-jwt";
import { getCookie, setCookie } from "../cookieUtils";
import { doPostApiCall } from "../apiconfig";
import { toast } from "react-toastify";

export default async function isValidToken(token: string, isTokenValid:any) {
  // console.log("Token:", token);
  
  try {
    if (token) {
      // const decodedToken = decodeToken(token);
      // console.log("Decoded Token:", decodedToken);
      const isTokenExpired = await isTokenValid(token);
      // console.log("is token expire===>", isTokenExpired)
      if (isTokenExpired) {
        const refreshToken = getCookie('refToken');
        // console.log("refresh token===>", refreshToken)
        const isRefTokenExpired = await isTokenValid(refreshToken);
        // console.log("is ref token expire===>", isRefTokenExpired
        if (!isRefTokenExpired) {
          // api call for new access token
          const generateAccessTokenData: any = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/auth/access/token`,
            bodyData: {
              refreshToken: refreshToken,
              deviceId: "test",
            }
          }

          const response: any = await doPostApiCall(generateAccessTokenData);
          if (!response.error) {
            toast.success(response.message);
            setCookie("token", response.token, 30);
          } else {
            toast.error("Something went wrong!");
            return true;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  } catch (error) {
    console.error(error, "Error in token check")
    return true;
  }
}