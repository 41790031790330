import React from "react";
import ImageWithBasePath from "../../../../core/data/img/ImageWithBasePath";


const ProcessCard = ({ card }: any) => {
  return (
    <div className="col-lg-4 col-md-6 col-12 d-flex mb-4" data-aos="fade-down">
      <div className="card flex-fill">
        <div className="card-body">
          <div className={`choose-img ${card.imgClass}`}>
            <ImageWithBasePath src={card.imgSrc} alt="icon" />
          </div>
          <div className="choose-content">
            <h4>{card.title}</h4>
            <p>{card.content}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessCard;
