import React from "react";
import ImageWithBasePath from "../../../../core/data/img/ImageWithBasePath";
import dayjs from 'dayjs';

const UserQuoteCard = (data: any) => {
  return (
    <div className="user-quote-card">
      <div className="user-quote-car-details">
        <div className="book-img user-quote-img">
          <ImageWithBasePath src="assets/img/car-shimmer.png" alt="img" />
        </div>
        <div className="user-quote-name-fleet">
          <div className="user-quote-name">{data?.data?.name}</div>
          <div className="user-quote-fleet-for-smalL-screen"><ImageWithBasePath src="assets/img/user-quote-car-vector.svg" className="me-1" />{data?.data?.fleetSize} Fleets</div>
        </div>
      </div>
      <div className="user-quote-location">
        <div className="user-quote-pickup-location">
          <div>{data?.data?.pickupLocation}</div>
          <div className="user-quote-date-time">{dayjs(data?.data?.pickupDate).format("DD MMM YYYY")} {data?.data?.pickuptime?.from}</div>
        </div>
        <ImageWithBasePath className="location-toggle-image img-fluid" src="assets/img/icons/exchange.png" alt="" />
        <div className="user-quote-dropoff-location">
          <div>{data?.data?.dropoffLocation}</div>
          <div className="user-quote-date-time">{dayjs(data?.data?.dropoffDate).format("DD MMM YYYY")} {data?.data?.dropoffTime?.from}</div>
        </div>
      </div>
      <div className="user-quote-fleet"><ImageWithBasePath src="assets/img/user-quote-car-vector.svg" className="me-1" />{data?.data?.fleetSize} Fleets</div>
    </div>
  )
};

export default UserQuoteCard;
