import { useFormik } from 'formik';
import { useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import { doPostApiCall } from '../utils/apiconfig';

const useContactUsHooks = () => {
    const [isLoading, setIsLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            issue: '',
        },
        validationSchema: Yup.object({
            // name: Yup.string().required('Name is required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Email is required'),
            issue: Yup.string().required('Message is required'),

        }),
        onSubmit: (values, { resetForm }) => {
            handleContactUsApi(values, resetForm)
        },
    });

    async function handleContactUsApi(bodyData: any, resetForm?: () => void) {
        setIsLoading(true);
        const data = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/contacts`,
            bodyData,
        };

        try {
            const res: any = await doPostApiCall(data);
            if (res?.error) {
                switch (res.code) {
                    case 'INTERNAL_SERVER_ERROR':
                        toast.error('Failed to send message.');
                        break;
                    default:
                        toast.error('An unexpected error occurred.');
                        break;
                }
            } else {
                switch (res.code) {
                    case 'CONTACT_CREATED':
                        toast.success("Thank you for reaching out to us! We will get back to you shortly.");
                        resetForm?.();
                        break;
                    default:
                        toast.success('Thank you for reaching out to us! We will get back to you shortly.');
                        resetForm?.();
                        break;
                }
            }
        } catch (err: any) {
            console.error('Error occurred:', err);
            toast.error('Something went wrong!');
        } finally {
            setIsLoading(false);
        }
    }

    return {
        isLoading,
        formik
    }
}

export default useContactUsHooks