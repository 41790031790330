import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import {
  authenticationRoute,
  LandingRoutes,
  pageroutes,
  publicRoutes,
  usermodule,
  FlexiDriverRoutes,
  carOwnerRoutes,
  SubLandingRoutes
  // ProfessionalDriverRoutes
} from "./router.link";
import Header from "../common/Header/header";
import Footer from "../common/Footer/footer";
import SpecializationSection from "../components/MainLandingSection/Specialization";
import RatingAboutUsSection from "../components/MainLandingSection/RatingAboutUsSection";
import WorkingProcessSection from "../components/MainLandingSection/WorkingProcessSection";
import AppAvailableSection from "../components/MainLandingSection/AppAvailableSection";
import ServingLocationSection from "../components/MainLandingSection/ServingLocationSection";
import ContactUsBanner from "../components/MainLandingSection/ContactUsBanner";
import ProtectedRoute from "./guards/ProtectedRoute";
import { useSelector } from "react-redux";

// import isValidToken from "../../utils/hooks/checkTokenExpiary";


const generateProtectedRoutes = (routesArray: any, allowedRoles: any) => {
  return routesArray.map(({ path, element }: any, idx: any) => (
    <Route
      key={idx}
      path={path}
      element={<ProtectedRoute element={element} allowedRoles={allowedRoles} />}
    />
  ));
};

const HeaderLayout = () => (
  <>
    <Outlet />
  </>
);

const PageLayout = () => (
  <>
    <Header />
    <Outlet />
    <Footer />
  </>
);

const UserLayout = () => (
  <>
    <Header />
    <Outlet />
    <Footer />
  </>
);

const LandingLayout = () => (
  <>
    <Header />
    <Outlet />
    <SpecializationSection />
    <WorkingProcessSection />
    <RatingAboutUsSection />
    <AppAvailableSection />
    <ContactUsBanner />
    <ServingLocationSection />
    <Footer />
  </>
);

const AllRoutes = () => {
  const login_data = useSelector((state: any) => state?.authReducer?.login_data);

  return (
    <>
      <Routes>
        {/* Landing Routes - no protection */}
        <Route path="/" element={<LandingLayout />}>
          {LandingRoutes.map((route, idx) => {
            return login_data?.role && route.openForAll ? null : <Route path={route.path} element={route.element} key={idx} />
          })}
        </Route>

        {!login_data?.role && <Route path="/" element={<LandingLayout />}>
          {SubLandingRoutes.map((route, idx) => {
            return login_data?.role && route.openForAll ? null : <Route path={route.path} element={route.element} key={idx} />
          })}
        </Route>}

        {/* Public Routes - no protection */}
        <Route path="/" element={<HeaderLayout />}>
          {publicRoutes.map((route, idx) => <Route path={route.path} element={route.element} key={idx} />)}
        </Route>

        {/* Page Routes - no protection */}
        <Route path="/pages" element={<PageLayout />}>
          {pageroutes.map((route, idx) => <Route path={route.path} element={route.element} key={idx} />)}
        </Route>

        {/* Car Owner Routes - protected */}
        <Route path="/relocate" element={<PageLayout />}>
          {generateProtectedRoutes(carOwnerRoutes, ['carOwner'])}
        </Route>

        {/* Flexi Driver Routes - protected */}
        <Route path="/rent" element={<PageLayout />}>
          {generateProtectedRoutes(FlexiDriverRoutes, ['flexiDriver'])}
        </Route>
        {/* User Module Routes - protected */}
        <Route path="/user" element={<UserLayout />}>
          {generateProtectedRoutes(usermodule, ['carOwner', 'flexiDriver', 'professionalDriver'])}
        </Route>

        {/* Authentication Routes - no protection */}
        <Route path="/" element={<HeaderLayout />}>
          {authenticationRoute.map((route, idx) => <Route path={route.path} element={route.element} key={idx} />)}
        </Route>

        {/* Redirect to main landing page for undefined routes */}
        <Route path="/" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
