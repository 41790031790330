import React, { useState } from "react";
import AuthenticationHeader from "../common/ui_components/AuthenticationComponents/AuthenticationHeader";
import AuthenticationFooter from "../common/ui_components/AuthenticationComponents/AuthenticationFooter";
import WrapperModal from "../common/ui_components/AuthenticationComponents/WrapperModal";
import Countdown from "react-countdown";
import { Button, Typography } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { doPostApiCall } from "../../utils/apiconfig";
import { useLocation, useNavigate } from "react-router-dom";
import { all_routes } from "../router/all_routes";
// import { useSignup } from "../../services/auth/signup";
import { callResendOtp, resetAuthReducerState } from "../../redux/reducers/authReducer";
import { toast } from "react-toastify";



const OtpVerification = ({ modal = false, setModal = null, type = 'email', otpFor = '' }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { emailFromForgotPass } = location.state || {}; // Destructure the passed state
  // const { openMessage } = useSignup();

  const signupData = useSelector((state: any) => state?.authReducer?.signup_data);

  const routes = all_routes;
  const BASE_API_URL = process.env.REACT_APP_PUBLIC_BASE_API_URL;
  const [resendOtp, setResendOtp] = useState(false);
  const [endTime, setEndTime] = useState(Date.now() + 5 * 60 * 1000);  // initialize countdown

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      setResendOtp(true);
      // return <span>{minutes}:{seconds < 10 ? `0${seconds}` : seconds} sec</span>;
    } else {
      // Render a countdown
      return (
        <span>{minutes}:{seconds < 10 ? `0${seconds}` : seconds} sec</span>
      );
    }
  };

  const initialValues = {
    otp: '',
  };

  const validationSchema = Yup.object({
    otp: Yup.string()
      .required('OTP is required')
      .length(6, 'OTP must be 6 digits'),
  });

  const handleSubmit = async (values: any) => {
    const bodyData: any = { otp: values.otp };
    if (type == 'email') {
      bodyData.email = emailFromForgotPass || signupData?.email;
      // if (emailFromForgotPass) bodyData.email = emailFromForgotPass;
      // else bodyData.email = signupData?.email;
    } else bodyData.phone = otpFor;

    try {
      const response: any = await doPostApiCall({ url: `${BASE_API_URL}/auth/verify/otp`, bodyData });
      if (response?.error) {
        toast.error(`${response?.message} !!`)
        return;
      }
      toast.success(`${response?.message} !!`)
      if (modal) setModal(false);
      else if (type == 'email') {
        dispatch(resetAuthReducerState());
        if (emailFromForgotPass) navigate(routes.resetPassword, { state: { emailFromForgotPass } });
        else setTimeout(() => navigate(routes.login), 1000);
      }
    } catch (err) {
      console.error("An error occurred during otp verification", err);
    }
  };

  const handleResendOtp = () => {
    const body = type === 'email' ? { email: emailFromForgotPass || signupData?.email } : { phone: otpFor };
    // const body: any = {};
    // if (type == 'email') {
    //   if (emailFromForgotPass) body.email = emailFromForgotPass;
    //   else body.email = signupData?.email;
    // } else body.phone = otpFor;

    dispatch(callResendOtp(body));
    setResendOtp(false);
    setEndTime(Date.now() + 5 * 60 * 1000);

    // renderer({ minutes: 4, seconds: 59, completed: false });
  };

  // useEffect(() => {
  //   console.log("resendOtp state", resendOtp);
  // }, [resendOtp])

  return (
    <>
      <div className="main-wrapper login-body">
        {!modal && <AuthenticationHeader />}
        <div className="login-wrapper">
          <WrapperModal>
            <h1>OTP Verification</h1>
            <p className="account-subtitle"> We have sent a verification code to your {type} - <span style={{ color: '#000' }}>{type == 'email' ? (emailFromForgotPass ? emailFromForgotPass : signupData?.email) : `+${otpFor}`}</span> </p>
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isValid, dirty }) => (
                  <Form>
                    <div className="input-block">
                      <label className="form-label">OTP <span className="text-danger">*</span></label>
                      <Field type="number" name="otp" placeholder="Enter OTP" className="form-control otp-input" />
                      <ErrorMessage name="otp" component="div" className="error text-danger" />
                    </div>

                    <div className="timer-resend" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography variant="body2" color="textSecondary">
                        {!resendOtp ?
                          <Countdown date={endTime} renderer={renderer} />
                          : <span>0:00 sec</span>
                        }
                      </Typography>
                      <Typography variant="body2" className="resend-otp-text">
                        Didn’t receive the OTP?{" "}
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => handleResendOtp()}
                          disabled={!resendOtp}
                          className="resend-otp"
                        >
                          Resend OTP
                        </Button>
                      </Typography>
                    </div>

                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      type="submit"
                      className="verify-btn"
                      disabled={!(dirty && isValid)}
                    >
                      Verify
                    </Button>
                  </Form>
                )}
              </Formik>
            </>
          </WrapperModal>
        </div>
        {!modal && <AuthenticationFooter />}
      </div>
    </>
  );
};

export default OtpVerification;