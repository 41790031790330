import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../../core/data/img/ImageWithBasePath";

// BookingHeader Component
export const BookingHeader = ({ carImage, carModel, location, amount }: any) => (
  <div className="booking-header">
    <div className="booking-img-wrap">
      <div className="book-img">
        <ImageWithBasePath src={carImage} alt="Car Image" srcType="new"/>
      </div>
      <div className="book-info">
        <h6>{carModel}</h6>
        <p><i className="feather icon-map-pin" /> Location : {location}</p>
      </div>
    </div>
    <div className="book-amount">
      <p>Total Amount</p>
      <h6>
        {amount}{" "}
        {/* <a href="#">
          <i className="feather icon-alert-circle" />
        </a> */}
      </h6>
    </div>
  </div>
);

// BookingDetails Component
const BookingDetails = ({ details }: any) => (
  <div className="booking-wrapper">
    <div className="booking-title">
      <h6>Booking Details</h6>
    </div>
    <div className="row">
      {details.map((detail: any, index: any) => (
        <div key={index} className="col-lg-4 col-md-6">
          <div className="booking-view">
            <h6>{detail.label}</h6>
            {Array.isArray(detail.values) ? (
              detail.values.map((value: any, idx: any) => <p key={idx}>{value}</p>)
            ) : (
              <p>{detail.values}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
);

// ModalActions Component
const ModalActions = () => (
  <div className="modal-btn modal-btn-sm text-end">
    <Link
      to="#"
      data-bs-target="#cancel_ride"
      data-bs-toggle="modal"
      data-bs-dismiss="modal"
      className="btn btn-secondary"
    >
      Cancel Booking
    </Link>
    <Link
      to="#"
      data-bs-target="#start_rides"
      data-bs-toggle="modal"
      data-bs-dismiss="modal"
      className="btn btn-primary"
    >
      Start Ride
    </Link>
  </div>
);

// Main ViewBooking Component
const ViewBooking = ({ viewModalData }: any) => {

  const bookingDetails = [
    { label: 'Car Type', values: viewModalData?.carType },
    { label: 'Dropoff Location', values: viewModalData?.dropoffLocation },
    { label: 'Payment Status', values: viewModalData?.paymentStatus },
    { label: 'Start Date', values: viewModalData?.pickupTime },
    { label: 'Pickup Time', values: viewModalData?.pickupTime },
    { label: 'End Date', values: viewModalData?.dropoffTime }
  ];

  return (
    viewModalData && (
    <div
      className="modal new-modal multi-step fade"
      id="upcoming_booking"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close-btn" data-bs-dismiss="modal">
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <BookingHeader
              carImage={viewModalData?.imgSrc}
              carModel={viewModalData?.carName}
              location={viewModalData?.pickupLocation}
              amount={viewModalData?.total}
            />
            <BookingDetails details={bookingDetails} />
            {/* <PersonalDetails personalInfo={personalInfo} /> */}
            <ModalActions />
          </div>
        </div>
      </div>
    </div>
  ))
};

export default ViewBooking;




{/* <div className="cancel-reason">
                <h6>Cancel Reason</h6>
                <p>
                  On the booking date i have other work on my personal so i am
                  cancelling my bookingOn the booking date i have other work on
                  my personal so i am cancelling my booking On the booking date
                  i have other work on my personal so i am cancelling my booking
                </p>
              </div>
              <div className="cancel-box">
                <p>
                  Cancelled By User on 17 Sep 2023, Refund will deposited in
                  user account on 19 Sep 2023{" "}
                </p>
              </div>
            </div >
  <div className="modal-btn modal-btn-sm text-end">
    <a
      href="#"
      data-bs-target="#view_status"
      data-bs-toggle="modal"
      data-bs-dismiss="modal"
      className="btn btn-primary"
    >
      View status
    </a>
  </div> */}