// WorkingProcessSection.js
import React from "react";
import { cardData } from "../../../core/data/json/common_static_data";
import ProcessCard from "../../common/ui_components/cards/ProcessCard";

const WorkingProcessSection = () => {
    return (
        <section className="section services-new popular-explore" id='how-it-works'>
            <div className="card_container">
                <div className="section-heading" data-aos="fade-down">
                    <h2>How it works</h2>
                    <p>Lorem Ipsum has been the industry standard dummy text ever since the 1500s,</p>
                </div>
                <div className="row">
                    {cardData.map((card, index) => (
                        <ProcessCard key={index} card={card} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default WorkingProcessSection;
