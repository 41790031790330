import React from 'react';
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath';

const ListingDetailsGroup = ({car}:any) => {
  return (
    
      <ul>
          <li>
            <span>
              <ImageWithBasePath
                src="assets/img/icons/s7.png"
                alt="icon"
              />
            </span>
            <p>{car?.year}</p>
          </li>
          <li>
            <span>
              <ImageWithBasePath
                src="assets/img/icons/s2.png"
                alt="icon"
              />
            </span>
            <p>{car?.make?.name}</p>
          </li>
          <li>
            <span>
              <ImageWithBasePath
                src="assets/img/icons/s8.png"
                alt="icon"
              />
            </span>
            <p>{car?.fuelType?.name}</p>
          </li>
          <li>
            <span>
              <ImageWithBasePath
                src="assets/img/icons/s4.png"
                alt="icon"
              />
            </span>
            <p>{car?.transmissionType?.name}</p>
          </li>
      </ul>

  );
};

export default ListingDetailsGroup;
