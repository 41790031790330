import React from 'react';
import { img_path } from '../../../environment'

const ImageWithBasePath = (props: any) => {
  // Combine the base path and the provided src to create the full image source URL

  let fullSrc = props?.srcType === 'new' ? props?.src : `${img_path}${props?.src}`;
  if (props?.src?.includes('blob')) {
    fullSrc = props.src;
  }
  return (
    <img
      crossOrigin="anonymous"
      className={props.className}
      src={fullSrc}
      height={props.height}
      alt={props.alt}
      width={props.width}
      id={props.id}
    // onError={(e: any) => console.log('onError hit ==>', e.target)}
    // onError={(e) => { e.currentTarget.src = defaultImg; }}
    // onLoad={() => {}}
    />
  );
};

export default ImageWithBasePath;
