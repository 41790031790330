import React from 'react';
import DashboardBookingItem from './DashboardBookingItem';



type Props = {
  carList: Array<{
    imgSrc: string;
    carName: string;
    carType: string;
    startDate: string;
    endDate: string;
    price: string;
    paymentStatus: string;
    relocationStatus: string;
    
    carId: string;
    stepCompleted: number;
    rentAmount: string;
    route: string;
  }>;
}

const DashboardBookingTable = ({ carList }: Props) => {
  return (
    <div className="table-responsive dashboard-table dashboard-table-info">
      <table className="table">
        <tbody>
          {carList.map((car, index) => (
            <DashboardBookingItem key={index} {...car} />
          ))}
        </tbody>
      </table>
    </div>
  )
};

export default DashboardBookingTable;
