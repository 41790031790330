import React from "react";

type Props = {
    label?: string;
    placeholder?: string;
    iconClass?: string;
    readOnly?: boolean;
    value?: string | null;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    id?:string;
}

const IconInputField = ({ label, placeholder, iconClass, readOnly, value, id, onChange, required = false }: Props) => {

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const trimmedValue = e.target.value.trim(); // Trim when focus is lost
        if (onChange) {
            const event = { ...e, target: { ...e.target, value: trimmedValue } }; // Pass trimmed value
            onChange(event as React.ChangeEvent<HTMLInputElement>);
        }
    };

    return (
        <div className="input-block">
            <label>{label} {required && <span className="text-danger"> *</span>}</label>
            <div className="group-img">
                <input
                    type="text"
                    className="form-control"
                    style={{ paddingLeft: !iconClass ? '15px' : '35px' }}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    value={value || ''}
                    id={id}
                    onChange={onChange}
                    onBlur={handleBlur} // Trim only when the user leaves the input
                />
                {iconClass && (
                    <span>
                        <i className={iconClass} />
                    </span>
                )}
            </div>
        </div>
    );
};

export default IconInputField;
