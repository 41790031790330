import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doPostApiCall } from '../../utils/apiconfig';
import { getCookie } from '../../utils/cookieUtils';



const BASE_API_URL = process.env.REACT_APP_PUBLIC_BASE_API_URL;

// <------------------------- Thunk For signUp ------------------------->
export const signUp = createAsyncThunk('/auth/signup', async (body: any) => {
    // console.log("body ===>", body);

    // const requestOptions = {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify(body),
    // };

    const response: any = await doPostApiCall(body);
    const res = await response.json();
    // console.log("Api Response --------->", res);
    return res.result;
});

// <------------------------- Thunk For Login ------------------------->
export const login = createAsyncThunk('/auth/login', async (body: any) => {
    // console.log("body ===>", body);

    const response: any = await doPostApiCall(body);
    const res = await response.json();
    // console.log("Api Response --------->", res);
    return res.result;
});

// <------------------------- Thunk For Login ------------------------->
export const forgotPassword: any = createAsyncThunk('/auth/forgotPassword', async (body: any) => {
    // console.log("body ===>", body);

    const res: any = await doPostApiCall(body);
    // console.log("Api Response --------->", res);
    return res;
});

// <------------------------- Thunk For Login ------------------------->
export const callResetPass: any = createAsyncThunk('/auth/callResetPass', async (body: any) => {
    // console.log("body ===>", body);

    const res: any = await doPostApiCall({ url: `${BASE_API_URL}/auth/reset/password`, bodyData: body });
    // console.log("Api Response --------->", res);
    return res;
});

// <------------------------- Thunk For Login ------------------------->
export const callResendOtp: any = createAsyncThunk('/auth/callResendOtp', async (body: any) => {
    // console.log("body ===>", body);

    const res: any = await doPostApiCall({ url: `${BASE_API_URL}/auth/resend/otp`, bodyData: body });
    // console.log("Api Response --------->", res);
    return res;
});

const cookieUserData = getCookie('userdata') ? getCookie('userdata') : {};
// console.log("cookieUserData===>",cookieUserData)

interface AuthState {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
    signup_data: any,
    login_data: any,
    forgotPassRes: any,
    resetPassRes: any,
    resendOtpRes: any
}

const initialState: AuthState = {
    loading: 'idle',
    signup_data: {},
    login_data: cookieUserData,
    forgotPassRes: {
        code: '',
        error: false,
        message: '',
        status: null
    },
    resetPassRes: {
        code: '',
        error: false,
        message: '',
        status: null
    },
    resendOtpRes: {
        code: '',
        error: false,
        message: '',
        status: null
    },
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetAuthReducerState() {
            return initialState;
        },

        set_signup_data(state, action) {
            state.signup_data = action?.payload;
            // return { ...state, signup_data: action?.payload };
        },

        set_login_data(state, action) {
            state.login_data = action?.payload;
            // return { ...state, login_data: action?.payload };
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed

        // <------ Reducers for signUp ------> 
        builder.addCase(signUp.pending, (state) => {
            state.loading = 'pending';
        });
        builder.addCase(signUp.fulfilled, (state) => {
            state.loading = 'succeeded';
        })
        builder.addCase(signUp.rejected, (state) => {
            state.loading = 'failed';
        })

        // <------ Reducers for forgotPassword ------> 
        builder.addCase(forgotPassword.pending, (state) => {
            state.loading = 'pending';
        });
        builder.addCase(forgotPassword.fulfilled, (state, action) => {
            state.loading = 'succeeded';
            const { code = '', error = false, message = '', status = null } = action.payload;
            state.forgotPassRes = { ...state.forgotPassRes, code, error, message, status };
        })
        builder.addCase(forgotPassword.rejected, (state) => {
            state.loading = 'failed';
        })

        // <------ Reducers for callResetPass ------> 
        builder.addCase(callResetPass.pending, (state) => {
            state.loading = 'pending';
        });
        builder.addCase(callResetPass.fulfilled, (state, action) => {
            state.loading = 'succeeded';
            const { code = '', error = false, message = '', status = null } = action.payload;
            state.resetPassRes = { ...state.resetPassRes, code, error, message, status };
        })
        builder.addCase(callResetPass.rejected, (state) => {
            state.loading = 'failed';
        })

        // <------ Reducers for callResendOtp ------> 
        builder.addCase(callResendOtp.pending, (state) => {
            state.loading = 'pending';
        });
        builder.addCase(callResendOtp.fulfilled, (state, action) => {
            state.loading = 'succeeded';
            const { code = '', error = false, message = '', status = null } = action.payload;
            state.resendOtpRes = { ...state.resendOtpRes, code, error, message, status };
        })
        builder.addCase(callResendOtp.rejected, (state) => {
            state.loading = 'failed';
        })
    },
})

// Action creators are generated for each case reducer function
export const { resetAuthReducerState, set_signup_data, set_login_data } = authSlice.actions;
export default authSlice.reducer;