import React, { useEffect } from "react";
import ExtraServicesRelocation from "./ExtraServicesRelocation";
import SpecializationField from "./SpecializationField";
import RelocatingCarDetails from "./RelocatingCarDetails";
import CarFeatures from "./RelocationCarFeatures";
import FileUpload from "./FileUpload";
import useCarOwnerHooks from "../../../hooks/useCarDetailsHooks";

const FILE_SIZE = 4 * 1024 * 1024; // 4MB in bytes
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];



const DetailsForm = ({ setStepperState }: any) => {
    const { formik, imagePreviews, setImagePreviews, fetchCarData } = useCarOwnerHooks(setStepperState);

    useEffect(() => {
        fetchCarData()
    }, [])


    return (
        <form onSubmit={formik.handleSubmit} className="main-form-container">
            <div className="form-details shadow-lg p-4">
                <RelocatingCarDetails formik={formik} />
                <ExtraServicesRelocation formik={formik} />
                <SpecializationField formik={formik} />
                <CarFeatures formik={formik} />
                <p>Gallery</p>
                <div className="d-flex align-items-center over-mt">
                    <div className="border-line"></div>
                    <div className={`exc-border`}></div>
                </div>
                <FileUpload formik={formik} FILE_SIZE={FILE_SIZE} SUPPORTED_FORMATS={SUPPORTED_FORMATS} imagePreviews={imagePreviews} setImagePreviews={setImagePreviews} />
            </div>
            <div className="d-flex justify-content-center justify-content-md-end align-items-center w-100 gap-3">
                <button onClick={() => setStepperState((prev: any) => prev - 1)} className="bg-black text-white py-2 px-5 d-flex justify-content-center align-items-center rounded-2">
                    Back
                </button>
                <button className="bg-black text-white py-2 px-5 d-flex justify-content-center align-items-center rounded-2" type="submit">
                    Save & Next
                </button>
            </div>
        </form>
    );
};

export default DetailsForm;

