import React, { useEffect, useState } from "react";
import CustomAccordion from "../../common/ui_components/Accordion";
import IconButton from "../../common/ui_components/buttons/IconButton";
import useDropdownOptions from "../../../hooks/DropDownOption";

const FilterSection = ({ setFilterQuery }: any) => {
    const { options: makeOptions } = useDropdownOptions("make");
    const { options: transmissionOptions } = useDropdownOptions("transmissionType");
    const { options: fuelOptions } = useDropdownOptions("fuelType");
    const { options: carOptions } = useDropdownOptions("carCategory");

    const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
    const [filterSections, setFilterSections] = useState<any[]>([]);
    const [resetTrigger, setResetTrigger] = useState(false); // Reset state

    useEffect(() => {
        setFilterSections([
            { id: "carCategory", title: "Car Category", type: "checkbox", items: carOptions },
            { id: "makeId", title: "Car Brand", type: "checkbox", items: makeOptions },
            { id: "transmissionType", title: "Transmission", type: "checkbox", items: transmissionOptions },
            { id: "fuelType", title: "Fuel Type", type: "checkbox", items: fuelOptions },
        ]);
    }, [carOptions, makeOptions, transmissionOptions, fuelOptions]);

    const handleCheckboxChange = (checked: boolean, id: string, value: string) => {
        setSelectedFilters((prev) => {
            const updatedFilters = { ...prev };

            if (checked) {
                updatedFilters[id] = updatedFilters[id] ? [...updatedFilters[id], value] : [value];
            } else {
                updatedFilters[id] = updatedFilters[id]?.filter((item) => item !== value) || [];
                if (updatedFilters[id].length === 0) delete updatedFilters[id];
            }

            return updatedFilters;
        });
    };

    const handleFilter = () => {
        const queryString = Object.entries(selectedFilters)
            .map(([key, values]) => `${key}=${encodeURIComponent(JSON.stringify(values))}`)
            .join("&");
        setFilterQuery(queryString ? `&${queryString}` : "");
    };

    const resetFilters = () => {
        setSelectedFilters({});
        setFilterQuery("");
        setResetTrigger((prev) => !prev); // Toggle reset trigger
    };

    return (
        <div className="stickybar shadow_class">
            <form action="#" autoComplete="off" className="sidebar-form">
                <div className="sidebar-heading">
                    <h3>What Are You Looking For</h3>
                </div>
                {/* <div className="product-search">
                    <SearchInput />
                </div> */}
                <div className="accord-list">
                    <CustomAccordion
                        sections={filterSections}
                        handleCheckboxChange={handleCheckboxChange}
                        resetTrigger={resetTrigger} // Pass resetTrigger to CustomAccordion
                    />
                </div>
                <IconButton label="Filter result" iconClass="feather icon-filter me-2" onClick={handleFilter} />
                <div className="reset-filter cursor-pointer" onClick={resetFilters}>
                    Reset Filter
                </div>
            </form>
        </div>
    );
};

export default FilterSection;

