import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the shape of the slice state
interface RelocationTab {
  selectedTab: string;
}

// Initial state
const initialState: RelocationTab = {
  selectedTab: "Individual",
};

// Create the slice
const relocationTabSlice = createSlice({
  name: 'relocationTab',
  initialState,
  reducers: {
    toggleTab: (state, action: PayloadAction<string>) => {
      state.selectedTab = action.payload
    }
  },
});

// Export actions and reducer
export const { toggleTab } = relocationTabSlice.actions;
export default relocationTabSlice.reducer;