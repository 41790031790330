import React, { useState } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
// import InputField from "../../common/ui_components/InputFields/InputField";
import SocialLoginComponent from "../../common/ui_components/SocialLoginComponent";
import DropdownFilter from "../../common/ui_components/DropDown";
import BookingFormSection from "../../common/ui_components/BookingFormSection";
import IconInputField from "../../common/ui_components/InputFields/IconInputField";



const ownerType = [
  { name: "Individual", val: 'individual' },
  { name: "Enterprise", val: 'enterprise' },
];

const SignUpForm = ({ handleSignUp, activeState, isSubmitting }: any) => {

  const [selectedSortOption, setSelectedSortOption] = useState(ownerType[0].name);
  const [isToggle, setIsToggle] = useState(false);

  const SignUpSchema = Yup.object().shape({
    username: Yup.string()
      .required("Username is required")
      .test("no-email", "Username cannot be an email address", (value) => !/\S+@\S+\.\S+/.test(value)),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
    ...((activeState === "carOwner") && { ['subtype']: Yup.string().required("User type is required") })
  });

  const handleUserTypeChange = (value: any, setFieldValue: any) => {
    setSelectedSortOption(value);
    setFieldValue('subtype', value?.val);
  }

  const handleTrimOnBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    fieldName: string,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const trimmedValue = e.target.value.trim();
    // console.log(`Trimming field "${fieldName}":`, { original: e.target.value, trimmed: trimmedValue });
    setFieldValue(fieldName, trimmedValue); // Apply trimmed value to Formik state
  };

  return (
    <Formik
      initialValues={{
        username: "",
        email: "",
        password: "",
        activeState,
        subtype: '',
      }}
      validationSchema={SignUpSchema}
      onSubmit={handleSignUp}
    >
      {({ values, errors, setFieldValue }) => (
        <Form>
          {
            activeState === "carOwner" &&
            <BookingFormSection>
              <label className="form-label">
                Select User Type <span className="text-danger">*</span>
              </label>
              <div className="input-container">
                <DropdownFilter
                  value={selectedSortOption}
                  onChange={(value: any) => handleUserTypeChange(value, setFieldValue)}
                  options={ownerType}
                  placeholder="Select Sort Option"
                  className="signup-dropdown-filter"
                />
                <ErrorMessage name="subtype" component="div" className="text-danger font-short mt-2 fade-down" />
              </div>
            </BookingFormSection>
          }
          {/* <InputField
            label="Username"
            name="username"
            placeholder="Enter your username"
            value={values.username}
            onChange={handleChange}
            // onBlur={handleBlur}
            onBlur={(e: any) => handleTrimOnBlur(e, "username", setFieldValue)}
            required
          />
          <ErrorMessage name="username" component="div" className="text-danger font-short mt-2 fade-down" /> */}

          {/* username */}
          <div className="input-block">
            <IconInputField
              label="Username"
              placeholder="Enter your username"
              // iconClass="feather icon-user"
              value={values.username}
              onChange={(e) => setFieldValue("username", e.target.value)}
              required={true}
            />
            {errors.username && <div className="error text-danger font-short"> {errors.username} </div>}
          </div>

          {/* email */}
          <div className="input-block">
            <IconInputField
              label="Email"
              placeholder="Enter Email"
              // iconClass="feather icon-user"
              value={values.email}
              onChange={(e) => setFieldValue("email", e.target.value)}
              required={true}
            />
            {errors.email && <div className="error text-danger font-short"> {errors.email} </div>}
          </div>

          {/* password */}
          <div className="input-block">
            <label className="form-label">Password <span className="text-danger">*</span></label>
            <div className="pass-group">
              <Field type={isToggle ? "text" : "password"} name="password" onBlur={(e: any) => handleTrimOnBlur(e, 'password', setFieldValue)} className="form-control pass-input" style={{ paddingLeft: '15px' }} />
              <span className={`fas toggle-password ${isToggle ? "fa-eye" : "fa-eye-slash"}`} onClick={() => setIsToggle(!isToggle)} />
            </div>
            <ErrorMessage name="password" component="div" className="text-danger mt-2 font-short" />
          </div>

          {/* <InputField
            label="Email"
            type="email"
            name="email"
            placeholder="Enter your email"
            value={values.email}
            onChange={handleChange}
            // onBlur={handleBlur}
            onBlur={(e: any) => handleTrimOnBlur(e, "email", setFieldValue)}
            required
          />
          <ErrorMessage name="email" component="div" className="text-danger font-short mt-2 fade-down" />

          <InputField
            label="Password"
            type="password"
            name="password"
            placeholder="Enter your password"
            value={values.password}
            onChange={handleChange}
            // onBlur={handleBlur}
            onBlur={(e: any) => handleTrimOnBlur(e, "password", setFieldValue)}
            required
          />
          <ErrorMessage name="password" component="div" className="text-danger font-short mt-2 fade-down" /> */}

          <button
            type="submit"
            className="btn btn-primary w-100 btn-size"
            disabled={isSubmitting}
            onClick={() => setFieldValue("activeState", activeState)}
          >
            Sign Up
          </button>

          <SocialLoginComponent />
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
