import React, { useEffect, useState } from 'react';
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath';
import { useSelector } from 'react-redux';
import useHelperFuncs from '../../../utils/hooks/useHelperFuncs';



const CarSpecification = () => {
  const { getCarSpecs } = useHelperFuncs();
  const carDetails = useSelector((state: any) => state?.carReducer?.singleCarDetails);

  const [carSpecs, setCarSpecs] = useState<any>([]);

  useEffect(() => {
    if (Object.keys(carDetails).length > 0) {
      setCarSpecs(getCarSpecs(carDetails));
    }
  }, [carDetails])

  // console.log("carSpecs", carSpecs)
  return (
    <div className="review-sec specification-card">
      <div className="review-header">
        <h4>Specifications</h4>
      </div>
      <div className="card-body">
        <div className="lisiting-featues">
          <div className="row">
            {carSpecs?.map((spec: any) => (
              <div
                key={spec.id}
                className="featureslist d-flex align-items-center col-xl-3 col-md-4 col-sm-6"
              >
                <div className="feature-img">
                  <ImageWithBasePath src={spec.imgSrc} alt="Icon" />
                </div>
                <div className="featues-info">
                  <span>{spec.label}</span>
                  <h6>{spec.value}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarSpecification;
