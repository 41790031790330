import React, { useEffect } from "react";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import BookingCard from "../../common/ui_components/cards/BookingCard";
import Breadcrumbs from "../../common/ui_components/breadcrumbs";
import CircularIndeterminate from "../../common/ui_components/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { all_routes } from "../../router/all_routes";
import { fetchCarById } from "../../../redux/reducers/carReducer";
import { format } from 'date-fns';
import Button from "../../common/ui_components/buttons/blueBtn";
import SuccessMessage from "../../common/ui_components/SuccessMessage";
import { bookCar } from "../../../redux/reducers/bookingReducer";
import dayjs from "dayjs";
// import useHelperFuncs from "../../../utils/hooks/useHelperFuncs";



const CarBooking = ({ type }: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const { printSection } = useHelperFuncs();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const { bookingFormValues, headerLocationData } = location.state || {}; // Destructure the passed state
    const routes = all_routes;

    const UserDetails = useSelector((state: any) => state?.authReducer?.login_data);
    const carDetails = useSelector((state: any) => state?.carReducer?.singleCarDetails);
    const recentBooking = useSelector((state: any) => state?.bookingReducer?.recentBooking);

    const handleBookingSubmit = () => {
        // console.log('handleBookingSubmit Hit', UserDetails?._id);
        const body = {
            carId: carDetails?._id,
            noOfPersons: bookingFormValues?.noOfPersons,
            pickupDate: dayjs(bookingFormValues?.dateRange?.selection?.startDate).format('YYYY-MM-DD'),
            dropoffDate: dayjs(bookingFormValues?.dateRange?.selection?.endDate).format('YYYY-MM-DD'),
        }
        dispatch(bookCar(body));
        navigate(routes.RentBookingSuccess, { state: { headerLocationData: headerLocationData } });
    }

    useEffect(() => {
        // console.log('headerLocationData', headerLocationData);
        if (searchParams?.size > 0) {
            const carId = searchParams.get("carId");
            dispatch(fetchCarById(carId));
        }
    }, [searchParams])

    const isObjectEmpty = (obj: any) => Object.keys(obj || {}).length === 0;

    if (!UserDetails || isObjectEmpty(carDetails)) {
        // Show spinner while loading data or if carDetails is an empty object
        return <div className='spinner_container' style={{ height: '40rem' }}><CircularIndeterminate /></div>;
    }

    return (
        <>
            <Breadcrumbs maintitle={carDetails?.modelName} title='Car Details' subtitle="Booking" searchdata={headerLocationData} />

            <div className="container">
                <div className="booking-card" id="printable-section">
                    {type === 'success' && <SuccessMessage status={carDetails?.paymentStatus} orderNumber={recentBooking?.bookingtempId} />}

                    <div className="booking-header">
                        <div className="booking-img-wrap">
                            <div className="book-img">
                                <ImageWithBasePath src="assets/img/blank_car.png" alt="img" />
                            </div>
                            <div className="book-info">
                                <h6>{carDetails?.modelName}</h6>
                                <p>{carDetails?.carCategory?.name}</p>
                                <p>Car Registration No: {carDetails?.registration}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {/* <div className="col-lg-6 col-md-6 d-flex">
                                <BookingCard title="Car Pricing">
                                    <PricingList
                                        items={bookingData.pricingItems}
                                        total={bookingData.pricingTotal}
                                    />
                                </BookingCard>
                            </div> */}

                        {/* Billing Information Section */}
                        <div className="col-lg-6 col-md-6 d-flex">
                            <BookingCard title="Billing Information">
                                <ul className="billing-lists">
                                    <div>{`${UserDetails?.first_name} ${UserDetails?.last_name}`}</div>
                                    <div>No of persons {bookingFormValues?.noOfPersons}</div>
                                    <div>{UserDetails?.address?.street}</div>
                                    <div>{UserDetails?.phone?.code} {UserDetails?.phone?.number}</div>
                                    <div>{UserDetails?.email}</div>
                                </ul>
                            </BookingCard>
                        </div>

                        {/* Location & Date Section */}
                        <div className="col-lg-6 col-md-6 d-flex">
                            <BookingCard title="Location & Date">
                                <div style={{ marginTop: '10px' }}>
                                    <h6>Pickup</h6>
                                    <p className="billing-lists" style={{ marginTop: '8px' }}>
                                        <i className="feather icon-map-pin" /> Location: {carDetails?.pickupLocation?.street}
                                    </p>
                                    <p className="billing-lists" style={{ marginTop: '8px' }}>Date: {carDetails?.pickupDate && format(new Date(carDetails?.pickupDate), "dd MMM yyyy")}</p>

                                </div>
                                <div style={{ marginTop: '20px' }}>
                                    <h6>Dropoff</h6>
                                    <p className="billing-lists" style={{ marginTop: '8px' }}>
                                        <i className="feather icon-map-pin" /> Location: {carDetails?.dropoffLocation?.street}
                                        <p className="billing-lists" style={{ marginTop: '4px' }}>Date: {carDetails?.dropoffDate && format(new Date(carDetails?.dropoffDate), "dd MMM yyyy")}</p>
                                    </p>
                                </div>
                                {/* <InfoList items={carDetails} /> */}
                            </BookingCard>
                        </div>

                        {/* Additional Information Section */}
                        {type === 'success' && UserDetails?.additionalInfo &&
                            <div className="col-lg-12">
                                <BookingCard title="Additional Information" className="mb-0">
                                    <p>
                                        {/* {bookingData.additionalInfo} */}
                                        {UserDetails?.additionalInfo}
                                    </p>
                                </BookingCard>
                            </div>
                        }

                        {/* <div className="col-lg-6 col-md-6 d-flex">
                                <BookingCard title="Payment Details">
                                    <InfoList items={bookingData.paymentDetailsItems} />
                                </BookingCard>
                            </div> */}


                    </div>
                </div>


                {/* <-------------------- Page Buttons Section --------------------> */}
                {type === 'confirmation' &&
                    <div className="booking-info-btns d-flex flex-row-reverse gap-y-1" style={{ margin: '20px 0' }} >
                        <Button
                            onClick={handleBookingSubmit}
                            label="Confirm"
                            className="btn btn-primary continue-book-btn"
                        />
                        <Link to={`${routes.CarDetails}?car_id=${carDetails?._id}`} className="btn btn-secondary" >
                            Back
                        </Link>
                    </div>
                }

                {type === 'success' &&
                    <div className="print-btn text-center" style={{ margin: '20px 0' }} >
                        {/* <Link to={`${routes.bookingInvoice}?car_id=${carDetails._id}`} className="btn btn-secondary" >
                                Print Order
                            </Link> */}

                        {/* <button className="btn btn-secondary" onClick={() => printSection('printable-section')}>Print Order</button> */}
                    </div>
                }
            </div>
        </>
    );
};

export default CarBooking