
export const all_routes = {
  /* -----Main landing/common routes ----------------- */
  MainLanding: "/",
  contactUs: "/contact-us",


  /* ---------------auth routes ---------------------*/
  register: "/authentication/register",
  login: "/authentication/login",
  forgotPassword: "/authentication/forgot-password",
  resetPassword: "/authentication/reset-password",
  OtpVerification: "/authentication/Otp-verification",



  /* ---------------flexi-driver routes ---------------------*/
  CarBooking: "/rent",
  CarList: "/rent/list",
  CarDetails: "/rent/details",
  bookingCheckout: "/rent/booking",
  invoiceDetails: "/rent/booking/invoice",
  BookingConformation: "/rent/conformation",
  RentBookingSuccess: "/rent/success",


  /* ---------------car-owner routes ---------------------*/
  Relocation: "/relocate",
  relocateCar: "/relocate/relocatecar",
  invoice: "/relocate/payment/invoice",


  /* ---------------Professinal-driver routes ---------------------*/
  ProfessionalDriver: "/professional-driver",


  /* ---------------dashboard routes ---------------------*/
  // userDashboard: "/user/user-dashboard",
  userBookings: "/user/user-bookings",
  // userPayment: "/user/user-payment",
  // userSettings: "/user/user-settings",


  /* <--------------------------------------------- Users route section --------------------------------------------->*/
  // <---------- Users common route section ---------->
  userDashboard: "/user/dashboard", // "user-dashboard",
  userSettings: "/user/settings", // user-settings,

  // <---------------- Car owners route ---------------->
  userPayment: "/user/payment", // user-payment,
  userRelocation: "/user/relocations",
  userQuote: "/user/my-quotes", // user-payment,

  // <-------------- flexy-drivers route -------------->
  driverBookings: "/user/bookings",
  bookingInvoice: "/user/booking/invoice",

  /* ---------------Professinal-driver routes ---------------------*/
  // ProfessionalDriver: "/professional-driver",




  /* -------------------not used path -------------------*/
  homeTwo: "/index-2",
  homeThree: "/index-3",
  aboutUs: "/aboutUs",
  pricing: "/pages/pricing",
  faq: "/pages/faq",
  gallery: "/pages/gallery",
  integration: "/integration",
  // invoice: "/pages/invoice-details",
  ourTeam: "/pages/our-team",
  testimonial: "/pages/testimonial",
  termsConditions: "/pages/terms-condition",
  privacyPolicy: "/pages/privacy-policy",
  maintenance: "/pages/maintenance",
  comingSoon: "/pages/coming-soon",
  error404: "/pages/error-404",
  error500: "/pages/error-500",
  bookingDetail: "/pagesbooking-detail",
  booking: "/pages/booking",
  bookingAddon: "/pages/booking-addon",
  bookingPayment: "/pages/booking-payment",
  bookingSuccess: "/pages/booking-success",
  userMessages: "/user/user-messages",
  userReviews: "/user/user-reviews",
  userWishlist: "/user/user-wishlist",
  userSecurity: "/user/user-security",
  preference: "/user/user-preferences",
  notification: "/user/user-notifications",
  userIntegration: "/user/user-integration",
  userWallet: "/user/user-wallet",
  blogList: "/blog/blog-list",
  blogGrid: "/blog/blog-grid",
  blogDetails: "/blog/blog-details",
  listingGrid: "/rent/listing-grid",
  listingMap: "/rent/listing-map",
  // userBookingCancelled: "/user/user-booking-cancelled",
  // userBookingUpcoming: "/user/user-booking-upcoming",
  // BookingCompleteCalendar: "/user/booking-complete-calendar",
  // BookingCancelledCalendar: "/user/booking-cancelled-calendar",
  // BookingInprogressCalendar: "/user/booking-inprogress-calendar",
  // BookingUpcomingCalendar: "/user/booking-upcoming-calendar",
  // BookingCalendar: "/user/bookings-calendar",
  // bookingCalendar: "/pages/booking-calendar",
  // userBookingComplete: "/user/user-booking-complete",
  // userBookingInprogress: "/user/user-booking-inprogress",
};
