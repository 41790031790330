import React from "react";
// import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import useHelperFuncs from "../../../utils/hooks/useHelperFuncs";

const BookingAvatar = ({img, carName, deliveryStatus }: any) => {
  const { toTitleCase } = useHelperFuncs();

  return (
    <div className="table-avatar">
      <div className={`avatar avatar-lg flex-shrink-0`}>
        <ImageWithBasePath className="avatar-img" src={img} srcType='new' alt="Image" />
      </div>
      <div className="table-head-name flex-grow-1">
        <div style={{fontWeight:'600', color:'black'}}>{toTitleCase(carName)}</div>
        <p>{toTitleCase(deliveryStatus)}</p>
      </div>
    </div>
  );
};

export default BookingAvatar;
