import React from 'react'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";



const ListingTable = ({ filteredData, columnConfig }: any) => {
  return (
    <div className="card-body">
      <div className="table-responsive dashboard-table">
        <DataTable
          className="table datatable"
          value={filteredData}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          currentPageReportTemplate="{first}"
        >
          {columnConfig.map((col: any, index: number) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              body={col.body}
            />
          ))}
        </DataTable>
      </div>
      {/* <div className="table-footer">
        <div className="row">
          <div className="col-md-6">
            <div id="tablelength" />
          </div>
          <div className="col-md-6 text-md-end">
            <div id="tablepage" />
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default ListingTable