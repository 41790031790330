import React from 'react';
import { useNavigate } from "react-router-dom";
import ImageWithBasePath from '../../../../core/data/img/ImageWithBasePath';
import IconButton from '../buttons/IconButton';
import ListingLocation from '../ListingLocation';
import { all_routes } from '../../../router/all_routes';
import ListingDetailsGroup from '../ListingDetailsGroup';
import ConfirmationModal from '../../../../core/data/modals/confirmationModal';
import { useSelector } from 'react-redux';

const ListViewCard = ({ car, searchdata = null }: any) => {
    const navigate = useNavigate();
    const routes = all_routes;
    const login_data = useSelector((state: any) => state?.authReducer?.login_data);
    const [showModal, setShowModal] = React.useState<boolean>(false);

    const handleClick = () => {
        if (login_data.isVerified === 1) {
            navigate(`${routes.CarDetails}?carId=${car?._id}`, { state: { searchdata } });
            return
        } else {
            setShowModal(true)
        }
    };

    return (
        <div className="listview-car">
            <div className="card shadow_class">
                <div className="blog-widget d-flex">
                    <div className="blog-img">
                        <ImageWithBasePath
                            srcType='new'
                            src={car?.images?.[0]?.key ? `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${car?.images?.[0]?.key}&width=${200}` : "assets/img/cars/car2.png"}
                            className="img-fluid"
                            alt="blog-img"
                        />
                    </div>
                    <div className="bloglist-content w-100">
                        <div className="card-body">

                            <div className="blog-list-head d-flex">
                                <div className="blog-list-title">
                                    <h3 className='cursor-pointer text-black' onClick={handleClick}>{car?.modelName}</h3>
                                    <h6>Category : <span>{car?.carCategory?.name}</span></h6>
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <ListingLocation color='#000' searchdata={{
                                    pickupLocation: `${car?.pickupLocation?.street}`,
                                    dropoffLocation: `${car?.dropoffLocation?.street}`,
                                    pickupDate: `${car?.pickupDate}`,
                                    dropoffDate: `${car?.dropoffDate}`
                                }} />
                                {/* <PriceInfo /> */}
                            </div>
                            <div className="listing-details-group">
                                <ListingDetailsGroup car={car} />
                                <IconButton onClick={handleClick} label='Rent Now' iconClass="feather icon-calendar me-2" />
                            </div>
                            {showModal && <ConfirmationModal show={showModal} setShow={setShowModal} message="You cannot rent a car because your account has not been verified by the admin yet." />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListViewCard;
