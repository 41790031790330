import React, { useEffect, useState } from 'react';
import ProfileBasicSection from './ProfileBasicSection';
import ProfileAddressSection from './ProfileAddressSection';
import Button from '../../../common/ui_components/buttons/blueBtn';
import { useSelector, useDispatch } from 'react-redux';
import { } from "react-redux";
import { set_login_data } from '../../../../redux/reducers/authReducer';
import { setCookie } from '../../../../utils/cookieUtils';
import ConfirmationModal from '../UserSectionModals/ConfirmationModal';
import SuccessMessage from '../UserSectionModals/SuccessMessage';
import { clearUserDetails } from '../../../../redux/reducers/userReducer';
import { useUserSettingsHook } from '../../../../hooks/useUserSettingsHook';



const ProfileSection = () => {
    const dispatch = useDispatch();
    const login_data = useSelector((state: any) => state?.authReducer?.login_data);
    const userDetails = useSelector((state: any) => state?.userReducer?.userDetails);

    const [phoneData, setPhoneData] = useState({ code: '', number: '' });
    const [profileImage, setProfileImage] = useState<string>('/assets/img/bg/blank_profile.jpg');

    const { formik, handleVerifyClick, handleOkClick, handleCancel, verificationModal, successMessage, setDeletedProfileImageKey } = useUserSettingsHook();

    useEffect(() => {
        (async () => {
            // const licenseUrl = await fetchMediaData(login_data?.document[0]?.key) || null;
            login_data?.profilePicture && setProfileImage(login_data?.profilePicture);
            login_data?.profilePicture && setProfileImage(`${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${login_data?.profilePicture?.key}&width=${100}`);
            formik.setValues({
                profilePicture: login_data?.profilePicture || null,
                firstName: login_data?.first_name || "",
                lastName: login_data?.last_name || "",
                userName: login_data?.user_name || "",
                email: login_data?.email || "",
                phonecode: login_data?.phone?.code || '',
                phoneNumber: login_data?.phone?.number || '',
                phoneNumberWithCode: login_data?.phoneNo || "",
                ...((login_data?.role === 'flexiDriver' || login_data?.role === 'professionalDriver') && { ['licenseField']: login_data?.licenseField || '' }),
                license:login_data?.license  || null, // licenseUrl || null,
                address: login_data?.address?.street || "",
                city: login_data?.address?.city || "",
                state: login_data?.address?.state || "",
                country: login_data?.address?.country || "",
                pincode: login_data?.address?.pincode || "",
                // document: login_data?.document || null,
            });
        })();
    }, [login_data]);


    useEffect(() => {
        if (userDetails && Object.keys(userDetails)?.length > 0) {
            dispatch(set_login_data(userDetails));
            setCookie('userdata', userDetails, 7);
        }
    }, [userDetails])

    useEffect(() => {
        return () => {
            dispatch(clearUserDetails());
        }
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <ProfileBasicSection formik={formik} phoneData={phoneData} setPhoneData={setPhoneData} profileImage={profileImage} setProfileImage={setProfileImage} setDeletedProfileImageKey={setDeletedProfileImageKey} />
                <ProfileAddressSection formik={formik} />
                <div className="profile-submit-btn">
                    <Button label="Save" className="btn btn-primary" type="submit" />
                </div>
            </form>
            {verificationModal && <ConfirmationModal handleCancel={handleCancel} handleVerifyClick={handleVerifyClick} />}
            {successMessage && <SuccessMessage handleOkClick={handleOkClick} />}
        </>
    );
};

export default ProfileSection;
