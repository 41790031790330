import { configureStore } from '@reduxjs/toolkit'
import authReducer from './reducers/authReducer';
import userReducer from './reducers/userReducer';
import mobileViewRedurer from './reducers/mobileViewRedurer';
import carReducer from './reducers/carReducer';
import transactionReducer from './reducers/transactionReducer';
import bookingReducer from './reducers/bookingReducer';
import relocationTabReducer from "./reducers/relocationTabReducer"
import quoteReducer from './reducers/quoteReducer';
import userSettingsTabSlice from './reducers/userSettingsTabSlice';
import notificationReducer from './reducers/notificationReducer'

const store = configureStore({
    reducer: {
        authReducer,
        userReducer,
        mobileViewRedurer,
        carReducer,
        transactionReducer,
        quoteReducer,
        bookingReducer,
        relocationTabReducer,
        userSettingsTabSlice,
        notificationReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export default store;