import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../common/ui_components/breadcrumbs";
import SearchSection from "../LandingPageForBoking/SearchSection";
import TabListSection from "./TabListSection";
import FilterSection from "./FilterSection";
import ListView from "./ListView";
import GridView from "./GridView";
import { useDispatch, useSelector } from "react-redux";
import { fetchCars } from "../../../redux/reducers/carReducer";
import { useLocation } from "react-router-dom";
import CircularIndeterminate from "../../common/ui_components/CircularProgress";
import dayjs from 'dayjs';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';


const Listinglist = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { searchdata } = location.state || {};
    const [first, setFirst] = useState<number>(0);
    const [rows, setRows] = useState<number>(25);
    const [page, setPage] = useState<number>(1);    
    const [paginationQuery, setPaginationQuery] = useState<string>("");

    const { loading, carList, totalCars } = useSelector((state: any) => state?.carReducer);
    const [currentView, setCurrentView] = useState("list");
    const [filterQuery, setFilterQuery] = useState('')

    // Fetching car list
    useEffect(() => {
        if (searchdata) {
            // console.log('searchdata in car listing page', searchdata);
            const formattedPickupDate = searchdata?.pickupDate ? dayjs(searchdata?.pickupDate).format('YYYY-MM-DD') : "";
            const formattedDropoffDate = searchdata?.dropoffDate ? dayjs(searchdata?.dropoffDate).format('YYYY-MM-DD') : "";
            const queryParams = [
                `pickUpLatitude=${searchdata?.pickupLat}`,
                `pickUpLongitude=${searchdata?.pickupLng}`,
                `maxDistance=${100}`,
                searchdata?.dropoffLat ? `dropOffLatitude=${searchdata?.dropoffLat}` : "",
                searchdata?.dropoffLng ? `dropOffLongitude=${searchdata?.dropoffLng}` : "",
                formattedPickupDate ? `pickupDate=${formattedPickupDate}` : "",
                formattedDropoffDate ? `dropoffDate=${formattedDropoffDate}` : ""
            ].filter(Boolean).join("&");
            dispatch(fetchCars({ endpoint: `cars/nearby?${queryParams}${filterQuery}${paginationQuery}` }));
        }

    }, [searchdata, paginationQuery, filterQuery]);

    useEffect(() => {
        setPaginationQuery(`&page=${page}&dataPerPage=${rows}`);
     }, [first, rows]); 

    const onPageChange = (event: PaginatorPageChangeEvent) => {
        setFirst(event.first);
        setRows(event.rows);
        setPage(event.first / event.rows + 1);      
    };

    return (
        <div className="listing-page">
            <Breadcrumbs title="Car Listings" subtitle="Listings" maintitle={""} searchdata={searchdata} />
            <div className="section-search page-search">
                <SearchSection searchdata={searchdata} />
            </div>
            <TabListSection setCurrentView={setCurrentView} currentView={currentView} />
            <section className="section car-listing pt-0">
                <div className="card_container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-sm-12 col-12 theiaStickySidebar">
                            <FilterSection setFilterQuery={setFilterQuery}/>
                        </div>
                        <div className="col-xl-9 col-lg-8 col-sm-12 col-12">
                            {loading === "pending" ? (
                                <div className="list-loader"><CircularIndeterminate /></div>
                            ) : loading === "succeeded" ? (
                                carList.length > 0 ? <>
                                    {currentView === "list" && <ListView carList={carList} searchdata={searchdata} />}
                                    {currentView === "grid" && <GridView carList={carList} searchdata={searchdata}/>}
                                    {/* {currentView === "map" && (
                                        <MapContainer
                                            center={{ lat: 53.470692, lng: -2.220328 }}
                                            places={data}
                                        />
                                    )} */}
                                    <Paginator first={first} rows={rows} totalRecords={totalCars} rowsPerPageOptions={[10, 25, 50]} onPageChange={(e: PaginatorPageChangeEvent) => onPageChange(e)}></Paginator>
                                </> : <div className="no_data">No Cars Found !!</div>

                            ) : (
                                <div>No cars found. Please search for relocation !!</div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Listinglist;
