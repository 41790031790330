import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from 'yup'
import { doPutApiCall } from "../utils/apiconfig";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";


const useChangePassword = () => {

    const [isLoading, setIsLoading] = useState(false)
    const UserDetails = useSelector((state: any) => state?.authReducer?.login_data);

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required('Old Password is required'),
            newPassword: Yup.string()
                .required('New Password is required')
                .min(8, 'New Password must be at least 8 characters'),
            confirmPassword: Yup.string()
                .required('Confirm Password is required')
                .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
        }),
        onSubmit: (values, { resetForm }) => {
            handleChange(values, resetForm)
        },
    });

    async function handleChange(values: any, resetForm?: () => void) {
        setIsLoading(true);
        const data = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/users/change/password/${UserDetails?._id}`,
            bodyData: {
                oldPassword: values.oldPassword,
                newPassword: values.newPassword
            },
        };

        try {
            const res: any = await doPutApiCall(data);
            if (res?.error) {
                switch (res.code) {
                    case 'PASSWORD_SAME':
                        toast.error('Use new password.');
                        break;
                    case 'PASSWORD_INCORRECT':
                        toast.warning('Enter correct old password.');
                        break;
                    default:
                        toast.error('An unexpected error occurred.');
                        break;
                }
            } else {
                switch (res.code) {
                    case 'PASSWORD_CHANGED_SUCCESS':
                        toast.success("Password change successfully.");
                        resetForm?.();
                        break;
                    default:
                        toast.success('Operation completed successfully.');
                        resetForm?.();
                        break;
                }
                const modalElement = document.getElementById("change_password");
                if (modalElement) {
                    // Hide the modal
                    modalElement.classList.remove("show"); // Remove the `show` class
                    modalElement.setAttribute("aria-hidden", "true"); // Mark it as hidden
                    modalElement.setAttribute("aria-modal", "false"); // Reset modal attribute
                    modalElement.style.display = "none"; // Hide modal from view
                
                    // Remove backdrop as a fallback
                    const backdrop = document.querySelector(".modal-backdrop");
                    if (backdrop) {
                        backdrop.parentNode?.removeChild(backdrop);
                    }
                }
                
            }
        } catch (err: any) {
            console.error('Error occurred:', err);
            toast.error('Something went wrong!');
        } finally {
            setIsLoading(false);
        }
    }

    return { formik, isLoading }
}

export default useChangePassword