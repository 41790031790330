import React, { useEffect } from "react";
import Breadcrumbs from "../common/ui_components/breadcrumbs";
import AOS from "aos";
import "aos/dist/aos.css";
// import { useSelector } from "react-redux";
// import { ContactUs } from "../../core/data/interface/interface";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
// import { Link } from "react-router-dom";
import Header from "../common/Header/header";
// import { Field } from "formik";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import InputField from "../common/ui_components/InputFields/InputField";
import TextAreaField from "../common/ui_components/TextAreaField";
import IconInputField from "../common/ui_components/InputFields/IconInputField";
import useContactUsHooks from "../../hooks/useContactUsHooks";
import Button from "../common/ui_components/buttons/blueBtn";

const Contact = () => {
    const { isLoading, formik } = useContactUsHooks();

    useEffect(() => {
        AOS.init({ duration: 1200, once: true });
    }, []);

    return (
        <div className="main-wrapper">
            <Header />
            <Breadcrumbs title="Contact us" subtitle="contact us" maintitle={""} />
            <section className="contact-section">
                <div className="card_container">
                    <div
                        className="form-info-area"
                        data-aos="fade-down"
                        data-aos-duration={1200}
                        data-aos-delay="0.5"
                    >
                        <div className="row">
                            <div className="col-lg-6 d-flex">
                                <ImageWithBasePath
                                    src="assets/img/bg/relocation_banner2.png"
                                    className="img-fluid contact_image"
                                    alt="Contact"
                                />
                            </div>
                            <div className="col-lg-6">
                                <form onSubmit={formik?.handleSubmit}>
                                    <h1>Get in touch!</h1>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <IconInputField
                                                label="Name"
                                                placeholder="Enter your name"
                                                value={formik?.values.name}
                                                onChange={formik?.handleChange}
                                                id='name'
                                            />
                                            {formik.touched.name && formik.errors.name && <div className="error text-danger font-short"> {formik.errors.name} </div>}
                                        </div>
                                        <div className="col-md-12">
                                            <IconInputField
                                                label="Email"
                                                placeholder="Enter your email"
                                                id='email'
                                                value={formik?.values.email}
                                                onChange={formik?.handleChange}
                                                required
                                            />
                                            {formik.touched.email && formik.errors.email && <div className="error text-danger font-short"> {formik.errors.email} </div>}
                                        </div>
                                        <div className="col-md-12">
                                            <div className="input-block">
                                                <TextAreaField
                                                    label=" Description"
                                                    placeholder="Enter your message"
                                                    id='issue'
                                                    rows={8}
                                                    value={formik?.values.issue}
                                                    onChange={formik?.handleChange}
                                                    required={true}
                                                />
                                                {formik.touched.issue && formik.errors.issue && <div className="error text-danger font-short"> {formik.errors.issue} </div>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <button className="btn contact-btn">{isLoading}</button> */}
                                    <Button
                                        label="Send Enquiry"
                                        className="btn contact-btn"
                                        isLoading={isLoading}
                                        disabled={isLoading}
                                    />
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Contact;
