import React from 'react'
// import { carsData } from '../../../core/data/json/common_static_data';
import GridCard from '../../common/ui_components/cards/GridViewCard';

const GridView = ({carList, searchdata = null }:any) => {

  return (
    <div className="row">
      {carList.map((car:any, index:any) => (
        <GridCard key={index} car={car} className="col-xxl-6 col-lg-6 col-md-6 col-12" searchdata={searchdata}/>
      ))}
    </div>
  )
}

export default GridView