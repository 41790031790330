import React from "react";
import { Navigate } from "react-router";
import { all_routes } from "./all_routes";
import SignUp from "../authentication/signup";
import Login from "../authentication/login";
import ForgotPassword from "../authentication/forgotpassword";
import ResetPassword from "../authentication/resetpassword";
import Listingslist from "../components/ListingPage/CarListingPage";
import BookingCheckout from "../components/BookingPage/MainBookingPage";
import Booking from "../booking/booking";
import ListingDetails from "../components/CarDetailsPage/listingDetailsPage";
import TermsCondition from "../pages/termscondition/termscondition";
import Maintenance from "../pages/maintenance/maintenance";
import Error404 from "../pages/errorpages/error404";
import Error500 from "../pages/errorpages/error500";
import Contact from "../contact/contact";
import UserSettings from "../components/UserSection/UserSettings/UserSettings";
import UserDashboard from "../components/UserSection/UserDashboard/UserDashboard";
import UserBookings from "../components/UserSection/UserBooking/MainUserbookingsPage";
import UserPayment from "../components/UserSection/UserPayments/Userpayment";
import ComingSoon from "../pages/comingsoon/comingsoon";
import PrivacyPolicy from "../pages/privacypolicy/privacypolicy";
import AboutUs from "../components/AboutUs/aboutus";
import InvoiceDetails from "../components/InvoicePage/MainInvoicePage";
import MainLanding from "../components/MainLandingSection/MainLandingPage";
import { RelocationLandingPage } from "../components/LandingPageForRelocation/RelocationLandingPage";
import { BookingLandingPage } from "../components/LandingPageForBoking/BookingLandingPage";
import { DriverLandingPage } from "../components/LandingPageForDriver/DriverLandingPage";
import RelocateCar from "../user/RelocateCar";
import OtpVerification from "../authentication/OtpVerification";
import BookingSuccess from "../components/BookingPage/BookingConfirmation";
import BookingAddon from "../booking/booking-addon";
import BookingDetail from "../booking/booking-detail";
import BookingPayment from "../components/BookingPage/BookingPayment";
import CarBooking from "../pages/carbooking/CarBooking";
import BookingPage from "../components/UserSection/BookingModule/BookingPage";
import UserQuotes from "../components/UserSection/UserQuote/UserQuotes";


const routes = all_routes;

export const LandingRoutes = [
  {
    path: routes.MainLanding,
    element: <MainLanding />,
    openForAll: false,
  }
];

export const SubLandingRoutes = [
  {
    path: routes.CarBooking,
    element: <BookingLandingPage />,
    openForAll: true,
  },
  {
    path: routes.Relocation,
    element: <RelocationLandingPage />,
    openForAll: true,
  },
  {
    path: routes.ProfessionalDriver,
    element: <DriverLandingPage />,
    openForAll: true,
  },
];

export const FlexiDriverRoutes = [
  {
    path: routes.CarList,
    element: <Listingslist />,
  },
  {
    path: routes.CarDetails,
    element: <ListingDetails />,
  },
  {
    path: routes.bookingCheckout,
    element: <BookingCheckout />,
  },
  {
    path: routes.invoiceDetails,
    element: <InvoiceDetails />,
  },
  {
    path: routes.BookingConformation,
    element: <CarBooking type='confirmation' />,
  },
  {
    path: routes.RentBookingSuccess,
    element: <CarBooking type='success' />,
  },
];

export const carOwnerRoutes = [
  {
    path: routes.relocateCar,
    element: <RelocateCar />,
  },
  {
    path: routes.invoice,
    element: <InvoiceDetails />,
  }
];

export const publicRoutes = [
  // {
  //   path: routes.homeTwo,
  //   element: <HomeTwo />,
  // },
  // {
  //   path: routes.homeThree,
  //   element: <HomeThree />,
  // },
  {
    path: "/",
    element: <Navigate to="/" />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },

  {
    path: routes.contactUs,
    element: <Contact />,
  },
  {
    path: routes.aboutUs,
    element: <AboutUs />,
  },
];

export const pageroutes = [
  {
    path: routes.booking,
    element: <Booking />,
  },
  {
    path: routes.termsConditions,
    element: <TermsCondition />,
  },
  {
    path: routes.privacyPolicy,
    element: <PrivacyPolicy />,
  },
  // {
  //   path: routes.ourTeam,
  //   element: <OurTeam />,
  // },
  // {
  //   path: routes.testimonial,
  //   element: <Testimonials />,
  // },
  // {
  //   path: routes.pricing,
  //   element: <Pricing />,
  // },
  // {
  //   path: routes.faq,
  //   element: <Faq />,
  // },
  // {
  //   path: routes.gallery,
  //   element: <Gallerys />,
  // },
  {
    path: routes.bookingDetail,
    element: <BookingDetail />,
  },
  {
    path: routes.bookingAddon,
    element: <BookingAddon />,
  },
  // {
  //   path: routes.bookingCheckout,
  //   element: <BookingCheckout />,
  // },
  {
    path: routes.bookingPayment,
    element: <BookingPayment />,
  },
  {
    path: routes.bookingSuccess,
    element: <BookingSuccess />,
  },
];

export const authenticationRoute = [
  {
    path: routes.register,
    element: <SignUp />,
  },
  {
    path: routes.login,
    element: <Login />,
  },
  {
    path: routes.forgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: routes.OtpVerification,
    element: <OtpVerification />,
  },
  {
    path: routes.resetPassword,
    element: <ResetPassword />,
  },
  {
    path: routes.error404,
    element: <Error404 />,
  },
  {
    path: routes.error500,
    element: <Error500 />,
  },
  {
    path: routes.maintenance,
    element: <Maintenance />,
  },
  {
    path: routes.comingSoon,
    element: <ComingSoon />,
  },
];

export const usermodule = [
  {
    path: routes.userDashboard,
    element: <UserDashboard />,
  },
  {
    path: routes.userSettings,
    element: <UserSettings />,
  },
  // {
  //   path: routes.userBookings,
  //   element: <UserBookings title="User Relocations" subtitle="User Relocations" />,
  //   // element: <UserBookings title="User Bookings" subtitle="User Bookings" />,
  // },
  {
    path: routes.userRelocation,
    element: <UserBookings title="User Relocations" subtitle="User Relocations" />,
  },
  {
    path: routes.userPayment,
    element: <UserPayment title="Payments" subtitle="Payments" />,
  },
  {
    path: routes.userQuote,
    element: <UserQuotes title="Quotes" subtitle="Quotes" />,
  },
  {
    path: routes.bookingInvoice,
    element: <InvoiceDetails />,
  },
  {
    path: routes.driverBookings,
    element: <BookingPage />,
  },
  // {
  //   path: routes.userIntegration,
  //   element: <UserIntegration />,
  // },
  // {
  //   path: routes.userSecurity,
  //   element: <UserSecurity />,
  // },
  // {
  //   path: routes.preference,
  //   element: <UserPreferences />,
  // },
  // {
  //   path: routes.notification,
  //   element: <UserNotification />,
  // },
  // {
  //   path: routes.userWallet,
  //   element: <UserWallet />,
  // },
  // {
  //   path: routes.userWishlist,
  //   element: <UserWishList />,
  // },
  // {
  //   path: routes.userMessages,
  //   element: <UserMessages />,
  // },
  // {
  //   path: routes.userReviews,
  //   element: <UserReview />,
  // },
  // {
  //   path: routes.userBookingCancelled,
  //   element: <UserBookingCancelled />,
  // },
  // {
  //   path: routes.userBookingCancelled,
  //   element: <UserBookingCancelled />,
  // },
  // {
  //   path: routes.userBookingUpcoming,
  //   element: <UserBookingUpcoming />,
  // },
  // {
  //   path: routes.userBookingComplete,
  //   element: <UserBookingComplete />,
  // },
  // {
  //   path: routes.userBookingCancelled,
  //   element: <UserBookingCancelled />,
  // },
  // {
  //   path: routes.userBookingInprogress,
  //   element: <UserBookingInprogress />,
  // },
  // {
  //   path: routes.BookingCalendar,
  //   element: <BookingCalendar />,
  // },
  // {
  //   path: routes.BookingCompleteCalendar,
  //   element: <BookingCompleteCalendar />,
  // },
  // {
  //   path: routes.BookingCancelledCalendar,
  //   element: <BookingCancelledCalendar />,
  // },
  // {
  //   path: routes.BookingInprogressCalendar,
  //   element: <BookingInprogressCalendar />,
  // },
  // {
  //   path: routes.BookingUpcomingCalendar,
  //   element: <BookingUpcomingCalendar />,
  // },
  // {
  //   path: routes.bookingCalendar,
  //   element: <BookingCalendar />,
  // },
];


// export const blogroutes = [
// {
//   path: routes.blogList,
//   element: <BlogList />,
// },
// {
//   path: routes.blogGrid,
//   element: <BlogGrid />,
// },
// {
//   path: routes.blogDetails,
//   element: <BlogDetails />,
// },
// ];

// export const listingroutes = [
//   {
//     path: routes.CarList,
//     element: <Listingslist />,
//   },
//   {
//     path: routes.CarDetails,
//     element: <ListingDetails />,
//   },
//   {
//     path: routes.listingGrid,
//     element: <ListingGrid />,
//   },
//   {
//     path: routes.listingMap,
//     element: <ListingMap />,
//   },
// ];

// export const BookingRoutes = [
//   {
//     path: routes.bookingCheckout,
//     element: <BookingCheckout />,
//   },
//   {
//     path: routes.invoiceDetails,
//     element: <InvoiceDetails />,
//   },
// ];