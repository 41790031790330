import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useNotification } from '../../../../hooks/useNotification';
import { RxCross2 } from "react-icons/rx";
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';

const NotificationSection = () => {
    const [first, setFirst] = useState<number>(0);
    const [rows, setRows] = useState<number>(25);
    const [page, setPage] = useState<number>(1); 
    const notifications = useSelector((state: any) => state?.notificationReducer);

    const { statusUpdateNotification, fetchNotifications, deleteNotification } = useNotification();

    const handleMarkRead = async (id: string) => {
        await statusUpdateNotification([id]);
    }

    const handleMarkAllRead = async () => {
        const unreadIds = notifications?.notificationList?.filter((item: any) => !item.readStatus).map((item: any) => item._id);
        if (unreadIds.length > 0) {
            await statusUpdateNotification(unreadIds, "readAll");
        }
    };

    const handleDelete = async (id: string) => {
        await deleteNotification([id]);
    }

    const handleDeleteAll = async () => {
        const ids = notifications?.notificationList?.map((item: any) => item._id);
        await deleteNotification(ids, "all");
    }

    const fetchNotification = async () => {
        await fetchNotifications(page, rows);
    }

    useEffect(() => {
        fetchNotification()
    }, [first, rows])

    const onPageChange = (event: PaginatorPageChangeEvent) => {
        setFirst(event.first);
        setRows(event.rows);
        setPage(event.first / event.rows + 1);      
    };

    return (
        <div className="all_notification">
            <div className='heading_section'>
                <h5>Notifications...</h5>
                {notifications?.notificationList?.length > 0 &&
                    <div className='header_btn'>
                        <div className='mark_read' onClick={handleMarkAllRead}>Mark all as read</div>
                        <div className='mark_read' onClick={handleDeleteAll}>Clear all</div>
                    </div>
                }
            </div>
            {notifications?.notificationList?.length > 0 ?
                <>
                    {notifications?.notificationList?.map((item: any, index: any) => {
                        return (
                            <div className='notification_wrapper' key={index}>
                                <div className='single_notification' style={{ backgroundColor: item.readStatus ? '#ffffff' : '#e5f6ff' }} onClick={() => handleMarkRead(item?._id)}>
                                    <div>
                                        <div className='notification_title'>{item?.title}</div>
                                        <div className='notification_msg'>{item?.message}</div>
                                        <div className='notification_discription'>{item?.description}</div>
                                        <div className='notification_date'>{dayjs(item?.createdAt).format("DD MMMM YYYY")}</div>
                                    </div>
                                </div>
                                <div className='cross_container' onClick={() => handleDelete(item?._id)}><RxCross2 className='cross_icon' /></div>
                            </div>
                        )
                    })}
                </> : 'No new Notifications.'}

            {notifications?.notificationList?.length > 0  && <Paginator first={first} rows={rows} totalRecords={notifications?.dataCount} rowsPerPageOptions={[10, 25, 50]} onPageChange={(e: PaginatorPageChangeEvent) => onPageChange(e)}></Paginator>}

        </div>
    )
}

export default NotificationSection