import { useEffect, useState } from 'react';
import { doGetApiCall } from '../utils/apiconfig';

const useDropdownOptions = (type: string) => {
    const [options, setOptions] = useState<{ label: string, value: string }[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getDropDownData();
        // getmasterdataType();
    }, [type]);

    // const getmasterdataType = async () =>{
    //     const data = {
    //         url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/masterdatas`,
    //     }
    //     await doGetApiCall(data)
    //     .then((res:any) => {
    //         console.log("res======>",res);
    //     })
    // }

    const getDropDownData = async () => {
        const data = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/masterdatas/type?type=${type}`,
        };
        await doGetApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    setOptions(res.result.map((item: { _id: string, name: string }) => ({
                        label: item.name,
                        value: item._id,
                    })));
                }
            })
            .catch((err) => {
                // alert("something went wrong !")
                console.error(err, '<<-- err');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return { options, loading };
};

export default useDropdownOptions;
