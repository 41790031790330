import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { useDispatch, useSelector } from "react-redux";
import { set_is_mobile_sidebar } from "../../../redux/reducers/mobileViewRedurer";
import Button from "../ui_components/buttons/Button";
import UserAvatar from "./UserAvatar";
import { getCookie } from "../../../utils/cookieUtils";
import { BiMenuAltLeft } from "react-icons/bi";
import SideBar from "./SideBar";
import NotificationIcon from "./NotificationIcon";
// import isValidToken from "../../../utils/hooks/checkTokenExpiary";


const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = getCookie('token');

    // useEffect(() => {
    //     const checkAuthentication = async () => {
    //         const isTokenExpire = await isValidToken(token);
    //         console.log("is token expire===>", isTokenExpire)

    //         if (isTokenExpire) {
    //             navigate(all_routes.login);
    //         }
    //     }
    //     checkAuthentication();

    // }, [])

    const routes = all_routes;

    const mobileSidebar = useSelector((state: any) => state?.mobileViewRedurer?.mobileSidebar);

    const handleClick = () => {
        dispatch(set_is_mobile_sidebar(!mobileSidebar));
    };

    const mainLandingMenuItems = [
        { title: "Home", path: routes.MainLanding, activePath: "home" },
        { title: "Services", path: '/#services', activePath: "services" },
        { title: "Pricing", path: '/#pricing', activePath: "pricing" },
        { title: "How It Works", path: '/#how-it-works', activePath: "how-it-works" },
        { title: "Contact Us", path: routes.contactUs, activePath: "contact" },
    ];
    const subLandingMenuItems = [
        { title: "Home", path: routes.MainLanding, activePath: "home" },
        { title: "How It Works", path: '/#how-it-works', activePath: "how-it-works" },
        { title: "Contact Us", path: routes.contactUs, activePath: "contact" },
    ];

    const menuItems = token ? subLandingMenuItems : mainLandingMenuItems;

    return (
        <header className="header">
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg header-nav">
                    <div className="navbar-header">
                        <div id="mobile_btn" onClick={handleClick}>
                            <BiMenuAltLeft className="bar-icon" />
                        </div>
                        <Link to={routes.MainLanding} className="navbar-brand logo-small">
                            <ImageWithBasePath
                                src="assets/img/logo.png"
                                className="img-fluid"
                                alt="Logo"
                            />
                        </Link>
                    </div>
                    <SideBar menuItems={menuItems} />
                    <ul className="nav header-navbar-rht">
                        {token ? (
                            <div className="d-flex gap-2">
                                <NotificationIcon />
                                <UserAvatar />
                            </div>
                        ) : (
                            <>
                                <li className="nav-item">
                                    <Button label="Sign In"
                                        iconClass="fa-regular fa-user"
                                        onClick={() => navigate(routes.login)}
                                        className="header-login" />
                                </li>
                                <li className="nav-item">
                                    <Button label="Sign Up"
                                        iconClass="fa-solid fa-lock"
                                        onClick={() => navigate(routes.register)}
                                        className="header-reg" />
                                </li>
                            </>
                        )}
                    </ul>

                </nav>
            </div>
        </header>
    );
};

export default Header;