import React, { useState  } from "react";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { LuCopyPlus } from "react-icons/lu";
import Button from "../../common/ui_components/buttons/blueBtn";
import { doPostApiCall } from "../../../utils/apiconfig";
import { getCookie } from "../../../utils/cookieUtils";
import { toast } from "react-toastify";

const PaymentOptions = [
  { id: "stripe", src: `assets/img/icons/stripe.png` },
  // { id: "applepay", src: `assets/img/icons/applepay.png` },
  // { id: "gpay", src: `assets/img/icons/gpay.png` },
];


const CheckoutPage = ({ setStepperState, setSecretKey,setIntentId }: any) => {
  const [selectedPayment, setSelectedPayment] = useState("stripe");

  // const pay_amount = 100;
  
  const handleSelectPayment = (id: string) => {
    setSelectedPayment(id);
  };

  const handleConfirm = async () => {
    const data = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/stripe/payment`,
      bodyData: {
        amount: getCookie('rentAmount'),
        carId: getCookie('CarId') // "6710dd6c9493caff0469d55b", // use the passed carId
      },
    };

    try {
      const response: any = await doPostApiCall(data);
      if (!response.error) {
        setSecretKey(response.result.client_secret)
        setIntentId(response.result. paymentIntentId)
        setStepperState((prev: any) => prev + 1)
        // setCookie("stepperstep",4)
      } else {
        toast.error("Something went wrong!");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="main-form-container">
        <div className="form-details shadow-lg p-4">
          <div className="d-flex align-items-center gap-3 border-bottom pb-3">
            <LuCopyPlus />
            <p className={`fw-bold fs-4`}>Payment</p>
          </div>
          <div className="d-flex flex-column gap-3 margin-padding-check">
            <p className={`fw-bold text-black text-center text-md-start fs-5`}>
              Choose Payment Method
            </p>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3" style={{ justifyContent: "space-between" }}>
              {PaymentOptions.map((item, idx) => (
                <div
                  className={`d-flex justify-content-center align-items-center p-2 payment-option ${selectedPayment === item.id ? "selected" : ""}`}
                  key={idx}
                  onClick={() => handleSelectPayment(item.id)}
                >
                  <ImageWithBasePath src={item.src} alt={`Payment option ${item.id}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center justify-content-md-end align-items-center w-100 gap-3">
          <Button
            label="Back"
            onClick={() => setStepperState((prev: any) => prev - 1)}
            className="bg-black text-white py-2 px-5 d-flex justify-content-center align-items-center rounded-2"
          />
          <Button
            label={`Pay € ${getCookie('rentAmount')} & Place Reservation"`}
            onClick={handleConfirm}
            className="btn btn-primary text-white py-2 px-5 d-flex justify-content-center align-items-center rounded-2"
          />
        </div>
      </div>
    </>
  );
};

export default CheckoutPage;
