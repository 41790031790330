import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns';
import useHelperFuncs from "../../../../utils/hooks/useHelperFuncs";
import BookingList from "./BookingList";
import DashboardLayout from "../DashboardLayout";
import SortAndFilterBar from "../UserBooking/SortAndFilterBar";
import UserBookingHeader from "../UserBooking/UserBookingHeader";
import { fetchBookings } from "../../../../redux/reducers/bookingReducer";
import ViewBooking from "../UserSectionModals/ViewBooking";
import useBooking from "../../../../utils/hooks/useBooking";



const BookingPage = () => {
  const dispatch = useDispatch();
  const { toTitleCase } = useHelperFuncs();
  const {
    statusArr, activeStatus, setActiveStatus,
    setTimeFilter,
    sortOptArr, setSortOpt,
  } = useBooking();

  const bookingList = useSelector((state: any) => state?.bookingReducer?.bookingList);
  const [listData, setListData] = useState<any[]>([]);

  const getFilteredList = () => {
    switch (activeStatus) {
      case "Upcoming":
        return listData.filter((rowData) => rowData?.bookingStatus === "Upcoming");
      case "Ongoing":
        return listData.filter((rowData) => rowData?.bookingStatus === "Ongoing");
      case "Completed":
        return listData.filter((rowData) => rowData?.bookingStatus === "Completed");
      case "Cancelled":
        return listData.filter((rowData) => rowData?.bookingStatus === "Cancelled");
      default:
        return listData;
    }
  };
  const filteredListData = getFilteredList();

  // Initial list data fetch
  useEffect(() => {
    dispatch(fetchBookings({ endpoint: `bookings/driver?dataPerPage=1000&page=1` }));
  }, [])

  // Only for formating list data
  useEffect(() => {
    if (bookingList?.length > 0) {
      const formatedCarList = bookingList.map((booking: any) => {
        return {
          // route: all_routes.userDashboard,
          bookingId: booking?.bookingtempId,
          imgSrc: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${booking?.carId?.images?.[0]?.key}&width=${100}`,
          carName: booking?.carId?.modelName || 'Model Name',
          carType: booking?.carId?.carCategory?.name || 'Car Type',
          pickupLocation: booking?.carId?.pickupLocation?.street,
          pickupTime: booking?.carId?.pickupDate && `${format(new Date(booking?.carId?.pickupDate), "dd MMM yyyy, hh:mm a")}`,
          dropoffLocation: booking?.carId?.dropoffLocation?.street,
          dropoffTime: booking?.carId?.dropoffDate && `${format(new Date(booking?.carId?.dropoffDate), "dd MMM yyyy, hh:mm a")}`,
          bookedOn: booking?.createdAt ? `${format(new Date(booking?.createdAt), "dd MMM yyyy, hh:mm a")}` : 'NA',
          bookingStatus: booking?.status ? toTitleCase(booking?.status) : 'Not Booked Yet',
        };
      });
      setListData(formatedCarList);
    }
  }, [bookingList])

  return (
    <>
      <DashboardLayout title={'User Bookings'} subtitle={'Bookings'}>
        <UserBookingHeader title={'Booking List'} />
        <SortAndFilterBar
          statusArr={statusArr}
          sortOptions={sortOptArr}
          onStatusChange={setActiveStatus}
          onTimeFilterChange={setTimeFilter}
          onSortChange={setSortOpt}
        />
        <div className="row">
          <BookingList title={activeStatus} tableData={filteredListData} /> 
          {/* : <UserBookingCalender />} */}
        </div>
      </DashboardLayout>

      <ViewBooking />
    </>

  );
};

export default BookingPage;
