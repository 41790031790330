import React, { useState } from 'react'
import { all_routes } from '../../router/all_routes';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RiArrowDropDownLine } from "react-icons/ri";
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath';
import useAuth from '../../../utils/hooks/useAuth';
import { ClickAwayListener } from '@mui/material';
import ConfirmationModal from '../../../core/data/modals/confirmationModal';



const UserAvatar = () => {
    const [show_user_option, setShowUserOPtion] = useState(false);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const { logout } = useAuth();
    const UserDetails = useSelector((state: any) => state?.authReducer?.login_data);

    // const handleDashboard = () => {
    //     navigate(all_routes.userSettings);
    // }\

    const handleLogout = () =>{
        setShowModal(true)
    }

    const ownerOptions = [
        { label: 'Dashboard', action: () => navigate(all_routes.userDashboard) },
        { label: 'Settings', action: () => navigate(all_routes.userSettings) },
        { label: 'My Relocations', action: () => navigate(all_routes.userRelocation) },
        { label: 'Payments', action: () => navigate(all_routes.userPayment) },
        { label: 'Log Out', action: handleLogout },
    ];
    const flexiDriverOptions = [
        { label: 'My Bookings', action: () => navigate(all_routes.driverBookings) },
        { label: 'Settings', action: () => navigate(all_routes.userSettings) },
        { label: 'Log Out', action: handleLogout },
    ];
    const professionalDriverOptions = [
        { label: 'Settings', action: () => navigate(all_routes.userSettings) },
        { label: 'Log Out', action: handleLogout },
    ];

    const enterpriseOptions = [
        { label: 'My Quotes', action: () => navigate(all_routes.userQuote) },
        { label: 'Settings', action: () => navigate(all_routes.userSettings) },
        { label: 'Log Out', action: handleLogout },
    ];

    const getOptions = () => {
        switch (UserDetails?.role) {
            case 'carOwner':
                return UserDetails?.subrole === 'enterprise' ? enterpriseOptions : ownerOptions;
            case 'flexiDriver':
                return flexiDriverOptions;
            case 'professionalDriver':
                return professionalDriverOptions;
            default:
                return []; // No options for undefined roles
        }
    };

    const userOptions = getOptions();

    return (
        <>
            <div className="user_info" onClick={() => setShowUserOPtion(!show_user_option)}>
                <div className="user_avatar">
                    <ImageWithBasePath
                        src={UserDetails?.profilePicture ? `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${UserDetails?.profilePicture?.key}&width=${100}` : "assets/img/bg/blank_profile.png"}
                        className="img-fluid"
                        alt="Logo"
                        srcType={UserDetails?.profilePicture ? 'new' : ''}
                    />
                </div>
                <div className='user_avator_name'>{UserDetails?.user_name} <RiArrowDropDownLine className="dropdown_icon" /></div>
                {show_user_option &&
                    <ClickAwayListener onClickAway={() => setShowUserOPtion(false)}>
                        <div className='user_option'>
                            {userOptions.map((option, index) => (
                                <div key={index} onClick={option.action}>
                                    {option.label}
                                </div>
                            ))}
                        </div>
                    </ClickAwayListener>
                }
            </div>
            {showModal && <ConfirmationModal show={showModal} actionBtn={true} actionClick={logout} setShow={setShowModal} message="Are you sure you want to log out?" />}
        </>
    )
}

export default UserAvatar