import React, { useEffect } from "react";
import DashboardLayout from "../DashboardLayout";
import DeleteModal from "../UserSectionModals/DeleteModal";
import InvoiceModel from "../UserSectionModals/InvoiceModal";
import { useDispatch, useSelector } from "react-redux";
import { getQuotes } from "../../../../redux/reducers/quoteReducer";
import UserQuoteCard from "./UserQuoteCard";



const UserQuotes = ({ title = "Quotes", subtitle = "My quotes" }: any) => {
    const dispatch = useDispatch();

    const quoteList = useSelector((state: any) => state?.quoteReducer?.quoteList);

    useEffect(() => {
        dispatch(getQuotes({ endpoint: `/quotes`, }));
    }, [])

    return (
        <>
            <DashboardLayout title={title} subtitle={subtitle}>
                <div className="content-header">
                    <h4>My quotes</h4>
                </div>
                {quoteList?.length > 0 ? (quoteList?.map((item: any) => <UserQuoteCard key={item._id} data={item} />))
                    : ' No data found'}

            </DashboardLayout>

            <InvoiceModel />
            <DeleteModal id="delete_modal"
                title="Delete Payment History"
                message="Are you sure want to delete?" />
        </>
    );
};

export default UserQuotes;

