import { toast } from "react-toastify";
import { doDeleteApiCall, doGetApiCall, doPutApiCall } from "../utils/apiconfig";
import { useDispatch } from "react-redux";
import { getNotificationList } from "../redux/reducers/notificationReducer";


export const useNotification = () => {

    const dispatch = useDispatch();

    const fetchNotifications = async (page=1, rows=25) => {
        const data = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/notifications/list?page=${page}&dataPerPage=${rows}`,
        };

        try {
            const res: any = await doGetApiCall(data);
            if (!res.error) {
                dispatch(getNotificationList(res))
            } else {
                console.error("response.error", res.error);
                // return []
            }
        } catch (err) {
            console.error("Error fetching user data:", err);
            // return []
        }
    };

    const statusUpdateNotification = async (ids:any, type?:string) => {
        const data = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/notifications${type !== undefined ? `?type=${type}` : " "}`,
            bodyData: {
                notificationIds: ids
            },
        };

        try {
            const res: any = await doPutApiCall(data);
            if (!res.error) {
                await fetchNotifications();
                // toast.success("Notification mark as read successfully")
            } else {
                console.error("response.error", res.error);
                // toast.error("Something went wrong")
            }
        } catch (error) {
            console.error('Error while updating user data ==>', error);
            toast.error("Unable to delete notification")
        }
    };

    const deleteNotification = async (nId: any, type?:string) => {
        const data = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/notifications${type !== undefined ? `?type=${type}` : " "}`,
            bodyData: {
                notificationIds: nId
            },
        };

        try {
            const res: any = await doDeleteApiCall(data)
            if (!res.error) {
                await fetchNotifications();
                // toast.success("Notification deleted successfully")
            } else {
                console.error("response.error ===>", res.error);
                // toast.error("Something went wrong")
            }
        } catch (error) {
            console.error('Error while updating user data ==>', error);
            toast.error("Unable to delete notification")
        }
    }
    return { fetchNotifications, statusUpdateNotification, deleteNotification };
};

