// import React, { useState } from 'react';
// import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// // import CheckBoxFilter from './CheckBoxFilterContainer';
// import RadioFilter from './RadioFilter';
// import CheckboxItem from './CheckBox';
// // import ColorPicker from './ColorPicker';
// // import PricingSlider from './PricingSlider';

// const CustomAccordion = ({ sections, handleCheckboxChange}: any) => {
//     const [expanded, setExpanded] = useState<string | false>(sections[0]?.id || false);

//     const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
//         setExpanded(isExpanded ? panel : false);
//     };

//     console.log("section====>", sections)

//     return (
//         <div>
//             {sections.map((section:any, sectionIndex:any) => (
//                 <Accordion
//                     key={sectionIndex}
//                     expanded={expanded === section.id}
//                     onChange={handleChange(section.id)}
//                 >
//                     <AccordionSummary
//                         expandIcon={<ExpandMoreIcon />}
//                         aria-controls={`panel${section.id}-content`}
//                         id={`panel${section.id}-header`}
//                     >
//                         <Typography className='filter-title'>{section.title}</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails>
//                         {section.type === 'checkbox' && (
//                             <>{section.items.map((item: any, itemIndex: any) => (
//                                 <CheckboxItem
//                                     key={itemIndex}
//                                     label={item.label}
//                                     count={item.count}
//                                     checked={item.checked}
//                                     onChange={(e:any) => handleCheckboxChange(e.target.checked, section.id, item.value)}
//                                 />
//                             ))}</>
//                             // <CheckBoxFilter items={section.items} onChange={(e:any)=>handleCheckboxChange(e.target.checked, section.items.value)} />
//                         )}
//                         {section.type === 'radio' && <RadioFilter items={section.items} name={section.radioName} />}
//                     </AccordionDetails>
//                 </Accordion>
//             ))}
//         </div>
//     );
// };

// export default CustomAccordion;

import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioFilter from "./RadioFilter";
import CheckboxItem from "./CheckBox";

const CustomAccordion = ({ sections, handleCheckboxChange, resetTrigger }: any) => {
    const [expanded, setExpanded] = useState<string | false>(sections[0]?.id || false);
    const [checkedState, setCheckedState] = useState<{ [key: string]: Set<string> }>({});

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        // Reset checkboxes when resetTrigger changes
        setCheckedState({});
    }, [resetTrigger]);

    const handleCheckboxClick = (id: string, value: string, checked: boolean) => {
        setCheckedState((prev) => {
            const updated = { ...prev };
            if (checked) {
                if (!updated[id]) updated[id] = new Set();
                updated[id].add(value);
            } else {
                updated[id]?.delete(value);
                if (updated[id]?.size === 0) delete updated[id];
            }
            return updated;
        });

        handleCheckboxChange(checked, id, value);
    };

    return (
        <div>
            {sections.map((section: any) => (
                <Accordion key={section.id} expanded={expanded === section.id} onChange={handleChange(section.id)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${section.id}-content`} id={`panel${section.id}-header`}>
                        <Typography className="filter-title">{section.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {section.type === "checkbox" && (
                            <>
                                {section.items.map((item: any) => (
                                    <CheckboxItem
                                        key={item.value}
                                        label={item.label}
                                        count={item.count}
                                        checked={checkedState[section.id]?.has(item.value) || false}
                                        onChange={(e: any) => handleCheckboxClick(section.id, item.value, e.target.checked)}
                                    />
                                ))}
                            </>
                        )}
                        {section.type === "radio" && <RadioFilter items={section.items} name={section.radioName} />}
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default CustomAccordion;

