import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/data/img/ImageWithBasePath';
import ListingLocation from '../ListingLocation';
import IconButton from '../buttons/IconButton';
import { all_routes } from '../../../router/all_routes';
import ListingDetailsGroup from '../ListingDetailsGroup';
import { useSelector } from 'react-redux';
import ConfirmationModal from '../../../../core/data/modals/confirmationModal';

const GridCard = ({ car, className, searchdata = null }: any) => {
  const navigate = useNavigate();
  const login_data = useSelector((state: any) => state?.authReducer?.login_data);
  const routes = all_routes;
  const [showModal, setShowModal] = React.useState<boolean>(false);  

  const handleClick = () => {
    if (login_data.isVerified === 1) {
      navigate(`${routes.CarDetails}?carId=${car?._id}`, { state: { searchdata } });
      return
    } else {
      setShowModal(true)
     }
  };

  return (
    <div className={className}>
      <div className="listing-item shadow_class">
        <div className="listing-img grid_image">
          <ImageWithBasePath
            srcType='new'
            src={car?.images?.[0]?.key ? `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${car?.images?.[0]?.key}&width=${500}` : "assets/img/cars/car2.png"}
            className="inner_image"
            alt={car.name}
          />
        </div>
        <div className="listing-content">
          <div className="listing-features d-flex align-items-end justify-content-between">
            <div className="list-rating">
              <h3 className="listing-title">
                <Link to={routes.CarDetails}>
                  {car.modelName}
                </Link>
              </h3>
              <div className="list-rating">
                Category: {car?.carCategory?.name}
              </div>
            </div>
          </div>
          <div className="listing-details-group">
            <ListingDetailsGroup car={car} />
          </div>
          <ListingLocation color='#000' searchdata={{
            pickupLocation: `${car?.pickupLocation?.street}`,
            dropoffLocation: `${car?.dropoffLocation?.street}`,
            pickupDate: `${car?.pickupDate}`,
            dropoffDate: `${car?.dropoffDate}`
          }} />
          <div className="listing-button" style={{ justifyContent: 'space-between', paddingTop: '18px', marginTop: '18px' }}>
            {/* <PriceInfo /> */}
            <IconButton onClick={handleClick} label='Rent Now' iconClass="feather icon-calendar me-2" />
          </div>
          { showModal && <ConfirmationModal show={showModal} setShow={setShowModal} message="You cannot rent a car because your account has not been verified by the admin yet." />}  
        </div>
      </div>
    </div>
  );
};

export default GridCard;
