import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AllRoutes from "./router/router";
import { useDispatch, useSelector } from "react-redux";
import { set_is_mobile_sidebar } from "../redux/reducers/mobileViewRedurer";
import { Bounce, ToastContainer } from 'react-toastify';
import { getCookie } from "../utils/cookieUtils";
import isValidToken from "../utils/hooks/checkTokenExpiary";
import useAuth from "../utils/hooks/useAuth";

const Feature = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const mobileSidebar = useSelector((state: any) => state?.mobileViewRedurer?.mobileSidebar);

    const token = getCookie('token');
    const { logout, isTokenValid } = useAuth();

    useEffect(() => {
        dispatch(set_is_mobile_sidebar(false));
        window.scrollTo(0, 0);
    }, [location, dispatch]);

    useEffect(() => {
        const checkAuthentication = async () => {
            // console.log("I am here in the feature component")
            const isTokenExpire = await isValidToken(token, isTokenValid);

            if (isTokenExpire) {
                // console.log("is token expire===>", isTokenExpire)
                logout();
            }
        }
        checkAuthentication();

    }, [])


    return (
        <div className={`main-wrapper ${mobileSidebar ? "menu-opened true" : "false"}`}>
            <AllRoutes />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                transition={Bounce}
            />
        </div>
    );
};

export default Feature;
