import React from 'react';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/data/img/ImageWithBasePath';
import Button from '../buttons/blueBtn';

const ServiceCard = ({ service }: any) => {
  return (
    <div className="col-lg-4 col-md-6 col-12" data-aos="fade-down">
      <div className='h-100 d-flex'>
        <div className="listing-item flex-grow-1">
          <div className='h-100 d-flex flex-column'>
            <div className="listing-img">
              <Link to={service.route}>
                <ImageWithBasePath
                  src={service.imageSrc}
                  className="img-fluid"
                  alt="service-card"
                />
              </Link>
            </div>
            <div className="listing-content flex-grow-1" style={{display:'flex'}}>
              <h3 className="listing-title">
                <Link to={service.route} style={{ color: '#111' }}>{service.title}</Link>
              </h3>
              <div className="listing-details-group">{service.description}</div>
              <div className="listing-button align-self-end">
                <Button
                  label={service.buttonText}
                  className="btn btn-order"
                  to={service.route}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
