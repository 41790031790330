// import React from 'react'
import { getCookie, setCookie } from '../utils/cookieUtils';
import { doGetApiCall, doUploadMediaApiCall } from '../utils/apiconfig';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from 'react';
import { toast } from 'react-toastify';

const useCarOwnerHooks = (setStepperState:any) => {
    const carId = getCookie('CarId');
    const [imagePreviews, setImagePreviews] = useState<string[]>([]);

    const formik = useFormik({
        initialValues: {
            modalName: "",
            carType: "",
            registration: "",
            year: "",
            seatingCapacity: "",
            description: "",
            transmissionType: "",
            fuelType: "",
            driverType:"",
            door: "",
            makeId: "",
            brake: "",
            engine: "",
            mileage: "",
            images: null,
            specifications: [],
            features: [],
            stepCompleted: ""
        },
        validationSchema: Yup.object().shape({
            modalName: Yup.string().required("Car Name is required"),
            carType: Yup.string().required("Car Type is required"),
            registration: Yup.string().required("Car Registration Number is required"),
            year: Yup.string().required("Car Make is required"),
            seatingCapacity: Yup.string().required("No of Persons is required"),
            description: Yup.string().required("Description is required"),
            transmissionType: Yup.string().required("Transmission is required"),
            fuelType: Yup.string().required("Fuel Type is required"),
            driverType: Yup.string().required("Driver Type is required"),
            door: Yup.string().required("Door is required"),
            // ac: Yup.string().required("AC is required"),
            makeId: Yup.string().required("Make is required"),
            brake: Yup.string().required("Brake is required"),
            engine: Yup.string().required("Engine is required"),
            mileage: Yup.string().required("Mileage is required"),
    
            images: Yup.array()
                .required('File is required')  // Ensures the array is present
                .min(3, 'At least three image is required')  // At least one file required
                .max(5, 'You can upload up to 5 images')  // Maximum 5 files allowed
                .of(
                    Yup.mixed()
                        .test('fileSize', 'File size is too large (Max 4MB)', (file: any) => { return file && file.size <= 4 * 1024 * 1024; })  
                        .test('fileFormat', 'Unsupported File Format (only images allowed)', (file: any) => { return file && ['image/jpg', 'image/jpeg', 'image/png'].includes(file.type || file.mimetype); })  // Check file format
                )
        }),
        onSubmit: async (values, action) => { handleSubmit(values, action) }
    });

    const handleSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
        const formData = new FormData();
        formData.append("carId", carId);
        formData.append("modelName", values.modalName);
        formData.append("carCategory", values.carType);
        formData.append("registration", values.registration);
        formData.append("year", values.year);
        formData.append("seatingCapacity", values.seatingCapacity);
        formData.append("description", values.description);
        formData.append("transmissionType", values.transmissionType);
        formData.append("fuelType", values.fuelType);
        formData.append("driverType", values.driverType);
        formData.append("door", values.door);
        formData.append("makeId", values.makeId);
        formData.append("brakeType", values.brake);
        formData.append("engine", values.engine);
        formData.append("mileage", values.mileage);
        formData.append("stepCompleted", "2",)
        formData.append("specifications", JSON.stringify(values.specifications));
        formData.append("features", JSON.stringify(values.features));
        if (values.images && values.images.length > 0) {
            values.images.forEach((file: any) => {
                formData.append("images", file);
            });
        }

        const data = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars/${carId}`,
            bodyData: formData,
            // method: 'PUT'
        };

        // await doUploadMediaApiCall(data);
        await doUploadMediaApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    setCookie("rentAmount", res?.result?.relocationAmount?.price)
                    toast.success(res.message)
                    resetForm();
                    setStepperState((prev: any) => prev + 1);
                    setCookie("stepperstep", 2)
                } else {
                    toast.warning(res.message)
                    console.error(res.error || "Save failed");
                }
            })
            .catch((err) => {
                toast.error("Something went wrong");
                console.error(err);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const fetchCarData = async () => {
        const data = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars/${carId}`,
        };
        await doGetApiCall(data)
            .then((res: any) => {
                const specificationsIds = res.result?.specifications.map((spec: any) => spec._id);
                const carCategoryId = res.result?.carCategory ? res.result.carCategory._id : "";
                const transmissionId = res.result?.transmissionType ? res.result.transmissionType._id : "";
                const fuelId = res.result?.fuelType ? res.result.fuelType._id : "";
                const makeId = res.result?.make ? res.result.make._id : "";
                const brakeId = res.result?.brakeType ? res.result.brakeType._id : "";
                if (!res.error) {
                    if (res?.result?.images) {
                        setImagePreviews(res?.result?.images.map((file: any) => `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${file?.key}&width=${100}`));
                    }
                    formik.setValues({
                        modalName: res.result.modelName || "",
                        carType: carCategoryId,
                        registration: res.result?.registration || "",
                        year: res.result?.year || "",
                        seatingCapacity: res.result?.seatingCapacity || "",
                        description: res.result?.description || "",
                        transmissionType: transmissionId,
                        fuelType: fuelId,
                        driverType: res?.result?.driverType || "",
                        door: String(res.result.door) || "",
                        makeId: makeId,
                        brake: brakeId,
                        engine: res.result.engine || "",
                        mileage: res.result?.mileage || "",
                        images: res.result?.images || [],
                        specifications: specificationsIds || [],
                        features: res.result.features || [],
                        stepCompleted: res.result?.stepCompleted || ""
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }


    return {
        handleSubmit,
        formik,
        fetchCarData,
        imagePreviews, 
        setImagePreviews
    }
}

export default useCarOwnerHooks