import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { set_login_data } from "../redux/reducers/authReducer";
import { doGetApiCall, doPostApiCall } from "../utils/apiconfig";
import { getCookie, setCookie } from "../utils/cookieUtils";
import { all_routes } from "../feature-module/router/all_routes";
import { fetchMediaData } from "./MediaData";
import { toast } from "react-toastify";
import { setSelectedSection } from "../redux/reducers/userSettingsTabSlice";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { v4 as uuidv4 } from 'uuid';

dayjs.extend(utc);


const useLogin = () => {
  const [loginError, setLoginError] = useState("");
  const [errorState, setErrorState] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routes = all_routes;

  //generate unique deviceId
  let deviceId = getCookie("device-id");
  if (!deviceId) {
    const uniqueId = uuidv4();
    deviceId = uniqueId;
    setCookie("device-id", uniqueId, 30);
  }


  const handleLoginSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    const loginData = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/auth/login`,
      bodyData: {
        email: values.email,
        password: values.password,
        rememberMe: values.rememberMe,
        deviceInfo: {
          deviceId: deviceId,
          model: navigator.userAgent,
          os: navigator.platform,
          platform: "web"
        }
      },
    };

    try {
      const response: any = await doPostApiCall(loginData);
      if (!response.error) {
        const expireSpan = dayjs.utc(response.rfExpireAt).startOf('day').diff(dayjs().utc().startOf('day'), 'days')
        toast.success("Logged in successfully !!")
        setCookie("token", response.token, 30);
        response.refreshToken && setCookie("refToken", response.refreshToken, expireSpan);
        resetForm();
        await fetchUserData();
      } else {
        handleError(`Login failed. ${response?.message}.`);
      }
    } catch (err: any) {
      handleError(err.message || "Something went wrong!");
    } finally {
      setSubmitting(false);
    }
  };

  const fetchUserData = async () => {
    const userDataRequest = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/users/details`,
    };

    try {
      const response: any = await doGetApiCall(userDataRequest);
      if (!response.error) {
        handleUserData(response?.result);
      } else {
        handleError("Failed to fetch user data.");
      }
    } catch (err) {
      handleError("Error fetching user data.");
      console.error("Error fetching user data:", err);
    }
  };

  const handleUserData = async (userData: any) => {
    dispatch(set_login_data(userData));
    setCookie("userdata", userData, 7);

    if (userData?.profileUpdated === 0) {
      navigate(routes?.userSettings);
      dispatch(setSelectedSection('Profile'))
    } else {
      if (userData?.profilePicture) {
        await fetchMediaData(userData?.profilePicture.key);
      }
      navigate(routes.MainLanding);
    }
  };

  const handleError = (message: string) => {
    setLoginError(message);
    setErrorState(true);
  };

  return {
    handleLoginSubmit, loginError, errorState, setErrorState,
  };
};

export default useLogin;
