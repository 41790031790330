import React, { useEffect, useState } from "react";
import DashboardItemList from "./DashboardItemList";
import UserRecentRelocation from "./UserRecentRelocation";
import UserRecentTransection from "./UserRecentTransection";
import DashboardLayout from "../DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { fetchCars } from "../../../../redux/reducers/carReducer";
import { fetchTransactions } from "../../../../redux/reducers/transactionReducer";
import { all_routes } from "../../../router/all_routes";
import EnterpriseDashboard from "../Enterprise/EnterpriseDashboard";



const UserDashboard = () => {
  const dispatch = useDispatch();

  // const loading = useSelector((state: any) => state?.carReducer?.loading);
  const loginData = useSelector((state: any) => state?.authReducer?.login_data);
  const totalCars = useSelector((state: any) => state?.carReducer?.totalCars);
  const totalAmount = useSelector((state: any) => state?.transactionReducer?.totalAmount);

  const routes = all_routes;
  const options = [
    { value: "last_7_days", label: "Last 7 Days" },
    { value: "last_15_days", label: "Last 15 Days" },
    { value: "last_30_days", label: "Last 30 Days" },
    { value: "last_month", label: "Last Month" },
  ];

  const [dBoardItemList, setDBoardItemList] = useState<any[]>([]);
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [tranFilterQuery, setTranFilterQuery] = useState<string>('');

  // For initial api calls according user roles
  useEffect(() => {
    if (loginData?.role === 'carOwner') {
      dispatch(fetchCars({ endpoint: `cars/lists${filterQuery}` }));
      dispatch(fetchTransactions({ endpoint: `transaction/alltransactions${tranFilterQuery}`, }));
    }
  }, [loginData?.role])

  useEffect(() => {
    if (loginData?.role === 'carOwner') {
      if (filterQuery) dispatch(fetchCars({ endpoint: `cars/lists${filterQuery}` }));
    }
  }, [loginData?.role, filterQuery])

  useEffect(() => {
    if (loginData?.role === 'carOwner') {
      if (tranFilterQuery) dispatch(fetchTransactions({ endpoint: `transaction/alltransactions${tranFilterQuery}`, }));
    }
  }, [loginData?.role, tranFilterQuery])


  useEffect(() => {
    const itemList = [];
    if (totalCars) itemList.push({
      title: "Total Relocations",
      value: totalCars,
      iconSrc: "assets/img/icons/book-icon.svg",
      linkTo: routes?.userRelocation,
      // linkTo: routes?.CarDetails,
      linkText: "View all Relocations",
      iconClass: "",
    });
    if (totalAmount) itemList.push({
      title: "Total Transactions",
      value: `$${totalAmount}`,
      iconSrc: "assets/img/icons/transaction-icon.svg",
      linkTo: routes?.userPayment,
      linkText: "View all Transactions",
      iconClass: "bg-success",
    });
    setDBoardItemList(itemList);
  }, [totalCars, totalAmount])


  return (
    <>
      {loginData?.role === 'carOwner' && loginData?.subrole === 'individual' &&
        <DashboardLayout title="User Dashboard" subtitle="User Dashboard">
          <div className="content-header">
            <h4>Dashboard</h4>
          </div>
          <DashboardItemList listData={dBoardItemList} />

          <div className="row">
            <UserRecentRelocation options={options} filterQuery={filterQuery} setFilterQuery={setFilterQuery} />
            <UserRecentTransection options={options} tranFilterQuery={tranFilterQuery} setTranFilterQuery={setTranFilterQuery} />
          </div>
        </DashboardLayout>
      }

      {loginData?.role === 'carOwner' && loginData?.subrole === 'enterprise' && <EnterpriseDashboard />}
    </>
  );
};

export default UserDashboard;
