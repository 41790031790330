import React from "react";
import Button from "../../common/ui_components/buttons/blueBtn";
import CheckboxLabels from "../../common/ui_components/CheckBox";
import InfoForPricing from "../../common/ui_components/InfoForPricing";
import { usePriceCalculation } from "../../../hooks/usePriceCalculation";
import { RxCrossCircled } from "react-icons/rx";
import { CircularProgress } from "@mui/material";

const InstantPricingSection = () => {

  const { formik, isLoading, priceChartData, setPriceChartData } = usePriceCalculation()

  return (
    <section className="section popular-explore" id='pricing'>
      <div className="card_container">
        <div className="section-heading" data-aos="fade-down">
          <h2>Get Instant Pricing</h2>
          <p>Quickly calculate the cost of relocating your fleet or renting a car with our</p>
        </div>
        <form onSubmit={formik?.handleSubmit}>
          <div className="instant-calculation-section" data-aos="fade-down">
            <div className="btn-container">
              <Button
                label="For Enterprise"
                className={`single_btn ${formik.values.selectedTab === "enterprise" ? "active" : ""}`}
                onClick={() => formik.setFieldValue("selectedTab", "enterprise")}
              />
              <Button
                label="For Individual"
                className={`single_btn ${formik.values.selectedTab === "individual" ? "active" : ""}`}
                onClick={() => formik.setFieldValue("selectedTab", "individual")}
              />
            </div>
            <div className="calculation-section">
                <InfoForPricing
                formik={formik}
                setPriceChartData={setPriceChartData}
                />
            </div>
            <div className="lower_container">
              <div className="input-container">
                <CheckboxLabels label="Include Fuel" checked={formik.values.includeFuel} onChange={(e) => 
                  formik.setFieldValue("includeFuel", e.target.checked)
                } />
              </div>
              <Button type= "submit" label="Calculate Price" className="calculate-btn" />
            </div>
            { isLoading ? <CircularProgress style={{ color: '#555' }} /> :  priceChartData &&  <div className="price_chart">
          <div className="price_chart_row">
                <div className="driver_type">Flexi Driver</div>
                <div className="cost_details">
                <div className="cost_row"><div>Fuel Cost:</div><div>€ {priceChartData?.result?.fuel_cost ? priceChartData?.result?.fuel_cost : 0}</div></div>
                  <div className="cost_row"><div>Driver Cost:</div> <div>€ {priceChartData?.result?.withFlexiDriver
                  }</div></div>
                  <hr />
                  <div className="cost_row"><div>Total Cost:</div> <div>€ { priceChartData?.result?.withFlexiDriver + (priceChartData?.result?.fuel_cost ||  0)}</div></div>
                </div>
            
          </div>
          <div className="price_chart_row">
            <div className="driver_type">Professional Driver</div>
            <div className="cost_details">
                  <div className="cost_row"><div>Fuel Cost:</div><div>€ {priceChartData?.result?.fuel_cost ? priceChartData?.result?.fuel_cost : 0}</div></div>
                  <div className="cost_row"><div>Driver Cost:</div> <div>€ {priceChartData?.result?.withProfessionalDriver
                  }</div></div>
                  <hr />
                  <div className="cost_row"><div>Total Cost:</div> <div>€ { priceChartData?.result?.withProfessionalDriver + (priceChartData?.result?.fuel_cost ||  0)}</div></div>
                </div>
          </div>
          <RxCrossCircled className="closePriceChartBtn" onClick={() => setPriceChartData(null)} />
        </div>}
          </div>
        </form>
      </div>
    </section>
  );
};

export default InstantPricingSection;
