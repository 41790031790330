import { ClickAwayListener } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { RxCrossCircled } from "react-icons/rx";
import { useNotification } from "../../../hooks/useNotification";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { GrFormNext } from "react-icons/gr";
import { setSelectedSection } from "../../../redux/reducers/userSettingsTabSlice";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";

const NotificationIcon = () => {
    const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { fetchNotifications, deleteNotification } = useNotification();
    const notifications = useSelector((state: any) => state.notificationReducer);
    // console.log("notifications==>",notifications)

    useEffect(() => {
        fetchNotification()
    }, [])

    const handleClick = () => {
        fetchNotification()
        setIsNotificationOpen(!isNotificationOpen);
    };

    const fetchNotification = async () => {
        await fetchNotifications(1, 6);
    }

    const handleSeeAll = () =>{
        dispatch(setSelectedSection("Notifications"))
        navigate(all_routes.userSettings)
    }

    const handleDelete = async (id: string) => {
        await deleteNotification([id]);
    }

    return (
        <>
            <div className="notification-icon-container" onClick={handleClick}>
                <IoMdNotificationsOutline size={20} />
                {notifications?.unreadCount > 0 && <div className="notification-count">{notifications?.unreadCount > 99 ? '99+' : notifications?.unreadCount}</div>}

            </div>
            {isNotificationOpen && (
                <ClickAwayListener onClickAway={() => setIsNotificationOpen(false)}>
                    <div className="notification-bar">
                        {notifications?.notificationList.length > 0 ? 
                        <>
                            {notifications?.notificationList?.slice(0, 6).map((nt: any) => (
                                <div key={nt?._id} className="notification_card position-relative">
                                    <div className="notification-msg" title={nt?.message}>
                                        {nt?.message}
                                    </div>
                                    <div className="notification-time">
                                        {dayjs(nt?.createdAt).format("DD MMMM YYYY")}
                                    </div>
                                    <RxCrossCircled className="close-notification" onClick={()=>handleDelete(nt._id)}/>
                                </div>
                            ))}
                        </>: 'No new notifications.'}
                        <div className="see_all_notification" onClick={handleSeeAll}>See all <GrFormNext /></div>
                    </div>
                </ClickAwayListener>
            )}
        </>
    );
};

export default NotificationIcon;
