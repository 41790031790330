import React, { useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";
import Tabs from "../Tabs";
import Button from "../buttons/blueBtn";
import IconInputField from "../InputFields/IconInputField";
import { getCookie } from "../../../../utils/cookieUtils";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useRelocationBannerForm from "../../../../hooks/useRelocationBannerForm";

const RelocationBannerCard = () => {
    const { quoteLoading, quoteMessage } = useSelector((state: any) => state?.quoteReducer);
    const { formik, getAddressComponent } = useRelocationBannerForm()
    const userDetails = useSelector((state: any) => state?.authReducer?.login_data);
    const selectedTab = useSelector((state: any) => state?.relocationTabReducer?.selectedTab);

    useEffect(() => {
        if (quoteLoading === 'succeeded') {
            toast.success(quoteMessage)
        }
    }, [quoteLoading])

    return (
        <div className="relocation-banner-card">
            {!getCookie('token') &&
                <Tabs tabs={["Enterprise", "Individual"]} />
            }
            <div className="search-box-banner" style={{ padding: "0px" }}>
                <form onSubmit={formik.handleSubmit} >
                    <ul className="align-items-center">
                        {(userDetails.subrole === "enterprise" || selectedTab === "Enterprise") && (<>
                            {/* Enterprise Name */}
                            <div className="relocation-card-column">
                                <IconInputField
                                    label="Name"
                                    placeholder="Enterprise Name"
                                    iconClass="feather icon-user"
                                    value={formik.values.name}
                                    onChange={(e) => formik.setFieldValue("name", e.target.value)}
                                />
                                {formik.touched.name && formik.errors.name && <div className="error text-danger font-short"> {formik.errors.name} </div>}
                            </div>

                            {/* Enterprise Email */}
                            <div className="relocation-card-column">
                                <IconInputField
                                    label="Email Id"
                                    placeholder="Enter Email Id"
                                    iconClass="feather icon-mail"
                                    value={formik.values.email}
                                    onChange={(e) => formik.setFieldValue("email", e.target.value)}
                                />
                                {formik.touched.email && formik.errors.email && <div className="error text-danger font-short"> {formik.errors.email} </div>}
                            </div>
                        </>)
                        }

                        {/* Pickup Location - pickupLocation */}
                        <div className="relocation-card-column">
                            <div className="input-block">
                                <label>Pickup Location</label>
                                <div className="group-img">
                                    <Autocomplete
                                        apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}
                                        onPlaceSelected={(place: any) => {
                                            const addressComponents = place?.address_components;
                                            const streetAddress = place?.formatted_address;
                                            const country = getAddressComponent(addressComponents, "country");
                                            const city = getAddressComponent(addressComponents, "locality") || getAddressComponent(addressComponents, "administrative_area_level_1"); // Fallback for certain regions
                                            const pincode = getAddressComponent(addressComponents, "postal_code");
                                            formik.setFieldValue("pickupLocation", streetAddress);
                                            formik.setFieldValue("pickupLat", place?.geometry?.location?.lat());
                                            formik.setFieldValue("pickupLng", place?.geometry?.location?.lng());
                                            formik.setFieldValue("pickupCountry", country);
                                            formik.setFieldValue("pickupCity", city);
                                            formik.setFieldValue("pickupPincode", pincode);
                                        }}
                                        value={formik.values.pickupLocation}
                                        onChange={(e) => formik.setFieldValue("pickupLocation", (e.target as HTMLInputElement).value)}
                                        placeholder="Enter Street Address"
                                        className="form-control"
                                        options={{ types: ["address"] }}
                                    />
                                    <span> <i className="feather icon-map-pin" /></span>
                                </div>
                                {formik.touched.pickupLocation && formik.errors.pickupLocation && <div className="error text-danger font-short">{formik.errors.pickupLocation} </div>}
                            </div>
                        </div>

                        {/* Drop Location - dropoffLocation */}
                        <div className="relocation-card-column">
                            <div className="input-block">
                                <label>Drop Location</label>
                                <div className="group-img">
                                    <Autocomplete
                                        apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}
                                        onPlaceSelected={(place: any) => {
                                            const addressComponents = place?.address_components;
                                            const streetAddress = place?.formatted_address;
                                            const country = getAddressComponent(addressComponents, "country");
                                            const city = getAddressComponent(addressComponents, "locality") || getAddressComponent(addressComponents, "administrative_area_level_1"); // Fallback for certain regions
                                            const pincode = getAddressComponent(addressComponents, "postal_code");
                                            formik.setFieldValue("dropoffLocation", streetAddress);
                                            formik.setFieldValue("dropoffLat", place?.geometry?.location?.lat());
                                            formik.setFieldValue("dropoffLng", place?.geometry?.location?.lng());
                                            formik.setFieldValue("dropoffCountry", country);
                                            formik.setFieldValue("dropoffCity", city);
                                            formik.setFieldValue("dropoffPincode", pincode);
                                        }}
                                        value={formik.values.dropoffLocation}
                                        onChange={(e) => formik.setFieldValue("dropoffLocation", (e.target as HTMLInputElement).value)}
                                        placeholder="Enter Street Address"
                                        className="form-control"
                                        options={{ types: ["address"] }}
                                    />
                                    <span> <i className="feather icon-map-pin" /></span>
                                </div>
                                {formik.touched.dropoffLocation && formik.errors.dropoffLocation && <div className="error text-danger font-short"> {formik.errors.dropoffLocation}</div>}
                            </div>
                        </div>

                        {/* pickupDate */}
                        <div className="half-column-group">
                            <DatePicker
                                label="Pickup Date"
                                value={formik.values.pickupDate}
                                placeholder="04/11/2023"
                                onChange={(e: any) => { formik.setFieldValue("pickupDate", e.value); }}
                            />
                            {formik.touched.pickupDate && formik.errors.pickupDate && <div className="error text-danger font-short" style={{ marginLeft: '10px' }}>{formik.errors.pickupDate} </div>}
                        </div>

                        {/* pickupTime */}
                        <div className="half-column-group">
                            <TimePicker
                                label="Pickup Time"
                                placeholder="00:00 AM"
                                value={formik.values.pickupTime}
                                onChange={(time) => formik.setFieldValue("pickupTime", time ? time.format("HH:mm") : "")}
                            />
                            {formik.touched.pickupTime && formik.errors.pickupTime && <div className="error text-danger font-short" style={{ marginLeft: '10px' }}>{formik.errors.pickupTime} </div>}
                        </div>

                        {/* dropoffDate */}
                        <div className="half-column-group">
                            <DatePicker
                                label="Drop off Date"
                                value={formik.values.dropoffDate} placeholder="04/11/2023"
                                onChange={(e: any) => { formik.setFieldValue("dropoffDate", e.value) }}
                            />
                            {formik.touched.dropoffDate && formik.errors.dropoffDate && <div className="error text-danger font-short" style={{ marginLeft: '10px' }}>{formik.errors.dropoffDate} </div>}
                        </div>

                        {/* dropoffTime */}
                        <div className="half-column-group">
                            <TimePicker label="Dropoff Time" placeholder="00:00 AM"
                                value={formik.values.dropoffTime}
                                onChange={(time: any) => { formik.setFieldValue("dropoffTime", time ? time.format("HH:mm") : "") }}
                            />
                            {formik.touched.dropoffTime && formik.errors.dropoffTime && <div className="error text-danger font-short" style={{ marginLeft: '10px' }}>{formik.errors.dropoffTime} </div>}
                        </div>

                        {(userDetails.subrole === "enterprise" || selectedTab === "Enterprise") && (
                            <>
                                {/* NIFnum */}
                                <div className="half-column-group">
                                    <IconInputField
                                        label="NIF number"
                                        placeholder="Enter NIF number"
                                        value={formik.values.NIFnum}
                                        onChange={(e) => formik.setFieldValue("NIFnum", e.target.value)}
                                    />
                                    {formik.touched.NIFnum && formik.errors.NIFnum && <div className="error text-danger font-short"> {formik.errors.NIFnum}</div>}
                                </div>

                                {/* fleetSize */}
                                <div className="half-column-group">
                                    <IconInputField
                                        label="Fleet Size"
                                        placeholder="Enter Fleet Size"
                                        value={formik.values.fleetSize}
                                        onChange={(e) => formik.setFieldValue("fleetSize", e.target.value)}
                                    />
                                    {formik.touched.fleetSize && formik.errors.fleetSize && <div className="error text-danger font-short">{formik.errors.fleetSize} </div>}
                                </div>
                            </>
                        )}

                        <div className="relocation-card-column">
                            <Button label={userDetails.subrole === "enterprise" || selectedTab === "Enterprise" ? "Get a Quote" : "Submit"} className="relocation-search-btn" type="submit" />
                        </div>
                    </ul>
                </form>
            </div>
        </div>
    );
};

export default RelocationBannerCard;
