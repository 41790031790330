import React from "react";
import { LuUpload } from "react-icons/lu";
import CloseIcon from '@mui/icons-material/Close';

interface FileUploadProps {
    formik: any;
    FILE_SIZE: number;
    SUPPORTED_FORMATS: string[];
    imagePreviews: string[];
    setImagePreviews: any;
}

const FileUpload: React.FC<FileUploadProps> = ({ formik, FILE_SIZE, SUPPORTED_FORMATS, imagePreviews, setImagePreviews }) => {
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.currentTarget.files || []);

        const validFiles = files.filter((file: any) => {
            const isValidSize = file?.size <= FILE_SIZE;
            const isValidFormat = SUPPORTED_FORMATS.includes(file?.type || file?.mimetype);
            return isValidSize && isValidFormat;
        });

        // Update Formik state with valid files
        formik.setFieldValue('images', validFiles);

        // Generate previews for selected images
        setImagePreviews(validFiles.map((file) => URL.createObjectURL(file)));
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    // Modify the handleDrop function:
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        handleFileChange({ currentTarget: { files } } as unknown as React.ChangeEvent<HTMLInputElement>);
    };

    const removeImage = (index: number) => {
        const updatedImages = formik.values.images.filter((_: any, i: number) => i !== index);
        formik.setFieldValue("images", updatedImages);

        if (updatedImages?.[0]?.key) setImagePreviews(updatedImages.map((file: any) => `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${file?.key}&width=${100}`));
        else setImagePreviews(updatedImages.map((file: any) => URL.createObjectURL(file)));
    };

    return (
        <>
            <div
                className="upload-container"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                {/* Input and Upload Label */}
                <input
                    id="images"
                    name="images"
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                    className="hidden-input"
                />

                <label htmlFor="images" className="cursor-pointer">
                    {!(imagePreviews?.length > 0) && (
                        <div className="flex flex-column justify-content-center align-items-center gap-2">
                            <div className="py-2 px-3 rounded-2 text-white fw-bold d-flex justify-content-center align-items-center gap-2" style={{ backgroundColor: '#0076D7' }}>
                                <LuUpload />
                                Upload Photos
                            </div>
                            <p className="mt-2">or Drag Photos</p>
                        </div>
                    )}
                </label>
                {imagePreviews?.length > 0 && <div className="change_image_btn">
                    <label htmlFor="images" className="cursor-pointer mb-2" style={{ float: 'right' }}>
                        <div className="security-btn">
                            <p className={`btn btn-secondary`}>Change Images</p>
                        </div>
                    </label>
                </div>}

                {imagePreviews?.length > 0 &&
                    <div className="images_conatiner">
                        {imagePreviews.map((src, index) => (
                            <div key={index} className="image_wrapper">
                                <div onClick={() => removeImage(index)} className="image_cross_icon"> <CloseIcon /></div>
                                <img crossOrigin="anonymous" src={src} alt={`Preview ${index}`} className="image_preview" width="100" />
                                <p style={{
                                    margin: 0,
                                    fontSize: '14px',
                                    color: '#333',
                                    textAlign: 'center',
                                }}>{`Image ${index + 1}`}</p>
                            </div>
                        ))}
                    </div>
                }
            </div>

            <p className="py-2 mt-2 mb-6">
                Maximum of 5 photos. File size up to 4MB each. Formats: jpeg, jpg, png.
            </p>
            {formik.touched.images && formik.errors.images ? (
                <div className="error mt-3 text-danger font-short">
                    {formik.errors.images}
                </div>
            ) : null}
        </>
    );
};

export default FileUpload;