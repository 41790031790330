import { useDispatch } from 'react-redux';
import { removeAllCookies } from '../cookieUtils';
import { set_login_data } from '../../redux/reducers/authReducer';
import { all_routes } from '../../feature-module/router/all_routes';
import { useNavigate } from 'react-router-dom';
import { doPostApiCall } from '../apiconfig';
// import { useState } from 'react';

const useAuth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        removeAllCookies();
        dispatch(set_login_data({}));
        navigate(all_routes.MainLanding)
    };
    
    const isTokenValid = async (token:string) =>{
        const data = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/auth/token/isvalid`,
            bodyData: {
                "token": token,
            },
        };
        try {
            const response: any = await doPostApiCall(data);
            // console.log("response===>", response)
            if (response.code === 'TOKEN_VALID') {
                return false
            } else {
                return true;
            }
        } catch (err: any) {
            console.error(err);
        }
    }

    return {
        logout,
        isTokenValid
    }
}

export default useAuth