import { useFormik } from "formik";
import { doGetApiCall, doPostApiCall, doUploadMediaApiCall } from "../utils/apiconfig";
import * as Yup from "yup";
import { getCookie, removeCookie, setCookie } from "../utils/cookieUtils";
import { toast } from "react-toastify";
interface FormValues {
    pickupDate: Date | null;
    pickupTime: string | null;
    pickupTimeEnd: string | null;
    dropoffDate: Date | null;
    dropoffTime: string | null;
    dropoffTimeEnd: string | null;
    pickupStreetAddress: string;
    pickupCountry: string;
    pickupCity: string;
    pickupPincode: string;
    dropoffStreetAddress: string;
    dropoffCountry: string;
    dropoffCity: string;
    dropoffPincode: string;
    pickupLat: string;
    pickupLng: string;
    dropoffLat: string;
    dropoffLng: string;
}


const useLocationHooks = (setStepperState: any) => {
    const CarId = getCookie('CarId');

    const formik = useFormik<FormValues>({
        initialValues: {
            pickupDate: null,
            pickupTime: null,
            pickupTimeEnd: null,
            dropoffDate: null,
            dropoffTime: null,
            dropoffTimeEnd: null,
            pickupStreetAddress: "",
            pickupCountry: "",
            pickupCity: "",
            pickupPincode: "",
            dropoffStreetAddress: "",
            dropoffCountry: "",
            dropoffCity: "",
            dropoffPincode: "",
            pickupLat: "",
            pickupLng: "",
            dropoffLat: "",
            dropoffLng: "",
        },
        validationSchema: Yup.object({
            pickupDate: Yup.date().nullable().required("Pickup Date is required"),
            pickupTime: Yup.string().required("Pickup Time is required"),
            pickupTimeEnd: Yup.string()
                .required("Pickup Time End is required")
                .test("is-valid-time", "Pickup end time should be greater than start time", function (value) {
                    const { pickupTime } = this.parent;
                    if (pickupTime && value) {
                        const pickupStartTime = new Date(`1970-01-01T${pickupTime}`);
                        const pickupEndTime = new Date(`1970-01-01T${value}`);
                        return pickupEndTime > pickupStartTime;
                    }
                    return true;
                }),
            dropoffDate: Yup.string().nullable().required("Dropoff date is required")
                .test("is-greater", "Dropoff date can not less than pickup date", function (value) {
                    const { pickupDate } = this.parent;
                    return !pickupDate || !value || new Date(value) >= new Date(pickupDate);
                }),
            dropoffTime: Yup.string().required("Dropoff Time is required"),
            dropoffTimeEnd: Yup.string()
                .required("Dropoff end time is required")
                .test("is-valid-time", "Dropoff end time should be greater than start time", function (value) {
                    const { dropoffTime } = this.parent;
                    if (dropoffTime && value) {
                        const dropoffStartTime = new Date(`1970-01-01T${dropoffTime}`);
                        const dropoffEndTime = new Date(`1970-01-01T${value}`);
                        return dropoffEndTime > dropoffStartTime;
                    }
                    return true;
                }),

            pickupStreetAddress: Yup.string().required("Pickup Street Address is required"),
            pickupCountry: Yup.string().required("Pickup Country is required"),
            pickupCity: Yup.string().required("Pickup City is required"),
            pickupPincode: Yup.string()
                .matches(/^[a-zA-Z0-9]{1,8}$/, "Pincode must be a maximum of 8 characters")
                .required("Pickup Pincode is required"),
            dropoffStreetAddress: Yup.string().required("Dropoff Street Address is required"),
            dropoffCountry: Yup.string().required("Dropoff Country is required"),
            dropoffCity: Yup.string().required("Dropoff City is required"),
            dropoffPincode: Yup.string()
                .matches(/^[a-zA-Z0-9]{1,8}$/, "Pincode must be a maximum of 8 characters")
                .required("Dropoff Pincode is required"),
        }),
        onSubmit: async (values, action) => { handleSubmit(values, action) },
    });

    const handleSubmit = async (values: FormValues, { setSubmitting }: any) => {
        removeCookie('locationData');
        const createCarData = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars`,
            bodyData: {
                pickupLocation: {
                    street: values.pickupStreetAddress,
                    city: values.pickupCity,
                    country: values.pickupCountry,
                    pincode: values.pickupPincode,
                    coordinates: [values.pickupLng, values.pickupLat],
                },
                dropoffLocation: {
                    street: values.dropoffStreetAddress,
                    city: values.dropoffCity,
                    country: values.dropoffCountry,
                    pincode: values.dropoffPincode,
                    coordinates: [values.dropoffLng, values.dropoffLat],
                },
                pickupDate: values.pickupDate?.toISOString().split('T')[0] || null,
                pickupTime: { from: values.pickupTime, to: values.pickupTimeEnd },
                dropoffDate: values.dropoffDate?.toISOString().split('T')[0] || null,
                dropoffTime: { from: values.dropoffTime, to: values.dropoffTimeEnd },
                stepCompleted: 1
                // ...(!CarId && { stepCompleted: 1, }),
                // ...(CarId && { carId: CarId }),
            },
          };
        const formData = new FormData();
        formData.append('carId', CarId);
        formData.append('pickupLocation[street]', values.pickupStreetAddress);
        formData.append('pickupLocation[city]', values.pickupCity);
        formData.append('pickupLocation[country]', values.pickupCountry);
        formData.append('pickupLocation[pincode]', values.pickupPincode);
        formData.append('pickupLocation[coordinates][0]',values.pickupLng)
        formData.append('pickupLocation[coordinates][1]',values.pickupLat)
        formData.append('dropoffLocation[street]', values.dropoffStreetAddress);
        formData.append('dropoffLocation[city]', values.dropoffCity);
        formData.append('dropoffLocation[country]', values.dropoffCountry);
        formData.append('dropoffLocation[pincode]', values.dropoffPincode);
        formData.append('dropoffLocation[coordinates][0]',values.dropoffLng);
        formData.append('dropoffLocation[coordinates][1]',values.dropoffLat);
        formData.append('pickupDate', values.pickupDate ? values.pickupDate.toISOString().split('T')[0] : '');
        formData.append('pickupTime[from]', values.pickupTime ? values.pickupTime : '');
        formData.append('pickupTime[to]', values.pickupTimeEnd ? values.pickupTimeEnd : '');
        formData.append('dropoffDate',values.dropoffDate ? values.dropoffDate.toISOString().split('T')[0] : '');
        formData.append('dropoffTime[from]', values.dropoffTime ? values.dropoffTime : '');
        formData.append('dropoffTime[to]', values.dropoffTimeEnd ? values.dropoffTimeEnd : '');
        

        const updataCarData = {
            url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars/${CarId}`,
            bodyData: formData
        }
        const data = CarId ? updataCarData : createCarData
        const apiCall = CarId ? doUploadMediaApiCall : doPostApiCall;

        try {
            const res: any = await apiCall(data);
            if(res.error){
                toast.error(res.message);
            }else{
                toast.success(res.message);
                setStepperState(1);
                // setStepperState((prev: any) => prev + 1);
                setCookie("stepperstep", 1)
            }

            if (!res.error && !CarId) setCookie('CarId', res.result._id);
        } catch (err) {
            console.error(err);
        } finally {
            setSubmitting(false);
        }
    };

    const fetchLocationData = async (id: string) => {
        try {
            const { result }: any = await doGetApiCall({ url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars/${id}` });
            formik.setValues({
                pickupDate: result?.pickupDate ? new Date(result.pickupDate) : null,
                pickupTime: result?.pickupTime?.from || null,
                pickupTimeEnd: result?.pickupTime?.to || null,
                dropoffDate: result?.dropoffDate ? new Date(result.dropoffDate) : null,
                dropoffTime: result?.dropoffTime?.from || null,
                dropoffTimeEnd: result?.dropoffTime?.to || null,
                pickupStreetAddress: result?.pickupLocation?.street || "",
                pickupCountry: result?.pickupLocation?.country || "",
                pickupCity: result?.pickupLocation?.city || "",
                pickupPincode: result?.pickupLocation?.pincode || "",
                dropoffStreetAddress: result?.dropoffLocation?.street || "",
                dropoffCountry: result?.dropoffLocation?.country || "",
                dropoffCity: result?.dropoffLocation?.city || "",
                dropoffPincode: result?.dropoffLocation?.pincode || "",
                pickupLat: result?.pickupLocation?.coordinates[1] || "",
                pickupLng: result?.pickupLocation?.coordinates[0] || "",
                dropoffLat: result?.dropoffLocation?.coordinates[1] || "",
                dropoffLng: result?.dropoffLocation?.coordinates[0] || "",
            });
        } catch (err) {
            console.error(err);
        }
    };


    return { fetchLocationData, formik }
}

export default useLocationHooks