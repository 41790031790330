import { useFormik } from "formik";
// import { useState } from "react";
import * as Yup from "yup";
import { doPostApiCall } from "../utils/apiconfig";
import { useState } from "react";
import { toast } from "react-toastify";

export const usePriceCalculation = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [priceChartData, setPriceChartData] = useState<any>(null)

  const handleSubmit = async (values: any) => {
    setIsLoading(true)
    const formattedData: any = {
      pickupLocation: {
        coordinates: [
          values.pickupLng,
          values.pickupLat
        ]
      },
      dropoffLocation: {
        coordinates: [
          values.dropoffLng,
          values.dropoffLat
        ]
      },
      includeFuel: values.includeFuel,
      carownerType: values.selectedTab
    };

    const data = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/cars/cost/calculate`,
      bodyData: formattedData
    }

    try {
        const res: any = await doPostApiCall(data);
        if (res.error) {
          toast.error("Unable to calculate price.")
        }else{
          setPriceChartData(res)
        }
    } catch (err) {
      toast.error("Not Found.")
    } finally {
        setIsLoading(false)
    }
  }
  

  const validationSchema = Yup.object({
    pickupLocation: Yup.string().required("Pickup location is required"),
    dropOffLocation: Yup.string().required("Drop Off location is required"),
    includeFuel: Yup.boolean().required(""),
    selectedTab: Yup.string().oneOf(["enterprise", "individual"]).required(),
    fleetSize: Yup.string().oneOf(["4 Fleet", "6 Fleet"])
  });

  const formik = useFormik({
    initialValues: {  
      pickupDate: "",
      dropOffDate:"",
      pickupLocation: "",
      dropOffLocation: "",
      pickupLat: "",
      pickupLng: "",
      dropoffLat: "",
      dropoffLng: "",
      includeFuel: false,
      selectedTab: "enterprise",
      fleetSize: ""
      },
      validationSchema,
    onSubmit: (values: any) => {
        handleSubmit(values)
    },
  });
  
  return {formik, isLoading, priceChartData, setPriceChartData}
}

