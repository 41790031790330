import React, { useEffect } from "react";
import { LuCopyPlus } from "react-icons/lu";
import LocationDateTime from "./LocationDateTime";
import PickUpLocation from "./PickUpLocation";
import DropOffLocation from "./DropOffLocation";
import { getCookie } from "../../../utils/cookieUtils";
import useLocationHooks from "../../../hooks/useLocationHooks";


const LocationForm = ({ setStepperState }: any) => {
    const CarId = getCookie('CarId');
    const { fetchLocationData, formik } = useLocationHooks(setStepperState)

    useEffect(() => {
        const locationData = getCookie('locationData');
        if (locationData && !CarId) {
            formik.setValues({
                ...locationData,
                pickupDate: locationData.pickupDate ? new Date(locationData.pickupDate) : null,
                dropoffDate: locationData.dropoffDate ? new Date(locationData.dropoffDate) : null,
                pickupTimeEnd: null,
                dropoffTimeEnd: null,
            });
        } else if (CarId) {
            fetchLocationData(CarId);
        }
    }, []);

    return (
        <form onSubmit={formik.handleSubmit} className="main-form-container">
            <div className="form-details shadow-lg p-4">
                <div className="d-flex align-items-center gap-3 border-bottom pb-3">
                    <LuCopyPlus />
                    <p className="fw-bold fs-4">Enter Location and Date</p>
                </div>
                <LocationDateTime formik={formik} />
                <PickUpLocation formik={formik} />
                <DropOffLocation formik={formik} />
            </div>
            <div className="d-flex justify-content-center justify-content-md-end w-100 gap-3">
                <button type="submit" className="bg-black text-white py-2 px-3 rounded-2">
                    Save & Next
                </button>
            </div>
        </form>
    );
};

export default LocationForm;
