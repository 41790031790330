import React from 'react'
import { Link } from 'react-router-dom'
import ImageWithBasePath from '../../../core/data/img/ImageWithBasePath'
import { all_routes } from '../../router/all_routes';
import { useDispatch, useSelector } from 'react-redux';
import { set_is_mobile_sidebar } from '../../../redux/reducers/mobileViewRedurer';
import { getCookie } from '../../../utils/cookieUtils';

const SideBar = ({menuItems}:any) => {
    const routes = all_routes;
    const dispatch = useDispatch();
    const mobileSidebar = useSelector((state: any) => state?.mobileViewRedurer?.mobileSidebar);
    const token = getCookie('token');

  const handleClick = () => {
    dispatch(set_is_mobile_sidebar(!mobileSidebar));
  };


    return (
        <div className={`main-menu-wrapper ${mobileSidebar ? "open" : ""}`}>
            <div className="menu-header">
                <Link to={routes.MainLanding} className="menu-logo">
                    <ImageWithBasePath
                        src="assets/img/logo.png"
                        className="img-fluid"
                        alt="Logo"
                    />
                </Link>
                <Link
                    id="menu_close"
                    className="menu-close"
                    to="#"
                    onClick={handleClick}
                >
                    <i className="fas fa-times" />
                </Link>
            </div>
            <ul className="main-nav">
                {menuItems.map((item:any, index:any) => (
                    <li
                        key={index}
                        className={((location.pathname === item.path && location.hash === '') || (location.hash === `#${item.activePath}`))
                            ? "active"
                            : ""}
                    >
                        <a href={item.path}>{item.title}</a>
                    </li>
                ))}
                {token === null &&
                    // for mobile view
                    <>
                        <li className="login-link"><Link to={routes.register}>Sign Up</Link></li>
                        <li className="login-link"><Link to={routes.login}>Sign In</Link></li>
                    </>
                }
            </ul>
        </div>
    )
}

export default SideBar