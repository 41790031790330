import React from 'react'
import { Dropdown } from "primereact/dropdown";
import { LuCopyPlus } from "react-icons/lu";
import useDropdownOptions from '../../../hooks/DropDownOption';

const RelocatingCarDetails = ({ formik }: any) => {

    const { options: carOptions } = useDropdownOptions('carCategory');
    const isReadOnly = formik.values.stepCompleted !== 1;

    return (
        <>
            <div className="d-flex align-items-center gap-2 border-bottom pb-3">
                <LuCopyPlus />
                <p className={`fw-bold fs-4`}>Car Details</p>
            </div>
            <div className="row row-cols-1 row-cols-md-2 py-3">
                <div className="input-block">
                    <label className="form-label">Car Model</label>
                    <input
                        type="text"
                        name="modalName"
                        className="form-control"
                        value={formik.values.modalName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.modalName && formik.errors.modalName ? (
                        <div className="error text-danger font-short">
                            {formik.errors.modalName}
                        </div>
                    ) : null}
                </div>
                <div className="input-block">
                    <label className="form-label">Car Type</label>
                    <Dropdown
                        value={formik.values.carType}
                        onChange={(e: any) => formik.setFieldValue("carType", e.value)}
                        onBlur={() => formik.setFieldTouched("carType", true)}
                        options={carOptions}
                        className="w-100"
                    />
                    {formik.touched.carType && formik.errors.carType ? (
                        <div className="error mt-3 text-danger font-short">
                            {formik.errors.carType}
                        </div>
                    ) : null}
                </div>
                <div className="input-block">
                    <label className="form-label">Car Registration Number</label>
                    <input
                        type="text"
                        name="registration"
                        className="form-control"
                        value={formik.values.registration}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        readOnly={isReadOnly}  /* Set readOnly if stepCompleted is not 1 */
                    />
                    {formik.touched.registration && formik.errors.registration ? (
                        <div className="error text-danger font-short">
                            {formik.errors.registration}
                        </div>
                    ) : null}
                </div>
                <div className="input-block">
                    <label className="form-label">{"Car Make (Year)"}</label>
                    <input
                        type="number"
                        name="year"
                        className="form-control"
                        value={formik.values.year}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.year && formik.errors.year ? (
                        <div className="error mt-3 text-danger font-short">
                            {formik.errors.year}
                        </div>
                    ) : null}
                </div>
                <div className="input-block">
                    <label className="form-label">No of Persons</label>
                    <input
                        type="number"
                        name="seatingCapacity"
                        className="form-control"
                        value={formik.values.seatingCapacity}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.seatingCapacity && formik.errors.seatingCapacity ? (
                        <div className="error text-danger font-short">
                            {formik.errors.seatingCapacity}
                        </div>
                    ) : null}
                </div>
                <div className="input-block">
                    <label className="form-label">Which type of driver you want?</label>
                    <Dropdown
                        value={formik.values.driverType}
                        onChange={(e: any) => formik.setFieldValue("driverType", e.value)}
                        onBlur={() => formik.setFieldTouched("driverType", true)}
                        options= {[
                            { label: "Flexi Driver" , value: "flexiDriver" },
                            { label: "Professional Driver" , value: "professionalDriver" },
                          ]}
                        className="w-100"
                    />
                    {formik.touched.driverType && formik.errors.driverType ? (
                        <div className="error mt-3 text-danger font-short">
                            {formik.errors.driverType}
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="input-block">
                <label className="form-label">Description</label>
                <textarea
                    name="description"
                    className="form-control"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.description && formik.errors.description ? (
                    <div className="error text-danger font-short">
                        {formik.errors.description}
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default RelocatingCarDetails;
