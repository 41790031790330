import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { ImCross } from "react-icons/im";
import AuthenticationHeader from "../common/ui_components/AuthenticationComponents/AuthenticationHeader";
import SocialLoginComponent from "../common/ui_components/SocialLoginComponent";
import AuthenticationFooter from "../common/ui_components/AuthenticationComponents/AuthenticationFooter";
import WrapperModal from "../common/ui_components/AuthenticationComponents/WrapperModal";
import useLogin from "../../hooks/login";
import { all_routes } from "../router/all_routes";
import IconInputField from "../common/ui_components/InputFields/IconInputField";



const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
});

const Login = () => {
    const { handleLoginSubmit, loginError, errorState, setErrorState } = useLogin();
    const [isToggle, setIsToggle] = useState(false);
    const routes = all_routes;

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>, fieldName: string, setFieldValue: any) => {
        const trimmedValue = e.target.value.trim(); // Trim when focus is lost
        setFieldValue(fieldName, trimmedValue);
    };

    return (
        <div className="main-wrapper login-body">
            <AuthenticationHeader />
            <div className="login-wrapper">
                <WrapperModal title="Sign In">
                    <Formik
                        initialValues={{ email: "", password: "", rememberMe: true }}
                        validationSchema={LoginSchema}
                        onSubmit={handleLoginSubmit}
                    >
                        {({ values, errors, setFieldValue, isSubmitting }) => (
                            <Form>
                                {loginError && errorState && (
                                    <div className="red-bg mb-3 p-2 py-lg-2 px-lg-3 d-flex justify-content-between align-items-center rounded-1 border border-danger-subtle">
                                        <p className={`text-danger`}>{loginError}</p>
                                        {loginError === "Invalid email or password" ? (
                                            <ImCross className="cursor-pointer text-danger" onClick={() => setErrorState(!errorState)} />
                                        ) : (
                                            <Link to={routes.register}>
                                                <button className="p-2 d-flex justify-content-center align-items-center bg-black rounded-2 text-white">
                                                    Sign up
                                                </button>
                                            </Link>
                                        )}
                                    </div>
                                )}

                                {/* email */}
                                <div className="input-block">
                                    <IconInputField
                                        label="Email"
                                        placeholder="Enter Email"
                                        // iconClass="feather icon-user"
                                        value={values.email}
                                        onChange={(e) => setFieldValue("email", e.target.value)}
                                        required={true}
                                    />
                                    {errors.email && <div className="error text-danger font-short"> {errors.email} </div>}
                                </div>

                                {/* password */}
                                <div className="input-block">
                                    <label className="form-label">Password <span className="text-danger">*</span></label>
                                    <div className="pass-group">
                                        <Field type={isToggle ? "text" : "password"} name="password" onBlur={(e: any) => handleBlur(e, 'password', setFieldValue)} className="form-control pass-input" style={{ paddingLeft: '15px' }} />
                                        <span className={`fas toggle-password ${isToggle ? "fa-eye" : "fa-eye-slash"}`} onClick={() => setIsToggle(!isToggle)} />
                                    </div>
                                    <ErrorMessage name="password" component="div" className="text-danger mt-2 font-short" />
                                </div>
                                <div className="d-flex justify-content-between">
                                    {/* Remember me */}
                                    <div className="input-block m-0">
                                        <label className="custom_check d-inline-flex">
                                            <span>Remember me</span>
                                            <Field type="checkbox" name="remember" checked={values.rememberMe} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue("rememberMe", e.target.checked)} />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    <div className="input-block">
                                        <Link className="forgot-link" to={routes.forgotPassword}>Forgot Password?</Link>
                                    </div>
                                </div>
                                <Field type="hidden" name="activeState" />
                                <button type="submit" className="btn btn-primary w-100 btn-size" disabled={isSubmitting}>Sign In</button>
                                <SocialLoginComponent />
                                <div className="text-center dont-have">
                                    <Link to={routes.register}>Register</Link>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </WrapperModal>
            </div>
            <AuthenticationFooter />
        </div>
    );
};

export default Login;