import React, { useEffect } from 'react'
import Button from '../../../common/ui_components/buttons/blueBtn'
import FileUpload from '../../../user/Forms/FileUpload';
import { useUserSettingsHook } from '../../../../hooks/useUserSettingsHook';
import { useSelector } from 'react-redux';
import ConfirmationModal from '../UserSectionModals/ConfirmationModal';
import SuccessMessage from '../UserSectionModals/SuccessMessage';

const FreelanceDocumnets = () => {
    const userDetails = useSelector((state: any) => state?.authReducer?.login_data);

    const FILE_SIZE = 4 * 1024 * 1024; 
    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
    const {
        freelanceformik, 
        imagePreviews, 
        setImagePreviews, 
        verificationModal, 
        successMessage,  
        handleOkClick, 
        handleCancel, 
        handleVerifyClick } = useUserSettingsHook()

    useEffect(()=>{
        if (userDetails) {
            setImagePreviews(userDetails?.document?.map((file: any) => `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${file?.key}&width=${100}`));
            freelanceformik.setValues({
                images: userDetails?.document || [],
            });
        }
    },[])

    return (
        <>
        <form onSubmit={freelanceformik.handleSubmit}>
            <div className="profile-info-grid">
                <div className="profile-info-header">
                    <h5>Freelance Documents</h5>
                    <p>Documents</p>
                </div>
                <div className="profile-inner">
                    <div className="row">
                        <div className="col-md-12">
                            <FileUpload formik={freelanceformik} FILE_SIZE={FILE_SIZE} SUPPORTED_FORMATS={SUPPORTED_FORMATS} imagePreviews={imagePreviews} setImagePreviews={setImagePreviews} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="profile-submit-btn">
                <Button label="Cancel" className="btn btn-secondary" />
                <Button label="Save Changes" className="btn btn-primary" />
            </div>
        </form>
        {verificationModal && <ConfirmationModal handleCancel={handleCancel} handleVerifyClick={handleVerifyClick} />}
      {successMessage && <SuccessMessage handleOkClick={handleOkClick} />}
        </>
    )
}

export default FreelanceDocumnets