import React from "react";
import { Link } from "react-router-dom";

const ListActionMenu = ({ actions }: any) => {
  return (
    <div className="dropdown dropdown-action">
      <Link
        to="#"
        className="dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="fas fa-ellipsis-vertical me-1"></i>
      </Link>
      <div className="dropdown-menu dropdown-menu-end">
        {actions.map((action: any, index: any) => (
          action.target ? (
            <Link
              key={index}
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target={action.target}
              onClick={action.onClick}
            >
              <i className={`${action.icon} me-1`} /> {action.label}
            </Link>
          ) : (
            <button
              key={index}
              className="dropdown-item"
              onClick={action.onClick}
            >
              <i className={`${action.icon} me-1`} /> {action.label}
            </button>
          )
        ))}
      </div>
    </div>
  );
};

export default ListActionMenu;
