import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { fetchBookings } from '../../redux/reducers/bookingReducer';

const useBooking = () => {
    const dispatch = useDispatch();

    const statusArr = [
        { name: "All Bookings" },
        { name: "Upcoming" },
        { name: "Ongoing" },
        { name: "Completed" },
        { name: "Cancelled" },
    ];
    const [activeStatus, setActiveStatus] = useState("All Bookings");

    const [timeFilter, setTimeFilter] = useState<string>('');

    const [sortOpt, setSortOpt] = useState<string>('');
    const sortOptArr = [
        // { name: "Sort By Booked On (Asc)", value: 'sort=ASC' },
        // { name: "Sort By Booked On (Desc)", value: 'sort=DESC' },
        { name: "Sort By Ascending", value: 'sort=ASC' },
        { name: "Sort By Descending", value: 'sort=DESC' },
        { name: "Sort By Alphabet", value: 'sort=byAlphabet' },
    ];

    const fetchFilterData = () => {
        if (timeFilter || sortOpt) {
            let query = '';
            if (timeFilter) query = `${timeFilter}&`;
            if (sortOpt) query += `${sortOpt}`;
            dispatch(fetchBookings({ endpoint: `bookings/driver?${query}` }));
        }
    }

    // Filtered list data fetch
    useEffect(() => {
        fetchFilterData();
    }, [timeFilter, sortOpt])

    return {
        statusArr,
        activeStatus,
        setActiveStatus,
        timeFilter,
        setTimeFilter,
        sortOpt,
        setSortOpt,
        sortOptArr
    }
}

export default useBooking