import { ClickAwayListener } from "@mui/material";
import React from "react";
import { RxCrossCircled } from "react-icons/rx";

const ConfirmationModal = ({ show, setShow, message, actionBtn=false, actionClick }: any) => {

    const handleAction = () =>{
        actionClick()
        setShow(false);
    }   

    return (
        <div className={`modal fade ${show ? "show d-block" : "d-none"} modal-backdrop-blur`}>
            <div className="modal-dialog modal-dialog-centered">
                <ClickAwayListener onClickAway={() => setShow(false)}>
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-end cursor-pointer">
                            {/* <h5 className="modal-title">Message</h5> */}
                            <div className="close-conbtn" onClick={() => setShow(false)}>
                                <RxCrossCircled size={25} />
                            </div>
                        </div>
                        <div className="modal-body">
                            <h5>{message}</h5>
                        </div>
                        <div className="modal-footer p-3">
                            <button className="btn btn-secondary px-4 py-1 " onClick={() =>setShow(false)}>close</button>
                            {actionBtn && <button className="btn btn-primary px-4 py-1 " onClick={handleAction}>Logout</button>}
                        </div>
                    </div>
                </ClickAwayListener>
            </div>
        </div>
    );
};

export default ConfirmationModal;
