import React, { useEffect, useState } from 'react'
import DashboardLayout from '../DashboardLayout'
import QuoteList from './QuoteList'
import SortingHeader from '../UserPayments/SortingHeader'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const EnterpriseDashboard = () => {
    
    const { quoteLoading, quoteMessage, quoteList } = useSelector((state: any) => state?.quoteReducer);

    const [listData, setListData] = useState<any[]>([]);
    const [activeStatus, setActiveStatus] = useState("All");

    useEffect(() => {
        if (quoteLoading === 'succeeded') {
            toast.success(quoteMessage)
        }
    }, [quoteLoading])

    useEffect(() => {
        if (quoteList?.length > 0) setListData(quoteList)
    }, [quoteList])

    return (
        <DashboardLayout title="Enterprise Dashboard" subtitle="Enterprise Dashboard">
            <div className="content-header">
                <h4>Dashboard</h4>
            </div>
            <SortingHeader onStatusChange={setActiveStatus} />
            {listData?.length > 0 ? <QuoteList tableData={listData} activeStatus={activeStatus} /> : 'No data found'}
        </DashboardLayout>
    )
}

export default EnterpriseDashboard