import React from "react";
import InputField from "../../../common/ui_components/InputFields/InputField";
import Button from "../../../common/ui_components/buttons/blueBtn";
import ModalWrapper from "./ModalWrapper";
import useChangePassword from "../../../../hooks/useChangePassword";

const ChangePassword = () => {
    const {formik, isLoading}= useChangePassword();
    
    return (
        <ModalWrapper id="change_password" title="Change Password">
            <form onSubmit={formik?.handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <InputField id="oldPassword" label="Old Password" placeholder="..........." required={true} type="password" value={formik?.values.oldPassword} onChange={formik?.handleChange}/>
                        {formik.touched.oldPassword && formik.errors.oldPassword && <div className="mb-3 error text-danger font-short"> {formik.errors.oldPassword} </div>}
                    </div>
                    <div className="col-md-12">
                        <InputField id="newPassword" label="New Password" placeholder="..........." required={true} type="password" value={formik?.values.newPassword} onChange={formik?.handleChange}/>
                        {formik.touched.newPassword && formik.errors.newPassword && <div className="mb-3 error text-danger font-short"> {formik.errors.newPassword} </div>}
                    </div>
                    <div className="col-md-12">
                        <InputField id="confirmPassword" label="Confirm Password" placeholder="..........." required={true} type="password" value={formik?.values.confirmPassword} onChange={formik?.handleChange}/>
                        {formik.touched.confirmPassword && formik.errors.confirmPassword && <div className="mb-3 error text-danger font-short"> {formik.errors.confirmPassword} </div>}
                    </div>
                </div>
                <div className="modal-btn modal-btn-sm">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <Button
                        label="Submit"
                        type="submit"
                        className="btn btn-primary"
                        isLoading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </form>
        </ModalWrapper>
    );
};

export default ChangePassword;
