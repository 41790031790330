import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import InputField from '../../../common/ui_components/InputFields/InputField';
import FileUploader from '../../../common/ui_components/FileUploader';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { Box, Modal } from '@mui/material';
import { doUploadMediaApiCall } from '../../../../utils/apiconfig';
import OtpVerification from '../../../authentication/OtpVerification';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../../../redux/reducers/userReducer';
import { toast } from 'react-toastify';

const ProfileBasicSection = ({ formik, phoneData, setPhoneData, profileImage, setProfileImage, setDeletedProfileImageKey }: any) => {
  const dispatch = useDispatch();
  const login_data = useSelector((state: any) => state?.authReducer?.login_data);

  const [open, setOpen] = useState(false);

  const handleProfileImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setProfileImage(url);
      formik.setFieldValue('profilePicture', file);
    }
  };

  const handleProfileImageDelete = () => {
    console.log("formik==>", formik.values.profilePicture)
    setProfileImage('/assets/img/bg/blank_profile.jpg');
    formik.setFieldValue('profilePicture', null);
    if(formik.values.profilePicture.key){
      setDeletedProfileImageKey(formik.values.profilePicture.key)
    }
  };

  const handlePhNoChange = (value: any, data: any) => {
    const rawPhone = value.slice(data.dialCode.length);
    setPhoneData((prev: any) => {
      return { ...prev, code: data.dialCode, number: rawPhone }
    });
    formik.setFieldValue('phoneNumberWithCode', value);
  }

  const handleVerify = async () => {
    const formData = new FormData();
    formData.append('phone[code]', phoneData?.code);
    formData.append('phone[number]', phoneData?.number);
    const data = {
      url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/users/update`,
      bodyData: formData,
    };
    const res: any = await doUploadMediaApiCall(data);
    if (res?.status === 200){
      setOpen(true);
    }else{
        toast.error(`${res.message} !`)
    }
  }

  const handleModalClose = () => {
    dispatch(getUserDetails());
    setOpen(false);
  }
  
  useEffect(() => {
    if (!open) {
      handleModalClose();  // Call function whenever modal closes
    }
  }, [open]);

  return (
    <div className="profile-info-grid">
      <div className="profile-info-header">
        <h5>Basic Information</h5>
        <p>Information about user</p>
      </div>

      <div className="profile-inner">
        {/* Profile Picture Section */}
        <div className="profile-info-pic">
          <div className="profile-info-img">
            <img crossOrigin="anonymous" src={profileImage} alt="Profile" className="profile-img" />
            <input
              type="file"
              accept="image/png, image/jpeg"
              style={{ display: 'none' }}
              id="profileImageInput"
              onChange={handleProfileImageChange}
            />
            <div className="profile-edit-info">
              <label htmlFor="profileImageInput" style={{ cursor: 'pointer' }}>
                <i className="feather icon-edit me-1" style={{ color: '#fff' }} />
              </label>
              <Link to="#" onClick={handleProfileImageDelete}>
                <i className="feather icon-trash-2" style={{ color: '#fff' }} />
              </Link>
            </div>
          </div>
          <div className="profile-info-content">
            <h6>Profile picture</h6>
            <p>PNG, JPEG under 15 MB</p>
          </div>
        </div>

        {/* Other Details Section */}
        <div className="row">
          {/* First Name */}
          <div className="col-md-4">
            <InputField
              label="First Name"
              placeholder="Enter First Name"
              value={formik.values.firstName}
              required
              onChange={(e: any) => formik.setFieldValue('firstName', e.target.value)}
              className="profile-inputfield"
              disabled={!login_data?.phoneVerified}
            />
            {formik.touched.firstName && formik.errors.firstName && login_data?.phoneVerified && (
              <div className="error text-danger font-short" style={{ margin: '0 0 5px 5px' }}>
                {formik.errors.firstName}
              </div>
            )}
          </div>

          {/* Last Name */}
          <div className="col-md-4">
            <InputField
              label="Last Name"
              placeholder="Enter Last Name"
              value={formik.values.lastName}
              required
              onChange={(e: any) => formik.setFieldValue('lastName', e.target.value)}
              className="profile-inputfield"
              disabled={!login_data?.phoneVerified}
            />
            {formik.touched.lastName && formik.errors.lastName && login_data?.phoneVerified && (
              <div className="error text-danger font-short" style={{ margin: '0 0 5px 5px' }}>
                {formik.errors.lastName}
              </div>
            )}
          </div>

          {/* User Name */}
          <div className="col-md-4">
            <InputField
              label="User Name"
              placeholder="Enter User Name"
              value={formik.values.userName}
              required
              onChange={(e: any) => formik.setFieldValue('userName', e.target.value)}
              className="profile-inputfield"
              readOnly={!login_data?.phoneVerified}
            />
            {formik.touched.userName && formik.errors.userName && login_data?.phoneVerified && (
              <div className="error text-danger font-short" style={{ margin: '0 0 5px 5px' }}>
                {formik.errors.userName}
              </div>
            )}
          </div>

          {/* Email */}
          <div className="col-md-6">
            <InputField
              label="Email"
              placeholder="Enter Email"
              value={formik.values.email}
              required
              readOnly
              className="profile-inputfield"
            />
            {/* {UserDetails?.emailVerified === false &&
              <button type="button" className="py-2 px-3 text-white d-flex justify-content-center align-items-center rounded-2 h-fit-cont" style={{ backgroundColor: '#0076D7', border: 'none', outline: 'none' }}>
                Verify
              </button>} */}
            {formik.touched.email && formik.errors.email && (
              <div className="error text-danger font-short" style={{ margin: '0 0 5px 5px' }}>
                {formik.errors.email}
              </div>
            )}
          </div>

          {/* Phone Number */}
          <div className="col-md-6">
              <div>
                <div className='profile-inputfield'>
                  <label style={{ color: '#111111', fontWeight: '500', fontSize: '14px', marginBottom: '5px', marginLeft: '5px', }}>
                    Phone Number <span className="text-danger"> *</span>
                  </label>
                  <div className='d-flex gap-2 mt-[2px]'>
                  <PhoneInput
                    country={'us'}
                    value={formik.values.phoneNumberWithCode}
                    onChange={handlePhNoChange}
                    inputStyle={{ width: '100%', height: '46px', background: '#F5F5F5', }}
                    disabled={login_data?.phoneVerified}
                  />
                  {!login_data?.phoneVerified &&
                <button
                  type="button"
                  // disabled={formik.errors.phoneNumberWithCode && login_data?.phoneVerified}
                  onClick={() => formik.values.phoneNumberWithCode && handleVerify()}
                  className="py-2 px-3 text-white d-flex justify-content-center align-items-center rounded-2 h-fit-cont"
                  style={{ backgroundColor: '#0076D7', border: 'none', outline: 'none' }}
                >
                  Verify
                </button>
                    }
                    </div>
                </div>
                {formik.touched.phoneNumberWithCode && formik.errors.phoneNumberWithCode &&
                  <div className="error text-danger font-short" style={{ margin: '0 0 5px 5px' }}>{formik.errors.phoneNumberWithCode}</div>
                }
              </div>
          </div>

          {/* Driving Liscense Number */}
          {(login_data?.role === 'flexiDriver' || login_data?.role === 'professionalDriver') &&
            <div className="col-md-12">
              <InputField
                label="Driving License Number"
                placeholder="Enter Driving License Number"
                value={formik.values.licenseField}
                required
                onChange={(e: any) => formik.setFieldValue('licenseField', e.target.value)}
                className="profile-inputfield"
              />
              {formik.touched.licenseField && formik.errors.licenseField && (
                <div className="error text-danger font-short" style={{ margin: '0 0 5px 5px' }}>
                  {formik.errors.licenseField}
                </div>
              )}
            </div>
          }

          {/* Document */}
          <div className="col-md-12">
            <FileUploader
              label="Upload Document (License)"
              file={formik?.values?.license}
              // file={(file:any) => formik.setFieldValue('document', file)}
              onChange={(e: any) => {
                formik.setFieldValue('license', e?.target?.files)
              }}
              required
              readOnly={!login_data?.phoneVerified}
            />
            {formik.touched.license && formik.errors.license && (
              <div className="error text-danger font-short" style={{ margin: '0 0 5px 5px' }}>
                {formik.errors.license}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Mobile OTP verification modal */}
      <Modal
        open={open}
        onClose={() => handleModalClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <OtpVerification modal={open} setModal={setOpen} type='mobile' otpFor={`${phoneData?.code}${phoneData?.number}`} />
          {/* <OTPVerificationModal /> */}
        </Box>
      </Modal>
    </div >
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default ProfileBasicSection;
