import { useFormik } from "formik";
import * as Yup from "yup";
import { createQuotes } from "../redux/reducers/quoteReducer";
import { useDispatch } from "react-redux";
import { getCookie, removeCookie, setCookie } from "../utils/cookieUtils";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../feature-module/router/all_routes";
import { useSelector } from "react-redux";


const useRelocationBannerForm = () => {

  const userDetails = useSelector((state: any) => state?.authReducer?.login_data);
  const selectedTab = useSelector((state: any) => state?.relocationTabReducer?.selectedTab);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routes = all_routes;
  const token = getCookie('token');

  const formik = useFormik({
    initialValues: {
      name: '',
      email: "",
      pickupLocation: "",
      pickupCountry: "",
      pickupCity: "",
      pickupPincode: "",
      dropoffLocation: "",
      dropoffCountry: "",
      dropoffCity: "",
      dropoffPincode: "",
      pickupDate: "",
      dropoffDate: "",
      pickupTime: "",
      dropoffTime: "",
      NIFnum: "",
      fleetSize: "",
      pickupLat: "",
      pickupLng: "",
      dropoffLat: "",
      dropoffLng: ""
    },
    validationSchema: Yup.object({
      pickupLocation: Yup.string().required("Pickup location is required"),
      dropoffLocation: Yup.string().required("Drop location is required"),
      name: Yup.string().when([], (value, schema) => {
        return userDetails.subrole === "enterprise" || selectedTab === "Enterprise" ? schema.required("Enterprise name is required") : schema.nullable();
      }),
      email: Yup.string().when([], (value, schema) => {
        return userDetails.subrole === "enterprise" || selectedTab === "Enterprise" ? schema.email("Invalid email").required("Email is required for Enterprise") : schema.nullable();
      }),
      NIFnum: Yup.string().when([], (value, schema) => {
        return userDetails.subrole === "enterprise" || selectedTab === "Enterprise" ? schema.required("NIF number is required for Enterprise") : schema.nullable();
      }),
      fleetSize: Yup.string().when([], (value, schema) => {
        return userDetails.subrole === "enterprise" || selectedTab === "Enterprise" ? schema.required("Fleet size is required for Enterprise") : schema.nullable();
      }),
      pickupDate: Yup.string().required("Pickup date is required"),
      dropoffDate: Yup.string()
        .required("Dropoff date is required")
        .test("is-greater", "Dropoff date can not less than pickup date", function (value) {
          const { pickupDate } = this.parent;
          return !pickupDate || !value || new Date(value) >= new Date(pickupDate);
        }),
      pickupTime: Yup.string().required("Pickup time is required"),
      dropoffTime: Yup.string()
        .required("Dropoff time is required")
        .test("is-valid-time", "Dropoff time should be greater than pickup time", function (value) {
          const { pickupDate, dropoffDate, pickupTime } = this.parent;
          if (pickupDate === dropoffDate && pickupTime && value) {
            const pickupTimeDate = new Date(`1970-01-01T${pickupTime}`);
            const dropoffTimeDate = new Date(`1970-01-01T${value}`);
            return dropoffTimeDate > pickupTimeDate;
          }
          return true; // Valid if dates are different or times are empty
        }),
    }),
    onSubmit: async () => { handleSubmit() }
  });

  const handleSubmit = async () => {
    if (userDetails.subrole === "enterprise" || selectedTab === "Enterprise") handleEnterPriseSubmit(formik.values, formik);
    else handleIndividualSubmit(formik.values);
  };

  const handleEnterPriseSubmit = async (values: any, { setSubmitting }: any) => {
    const formattedData = {
      email: values.email,
      name: values.name,
      pickupLocation: values.pickupLocation,
      dropoffLocation: values.dropoffLocation,
      pickupDate: `${(new Date(values.pickupDate)).toISOString().split('T')[0]}`,
      pickupTime: {
        from: values.pickupTime,
        to: values.pickupTime
      },
      dropoffDate: `${(new Date(values.dropoffDate)).toISOString().split('T')[0]}`,
      dropoffTime: {
        from: values.dropoffTime,
        to: values.dropoffTime
      },
      NIFnum: values.NIFnum,
      fleetSize: values.fleetSize
    };
    dispatch(createQuotes(formattedData));
    setSubmitting(false);
    formik.resetForm();
  };

  const handleIndividualSubmit = (values: any) => {
    if (token) {
      const data = {
        pickupStreetAddress: values.pickupLocation,
        pickupCountry: values.pickupCountry,
        pickupCity: values.pickupCity,
        pickupPincode: values.pickupPincode,
        dropoffStreetAddress: values.dropoffLocation,
        dropoffCountry: values.dropoffCountry,
        dropoffCity: values.dropoffCity,
        dropoffPincode: values.dropoffPincode,
        pickupDate: values.pickupDate,
        dropoffDate: values.dropoffDate,
        pickupTime: values.pickupTime,
        dropoffTime: values.dropoffTime,
        pickupLat: values.pickupLat,
        pickupLng: values.pickupLng,
        dropoffLat: values.dropoffLat,
        dropoffLng: values.dropoffLng
      };
      setCookie('locationData', data, 1);
      removeCookie('CarId');
      removeCookie('stepperstep');
      removeCookie('rentAmount');
      navigate(routes.relocateCar);
    } else navigate(routes.login);
  };


  const getAddressComponent = (addressComponents: any, type: any) => {
    const component = addressComponents?.find((component: any) =>
      component.types.includes(type)
    );
    return component ? component.long_name : "";
  };


  return { formik, getAddressComponent }
}

export default useRelocationBannerForm;