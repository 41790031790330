import React, { useState, useRef } from "react";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import AutoCompletePicker from "./AutoCompletePicker";
import Autocomplete from "react-google-autocomplete";
import { MdKeyboardArrowDown } from "react-icons/md";
import DatePicker from "react-multi-date-picker";
import { Card, styled } from "@mui/material";

const InfoForPricing = ({ formik, setPriceChartData }: any) => {

  const [pickLocation, setPickLocation] = useState<any>("");
  const [dropLocation, setDropLocation] = useState<any>("");

  const [pickupDate, setPickupDate] = useState<any>({ start: null, end: null });
  const [dropoffDate, setDropoffDate] = useState<any>({ start: null, end: null });
  const [fleetSize, setFleetSize] = useState<any>();

  const pickupDatePickerRef = useRef<any>(null);
  const dropoffDatePickerRef = useRef<any>(null);

  const handleSwapLocation = () => {
    const tempLockation = formik.values.pickupLocation;
    const tempLat = formik.values.pickupLat;
    const tempLng = formik.values.pickupLng;
    formik.setFieldValue("pickupLat", formik.values.dropoffLat);
    formik.setFieldValue("pickupLng", formik.values.dropoffLng);
    formik.setFieldValue("dropoffLat", tempLat);
    formik.setFieldValue("dropoffLng", tempLng);
    formik.setFieldValue("pickupLocation", formik.values.dropOffLocation);
    formik.setFieldValue("dropOffLocation", tempLockation);

    // update local state
    setPickLocation(formik.values.dropOffLocation);
    setDropLocation(tempLockation);
  };

  const openPickupCalendar = () => {
    if (pickupDatePickerRef.current) {
      pickupDatePickerRef.current.openCalendar();
    }
  };

  const openDropoffCalendar = () => {
    if (dropoffDatePickerRef.current) {
      dropoffDatePickerRef.current.openCalendar();
    }
  };

  const handlePickupDateChange = (range: any) => {
    if (range) {
      const [startDate, endDate] = range;
      setPickupDate({ start: startDate, end: endDate });
      setDropoffDate({ start: startDate, end: endDate });
    } else {
      setPickupDate({ start: null, end: null });
      setDropoffDate({ start: null, end: null });
    }
  };

  const handleDropoffDateChange = (range: any) => {
    if (range) {
      const [startDate, endDate] = range;
      setDropoffDate({ start: startDate, end: endDate });
    } else {
      setDropoffDate({ start: null, end: null });
    }
  };

  const formatDateWithDay = (date: any) => {
    if (!date) return "Select Date";
    const formattedDate = new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const formattedDay = new Date(date).toLocaleDateString("en-GB", {
      weekday: "long",
    });
    return (
      <div>
        <span>{formattedDate}</span>
        <br />
        <span>{formattedDay}</span>
      </div>
    );
  };

  const fleetSizwOpts = [
    { value: "4 Fleet", label: "4 Fleet" },
    { value: "6 Fleet", label: "6 Fleet" },
  ];

  return (
    <div className="pricing-info">
      <div className="location-section">
        {/* Pick Up Location */}
        <div className="location">
          <p>From</p>
          <Autocomplete
            apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}
            onPlaceSelected={(place: any) => {
              const streetAddress = place?.formatted_address;
              formik.setFieldValue("pickupLocation", streetAddress);
              formik.setFieldValue("pickupLat", place?.geometry?.location?.lat());
              formik.setFieldValue("pickupLng", place?.geometry?.location?.lng());
              setPickLocation(streetAddress)

              // reset price chart data for second time price calculation
              setPriceChartData(null)
            }}
            placeholder={"Enter Pickup Location"}
            options={{ types: ["address"] }}
            value={pickLocation}
            onChange={(e) => {
              setPickLocation((e.target as HTMLInputElement).value)
            }
            }
          />
          {/* {pickupLocation ? <p className="address">{pickupLocation.label}</p> : <p>Address</p>} */}
        </div>

        {/* Location Swap */}
        <SwapLocation variant="elevation" onClick={handleSwapLocation}>
          <ImageWithBasePath className="img-fluid" src="assets/img/icons/exchange.png" alt="" />
        </SwapLocation>

        {/* Drop-off Location */}
        <div className="location drop-off">
          <p>To</p>
          <Autocomplete
            apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}
            onPlaceSelected={(place: any) => {
              const streetAddress = place?.formatted_address;
              formik.setFieldValue("dropOffLocation", streetAddress);
              formik.setFieldValue("dropoffLat", place?.geometry?.location?.lat());
              formik.setFieldValue("dropoffLng", place?.geometry?.location?.lng());
              setDropLocation(streetAddress)

              // reset price chart data for second time price calculation
              setPriceChartData(null)
            }}
            placeholder={"Enter Drop-off Location"}
            options={{ types: ["address"] }}
            value={dropLocation}
            onChange={(e) => {
              setDropLocation((e.target as HTMLInputElement).value)
            }
            }
          />
          {/* {dropLocation ? <p className="address">{dropLocation.label}</p> : <p>Address</p>} */}
        </div>
      </div>

      <div className="date-fleet-section">
      {/* Pick up Date */}
      <div
        className="select-date"
          style={{ borderRight: "1px solid #E6E6E6" }}
        onClick={openPickupCalendar}
      >
        <DropdownHeadWrap>
          <p>Pick-up Date</p>
          <DropdownIcon />
        </DropdownHeadWrap>
        <h4>
          {pickupDate.start ? formatDateWithDay(pickupDate.start) : "Select Date"}
        </h4>
      </div>

      {/* Drop-off Date */}
      <div
        className="select-date"
        onClick={openDropoffCalendar}
      >
        <DropdownHeadWrap>
          <p>Drop-off Date</p>
          <DropdownIcon />
        </DropdownHeadWrap>
        <h4>
          {dropoffDate.end ? formatDateWithDay(dropoffDate.end) : "Select Date"}
        </h4>
      </div>

      {/* Fleet Size */}
      {formik.values.selectedTab === "enterprise" &&
        <div className="select-date" style={{ borderLeft: "1px solid #E6E6E6" }}>
          <DropdownHeadWrap style={{paddingTop: "10px"}}>
            <p>Fleet Size</p>
            <DropdownIcon />
          </DropdownHeadWrap>
          <AutoCompletePicker
            placeholder={"Select"}
            options={fleetSizwOpts}
            selected={fleetSize}
            setSelected={setFleetSize}
          />
        </div>
        }
        </div>

      <div className="for-pickUp">
        <DatePicker
          ref={pickupDatePickerRef}
          value={pickupDate.start ? [pickupDate.start, pickupDate.end] : []}
          onChange={handlePickupDateChange}
          range
          style={{ display: "none" }}
        />
      </div>

      <div className="for-pickUp">
        <DatePicker
          ref={dropoffDatePickerRef}
          value={dropoffDate.start ? [dropoffDate.start, dropoffDate.end] : []}
          onChange={handleDropoffDateChange}
          range
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
};

const DropdownHeadWrap = styled('div')(() => ({ display: "flex", alignItems: "center", gap: "10px", width: "100%"}));
const DropdownIcon = styled(MdKeyboardArrowDown)(() => ({ height: "22px", width: "22px", color: "#0076D7" }));
const SwapLocation = styled(Card)(() => ({
  width: '60px',
  height: '60px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  borderRadius: '50%',
  '&:hover': {
    cursor: 'pointer'
  },
  "@media (max-width: 1023px)": {
    width: '40px',
  height: '40px',
  },
  "@media (max-width: 768px)": {
    width: '30px',
  height: '30px',
  },
}));

export default InfoForPricing;
