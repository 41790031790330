import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTheme, useMediaQuery } from '@mui/material';

type Props = {
  label?: string;
  count?: number;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CheckboxItem = ({ label, count, checked, onChange }: Props) => {
  const theme = useTheme()
  const displayLabel = count ? `${label} (${count})` : label;


  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallerScreen = useMediaQuery('(max-width: 430px)');

  const labelStyles = {
    '& .MuiTypography-root': {
      fontSize: isSmallerScreen ? '12px' : isSmallScreen ? '14px' : '16px',
    }
  };

  const checkboxStyles = {
    '& .MuiSvgIcon-root': {
      fontSize: isSmallerScreen ? '14px' : isSmallScreen ? '16px' :  '18px', // Adjust checkbox size
    },
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onChange} sx={checkboxStyles}/>}
        label={displayLabel}
        sx={labelStyles}
      />
    </FormGroup>
  );
};

export default CheckboxItem;
