import React from 'react'
import ListViewCard from '../../common/ui_components/cards/ListViewCard';

const ListView = ({ carList, searchdata = null }: any) => {
  return (
    <div className="row">
      {carList.map((car: any, index: any) => (
        <ListViewCard key={index} car={car} searchdata={searchdata} />
      ))}
    </div>
  );
}

export default ListView