import { getCookie } from "../utils/cookieUtils";

export const fetchMediaData = async (mediaId: any) => {
  if (!mediaId) {
    return "assets/img/bg/noimage.png"; // Return default image if mediaId is not provided
  }
  const mediaDataRequest = {
    url: `${process.env.REACT_APP_PUBLIC_BASE_API_URL}/medias?key=${mediaId}&width=${100}`,
  };

  try {
    const response = await fetch(mediaDataRequest.url, {
      method: "GET",
      headers: {
        Accept: "application/octet-stream",
        Authorization: getCookie('token') || "",
      },
    });
    // console.log(response);
    if (response.status === 200) return response.url;
    else return "assets/img/bg/noimage.png";

  } catch (err) {
    console.error("Error fetching media data:", err);
    return "assets/img/bg/noimage.png";
  }
}